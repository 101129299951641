import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import {
  ILocalization,
  EN,
  HI,
  UpdateHIMsg,
  UpdateENMsg,
  profileHIMsg,
  profileENMsg,
} from "../models";
import { ApiHandlerService } from "../services/api-handler.service";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class LocalizationService {
  private language: string = "EN";
  localizationData: ILocalization;
  private languageObserver$: BehaviorSubject<string> = new BehaviorSubject<string>(
    "EN"
  );

  localizationObserver$: BehaviorSubject<ILocalization> = new BehaviorSubject<
    ILocalization
  >(EN());

  constructor(private _apiHandler: ApiHandlerService) {}

  getFormMsg(): Observable<any> {
    const api = "application_desc";
    return this._apiHandler.get<any>(api).pipe(
      tap((ev) => {
        UpdateENMsg(ev.data[0].ref_val, ev.data1[0].ref_val);
        UpdateHIMsg(ev.data[0].ref_val_hi, ev.data1[0].ref_val_hi);
      })
    );
  }
  getProfileMsg(): Observable<any> {
    const api = "profile_desc_reg";
    return this._apiHandler.get<any>(api).pipe(
      tap((ev) => {
        profileHIMsg(ev.data[0].ref_val_hi);
        profileENMsg(ev.data[0].ref_val);
      })
    );
  }

  seti18nData(language: string) {
    switch (language) {
      case "EN": {
        this.localizationData = EN();
        break;
      }
      case "HI": {
        this.localizationData = HI();
        break;
      }
      case "default": {
        this.localizationData = EN();
      }
    }
    this.localizationObserver$.next(this.localizationData);
    // this.setLanguage(language)
    this.language = language;
    this.languageObserver$.next(language);
  }
  geti18nData(): Observable<ILocalization> {
    return this.localizationObserver$.asObservable();
  }
  // setLanguage(language:string){
  //   this.language=language
  // }
  getLanguage(): Observable<string> {
    // return this.language;
    return this.languageObserver$.asObservable();
  }
}
