import { BrowserModule } from "@angular/platform-browser";
import { MatToolbarModule } from "@angular/material/toolbar";
import { LayoutsModule } from "./layouts/layouts.module";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgSelectModule, NgOption } from "@ng-select/ng-select";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatStepperModule } from "@angular/material/stepper";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatNativeDateModule, MatRippleModule } from "@angular/material/core";
import {ToastrModule, ToastrService} from 'ngx-toastr'
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { HTTP_INTERCEPTORS} from '@angular/common/http';
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSliderModule } from "@angular/material/slider";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTreeModule } from "@angular/material/tree";
import { MatFormFieldModule } from "@angular/material/form-field";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MelaAppAdminComponent } from "./pages/mela-app-admin/mela-app-admin/mela-app-admin/mela-app-admin.component";
import { MelaServAdminComponent } from "./pages/mela-serv-admin/mela-serv-admin/mela-serv-admin/mela-serv-admin.component";
import { DrawComponent } from "./mapDetails/draw/draw/draw.component";
import { DrawPlotComponent } from "./mapDetails/drawPlot/draw-plot/draw-plot.component";
import { MatCardModule } from '@angular/material/card';
import { DialogComponent } from './components/dialog/dialog.component';
import { NotAllottedServicesComponent } from './pages/not-allotted-services/not-allotted-services/not-allotted-services.component';
import { SectorReportComponent } from './mapDetails/sectorReport/sectorReport/sector-report.component'
import {TokenInterCepterService } from './token-inter-cepter.service';
import { APP_BASE_HREF , LocationStrategy , HashLocationStrategy } from "@angular/common";
import { AddLandComponent } from './pages/add-land/add-land.component';
//import { IciciReturnComponent } from './pages/icici-return/icici-return/icici-return.component'

//import { CategoryMapComponent } from './mapDetails/category-map/category-map/category-map.component';
// import { FullLandComponent } from './mapDetails/full-land/full-land/full-land.component';
//import { MasterComponent } from './pages/master/master/master/master.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import { AddServiceComponent } from './pages/add-service/add-service.component';
import { SetOrgRateComponent } from './pages/set-org-rate/set-org-rate.component';
import { ApproveRefundComponent } from './pages/approve-refund/approve-refund.component';
import { MasRepApplicationComponent } from "./pages/mas-rep-application/mas-rep-application.component";
import { MasRepServiceComponent } from './pages/mas-rep-service/mas-rep-service.component';

@NgModule({
  declarations: [
    AppComponent,
    MelaAppAdminComponent,
    DrawPlotComponent,
    DrawComponent,
    DialogComponent,
    AddLandComponent,
    AddServiceComponent,
    SetOrgRateComponent,
    ApproveRefundComponent,
    MasRepApplicationComponent,
    MasRepServiceComponent,
    //CategoryMapComponent,
   // FullLandComponent,
    //SectorReportComponent,
    
   // MasterComponent,
  
  ],
  imports: [
    BrowserModule,
    LayoutsModule,
    AppRoutingModule,
    NgxSpinnerModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatCardModule,
    ToastrModule.forRoot()
  ],
  providers: [ToastrService,{
    provide:HTTP_INTERCEPTORS,
    useClass:TokenInterCepterService,
    multi:true
  },
  // {provide: APP_BASE_HREF, useValue: '/'},
  // {provide: LocationStrategy, useClass: HashLocationStrategy}
],



  bootstrap: [AppComponent]
})
export class AppModule {}
