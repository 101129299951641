import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/pages/auth/auth.service";
import { ILogin } from "src/app/pages/auth/models/login.model";
import { mapKeyValueRole, IRole } from "../model";
import { ILocalization } from "src/app/shares/models";
import { LocalizationService } from "src/app/shares/store/localization.service";
import { MobileNumberService } from "src/app/shares/store/mobile-number.service";
import { ProfileService } from "src/app/pages/profile/profile.service";
import { ILoginRequest } from "src/app/pages/auth/models/login-request.model";
import { ApiHandlerService } from 'src/app/shares/services/api-handler.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit {
  localizationData: ILocalization;
  displayElement = false;
  userRole: string;
  role: string[];
  roleData;
  selected: string;
  roles: string;
  Roles: IRole[];
  userMobile: string;
  status:string;
  data;
  is_serv;
  is_service_applicable;

  dataa: Object;
  r_c_c;

  constructor(
    private router: Router,
    private _authService: AuthService,
    private _localization: LocalizationService,
    private _mobileService: MobileNumberService,
    private _apiHandler:ApiHandlerService,
    private http:HttpClient,
    // private _profileService: ProfileService,
    
  ) {}
  ngOnInit() {
    this._localization.geti18nData().subscribe((res: ILocalization) => {
      this.localizationData = res;
    });
    this.r_c_c=0;
    this._authService.userInfo$.subscribe((res: ILogin) => {
      if (res !== null) {
        this.displayElement = true;
        this.userRole = res.data[0].ip_role_ty_cd;
        this.status = res.data[0].status
        this.Roles = mapKeyValueRole(res.data[0].ip_role_ty_val);
        console.log(this.Roles);
        
        this.role = res.data[0].ip_role_ty_val.split(",");
        console.log(this.role)
        this.role.forEach(function(role){
          console.log(role)
        })
        this.selected = this.Roles[0].RoleCode;
        if (this._mobileService.getRole() == null) {
          this.selected = this.Roles[0].RoleCode;
          this._mobileService.setRole(this.selected , this.r_c_c);
        } else {
          this.selected = this._mobileService.getRole();
        }
        console.log('qwertyuiosdfghjkl')
      } else {
        //const mobile: stredirectToSetRatering = localStorage.getItem("userMobile");
        const mobile:string = this._mobileService.getMobileNumber()
        console.log(mobile)
        if (mobile) {
          const mobileNumber: ILoginRequest = {
            ip_mobile: mobile
          };
          this._authService.login(mobileNumber,0).subscribe((res: ILogin) => {});
        }else{
          this.router.navigate(['/auth'])
        }
      }
    });
    this.get_single_Data();
    // this.getProfileData()
    console.log(this.Roles,'roles')
  }

  async get_single_Data() {
    var obj = new Object();
    obj["ip_mobile"] = this._mobileService.getMobileNumber()
    this.data = await this.http
      .post(this._apiHandler.baseURL1 + "single_app_data", obj)
      .toPromise();
    this.data = this.data.data[0];
    this.is_serv = this.data.is_applied_service;
    console.log(this.is_serv);
    this.is_service_applicable = this.data['isApplicable_service'];
    console.log(this.is_service_applicable);
   

  }

  onClickRole(data: string) {
    this.selected = data;
    console.log(this.selected);
    this.r_c_c = this.r_c_c+1;
    this._mobileService.setRole(this.selected , this.r_c_c);
    if (this.selected == 'M'){
      this.navi('/master');
       
    }
    if (this.selected == 'MA'){
      this.navi('/melaHead');
    }
    if (this.selected == 'MAA'){
      this.navi('/melaAllotAdmn');
       
    }
  
    if (this.selected == 'MSA'){
      this.navi('/melaServAdmn');
       
    }
    if (this.selected == 'MLA'){
      this.navi('/melaLandAdmn');
    }
    if (this.selected == 'MTA'){
      this.navi('/melaSiteAdmn');
       
    }
    // if (this.selected == 'MAP'){
    //   this.navi('/melaAppAdmin');
       
    // }
    
    // if (this.selected == 'MLA'){
    //   this.navi('/melaLandAdmin');
    // }
    if (this.selected == 'MFA'){
      this.navi('/finance');
       
    }
  window.location.reload();
  }
  navi(path: string) {
    this.router.navigate([path]);
  }
  redirectToApp(){
    this.router.navigate(['/melaAllotAdmn/edit-app']);
  }
  redirectToService(){
    this.router.navigate(['/melaServAdmn/add-service']); 
  }
  redirectToPayment(){
    this.router.navigate(['/melaAllotAdmn/payment-details']);
  }
  
  getRoleName(role: string): string {
    const roleCode = eval("this.localizationData." + role);
    return roleCode;
    
  }

  redirectToMA(){
    this.navi('/melaAllotAdmn/land-status');

  }
  redirectToaddLand(){
    this.navi('/melaAllotAdmn/addLand');

  }
  redirectToService_letter(){
    this.navi('/melaServAdmn/service-letter');
  }
  redirectToSetRate(){
    this.navi('/melaAllotAdmn/set-sector-rate');

  }
  redirectToRefundRecord(){
    this.navi('/melaAllotAdmn/refund-status');
  }
  logOut(path: string) {
    // localStorage.removeItem("userRole");
    localStorage.clear();
    
    this.navi(path);
  
  }
}