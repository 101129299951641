<div style="margin-bottom: 20px;">
    <ng-select [items]="sectorList"
    bindLabel="sec_name"
    placeholder="Sector"
    [(ngModel)]="selectedSector" (click)="setSectorId(selectedSector)">
    </ng-select>
    </div>
    <div style="margin-bottom: 100px;">
        <ng-select [items]="plotList"
        bindLabel="lc_desc"
        placeholder="Plot"
        [(ngModel)]="selectedPlot" (click)="setPlotMap(selectedPlot)">
        </ng-select>
        </div>

      <div id="map"></div> 