import { Injectable ,Injector} from '@angular/core';
import {HttpInterceptor} from '@angular/common/http'
import {AuthService} from './pages/auth/auth.service'
@Injectable({
  providedIn: 'root'
})
export class TokenInterCepterService implements HttpInterceptor{

  constructor(private injector:Injector ) { }

  intercept(req,next){
    let authService=this.injector.get(AuthService) // injection in tokenInterCepter being like this(of any service)
    let tokenizedReq=req.clone({
      setHeaders:{
        Authorization:`bearer ${authService.getToken()}`
      }
    })
    // console.log(tokenizedReq)
    return next.handle(tokenizedReq)
  }
}


  // TokenInterCepterService