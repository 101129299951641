import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiHandlerService } from "./shares/services/api-handler.service";
import { AuthService } from "./pages/auth/auth.service";
import { Datum } from "./pages/auth/models/login.model";
import { LocalizationService } from "./shares/store/localization.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
  title = "Kumbh-web";
  RoleCode: string;

  constructor(
    private router: Router,
    private _authService: AuthService,
    private _localizationService: LocalizationService
  ) {}
  ngOnInit() {
    this._localizationService.getFormMsg().subscribe(res => {
      const data = res;
      this._localizationService.getProfileMsg().subscribe(res => {
        const data = res;
      });
    });
    
  }
}
