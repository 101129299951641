import { Injectable } from "@angular/core";
import { ApiHandlerService } from "src/app/shares/services/api-handler.service";
import { Observable, observable } from "rxjs";
import { LocalizationService } from "src/app/shares/store/localization.service";
import { ActivatedRoute } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class MelaServicesService {
  serviceElement: any[][] = [];

  private serId: string;

  setMobile(mob: string) {
    // this.mobileNo = mob;
    localStorage.setItem("serv", btoa(mob));
  }
  getMobile(): string {
    // return this.mobileNo;
    const mobileNumber: string = atob(localStorage.getItem("serv"));
    if (mobileNumber) {
      return mobileNumber;
    }
  }
  setSerId(serId: string) {
    // this.serId = serId;
    console.log(serId);
    localStorage.setItem("servId", btoa(serId));
  }
  getSerId(): string {
    console.log(this.serId);
    // return this.serId;
    const servId: string = atob(localStorage.getItem("servId"));
    if (servId) {
      return servId;
    }
  }
  setMesId(mesId: string) {
    localStorage.setItem("mesId", btoa(mesId));
  }
  getMesId(): string {
    const mesId: string = atob(localStorage.getItem("mesId"));
    if (mesId) {
      return mesId;
    }
  }

  constructor(
    private _apiHandler: ApiHandlerService,
    private _localization: LocalizationService,
    private _route: ActivatedRoute
  ) {}

  getAllottedList(mobile: string): Observable<any> {
    //  console.log( this._localization.getLanguage())
    const data = { rep_id: mobile };
    const api = "allotment_ltr_gen_rep_data";
    return this._apiHandler.postServiceData<string, any>(api, data);
  }
  getAllottedList2(mobile: string): Observable<any> {
    //  console.log( this._localization.getLanguage())
    const data = { rep_id: mobile };
    const api = "allotment_ltr_gen_rep_data_for_msr";
    return this._apiHandler.postServiceData<string, any>(api, data);
  }
  getServiceList(language: string): Observable<any> {
    const data = { hi: language };
    const api = "level1_services";
    return this._apiHandler.postServiceData<string, any>(api, data);
  }
  getServices1(data): Observable<any> {
    console.log(data);
    const api = "level2_services";
    return this._apiHandler.postServiceData<any, any>(api, data);
  }
  getServices3(data): Observable<any> {
    // const data = { id };
    const api = "level3_services";
    return this._apiHandler.postServiceData<any, any>(api, data);
  }
  postServiceData(data): Observable<any> {
    const api = "insertService_User";
    return this._apiHandler.postServiceData<any, any>(api, data);
  }
  onSubmitCheckbox(data: any[]) {
    console.log(window.location.hash.split("/")[2]);
    const id: number = parseInt(window.location.hash.split("/")[2]) ;
   // const id: number = parseInt(window.location.hash.split("/")[3]) - 1;
    if (this.serviceElement[id] === undefined) {
      this.serviceElement[id] = data;
      console.log("if");
    } else if (data[0].type_id !== undefined) {
      // const selectedData = this.serviceElement[id];

      const selectedTypeId = data[0].type_id;
      this.serviceElement[id] = this.serviceElement[id].filter((obj) => {
        return obj.type_id !== selectedTypeId;
      });
      // for(let i=0; i<data.length; i++) {
      //   for(let j=0; j<selectedData.length; j++) {
      //     if(data[i].type_id === selectedData[j].type_id) {
      //       this.serviceElement[id].splice(j, 0);
      //       break;
      //     }
      //   }
      // }
      this.serviceElement[id] = this.serviceElement[id].concat(data);
      console.log("else");
    }
    console.log(this.serviceElement);
  }
  getServiceApp(mobile): Observable<any> {
    const data = { rep_id: mobile };
    const api = "rep_user_service_alloted_list";
    return this._apiHandler.postServiceData<any, any>(api, data);
  }
  getNotAllotServiceApp(mobile): Observable<any> {
    const data = { rep_id: mobile };
    const api = "rep_user_service_not_alloted_list";
    return this._apiHandler.postServiceData<any, any>(api, data);
  }
  getAllotServData(mobile): Observable<any> {
    const data = { ip_mobile: mobile };
    const api = "alloted_service_to_a_user";
    return this._apiHandler.postServiceData<any, any>(api, data);
  }
  getTotalServReq(mobile): Observable<any> {
    const data = { ip_mobile: mobile };
    const api = "total_service_request";
    return this._apiHandler.postServiceData<any, any>(api, data);
  }
  getTotalServReq2(mobile): Observable<any> {
    const data = { ip_mobile: mobile };
    const api = "total_service_request_for_msr";
    return this._apiHandler.postServiceData<any, any>(api, data);
  }
  getOrdered(mobile): Observable<any> {
    const data = { ip_mobile: mobile };
    const api = "ordered_service_request";
    return this._apiHandler.postServiceData<any, any>(api, data);
  }
  getOrdered2(mobile): Observable<any> {
    const data = { ip_mobile: mobile };
    const api = "ordered_service_request_for_msr";
    return this._apiHandler.postServiceData<any, any>(api, data);
  }
  getInProgress(mobile): Observable<any> {
    const data = { ip_mobile: mobile };
    const api = "inProcess_service_request";
    return this._apiHandler.postServiceData<any, any>(api, data);
  }
  getInProgress2(mobile): Observable<any> {
    const data = { ip_mobile: mobile };
    const api = "inProcess_service_request_for_msr";
    return this._apiHandler.postServiceData<any, any>(api, data);
  }
  getApplicantService(mobile): Observable<any> {
    const data = { ip_mobile: mobile };
    const api = "services_for_applicant";
    return this._apiHandler.postServiceData<any, any>(api, data);
  }
  getUserServDetail(data): Observable<any> {
    const api = "user_details_for_service_request";
    return this._apiHandler.postServiceData<any, any>(api, data);
  }
  getUserServDetail1(mobile): Observable<any> {
    const api = "user_requested_details";
    return this._apiHandler.postServiceData<any, any>(api, mobile);
  }
  setUpdatedData(data): Observable<any> {
    const api = "add_items_to_in_process";
    return this._apiHandler.postServiceData<any, any>(api, data);
  }
  removeData(data): Observable<any> {
    const api = "remove_mes_id";
    return this._apiHandler.postServiceData<any, any>(api, data);
  }
  checkQuantity(data): Observable<any> {
    const api = "check_quantityOf_mes_id";
    return this._apiHandler.postServiceData<any, any>(api, data);
  }
  payServFee(data):Observable<any>{
    const api = "login/confirm_details"
    return this._apiHandler.postServiceData<any,any>(api,data)
  }
  removeSelectItemCount(data){
    const api ="substract_apply_count_from_rem_count_of_services"
    return this._apiHandler.postServiceData<string,any>(api,data)
  }
}