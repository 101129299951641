import { Routes } from "@angular/router";
import { GeneralLayoutComponent } from "./general-layout.component";

export const routes: Routes = [
  {
    path: "auth",
    component: GeneralLayoutComponent,
    loadChildren: () =>
      import("./../../pages/auth/auth.module").then(mod => mod.AuthModule)
  },
  {
    path: "sign-in",
    component: GeneralLayoutComponent,
    loadChildren: () =>
      //import("./../../pages/home1/home1.module").then(mod => mod.Home1Module)
      import("./../../pages/auth/auth.module").then(
        mod => mod.AuthModule
      )
  },
  {
    path: "registration",
    component: GeneralLayoutComponent,
    loadChildren: () =>
      //import("./../../pages/home1/home1.module").then(mod => mod.Home1Module)
      import("./../../pages/registration/registration.module").then(
        mod => mod.RegistrationModule
      )
  },
  {
    path: "draw",
    component: GeneralLayoutComponent,
    loadChildren: () =>
      import("./../../mapDetails/draw/draw.module").then(
        mod => mod.DrawModule
      )
  },
  // {
  //   path: "allotLetter/:id",
  //   component: GeneralLayoutComponent,
  //   loadChildren: () =>
  //     import("./../../pages/allotment-letter/allotment-letter.module").then(
  //       mod => mod.AllotmentLetterModule
  //     )
  // },
  // {
  //   path: "showSector",
  //   component: GeneralLayoutComponent,
  //   loadChildren: () =>
  //     import("./../../mapDetails/showSector/show-sector.module").then(
  //       mod => mod.ShowSectorModule
  //     )
  // },
  {
    path: "showPlot",
    component: GeneralLayoutComponent,
    loadChildren: () =>
      import("./../../mapDetails/showPlot/show-plot.module").then(
        mod => mod.ShowPlotModule
      )
  },
  {
    path: "drawPlot",
    component: GeneralLayoutComponent,
    loadChildren: () =>
      import("./../../mapDetails/drawPlot/draw-plot.module").then(
        mod => mod.DrawPlotModule
      )
  },
  {
    path: "uploadFile",
    component: GeneralLayoutComponent,
    loadChildren: () =>
      import("./../../pages/file-upload/file-upload.module").then(
        mod => mod.FileUploadModule
      )
  },
  {
    path: "fileDownload",
    component: GeneralLayoutComponent,
    loadChildren: () =>
      import("./../../pages/file-upload/file-upload.module").then(
        mod => mod.FileUploadModule
      )
  },
  // {
  //   path: "allot/:id",
  //   component: GeneralLayoutComponent,
  //   loadChildren: () =>
  //     import("./../../pages/allot/allot.module").then(
  //       mod => mod.AllotModule
  //     )
  // },
  
];
