import { IRole } from "./role.model";

export const mapKeyValueRole = (data: string): IRole[] => {
const Roles: IRole[] = [];
  //console.log("MapKey data", data);
  const arr = data.split(",").map(item => {
    return item.trim();
  });
  //console.log("")
  arr.forEach(obj => {
    const Role: IRole = {
      RoleName: obj.split(":")[1].trim(),
      RoleCode: obj.split(":")[0].trim()
    };
    //console.log("mapKey Role", Role);
    Roles.push(Role);
  });
  //console.log("mapKeyValue", Roles);
  return Roles;
};
