import {
  Component,
  AfterViewInit,
  OnInit,
  NgModule,
  ViewChild,
} from "@angular/core";
import { MapLoaderService } from "../../map.loader";
import { HttpClient } from "@angular/common/http";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  FormsModule,
} from "@angular/forms";
import { ApiHandlerService } from "src/app/shares/services/api-handler.service";

declare var google: any;

@Component({
  selector: "app-draw-plot",
  templateUrl: "./draw-plot.component.html",
  styleUrls: ["./draw-plot.component.css"],
})
export class DrawPlotComponent implements OnInit {
  sectorList: any;
  plotList: any;
  sector_id: any;
  plot_id: any;
  filteredList1: any;
  selectedSector: any;
  selectedPlot: any;
  map: any;
  drawingManager: any;
  postData: any;
  triangleCoords: any;

  constructor(
    private http: HttpClient,
    private _apiHandler: ApiHandlerService
  ) {}
  ngOnInit(): void {
    this.setSector();
  }
  setSectorId(sector) {
    console.log(sector);
    this.sector_id = sector.sector_id;
    this.setPlot();
    MapLoaderService.load().then(() => {
      this.drawPolygon();
    });
    this.setPolygonSector();
  }
  setPlotMap(plot) {
    this.plot_id = plot.lc_cd;
    this.setPlotPolygon();
  }

  ngAfterViewInit() {}

  drawPolygon() {
    this.map = new google.maps.Map(document.getElementById("map"), {
      center: { lat: 29.9457, lng: 78.1642 },
      zoom: 12,
    });

    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        // drawingModes: [ 'polygon', 'polyline', 'rectangle','marker', 'circle']
        drawingModes: ["polygon"],
      },
    });

    this.drawingManager.setMap(this.map);

    google.maps.event.addListener(
      this.drawingManager,
      "overlaycomplete",
      (event) => {
        // Polygon drawn
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          //this is the coordinate, you can assign it to a variable or pass into another function.
          var data = event.overlay.getPath().getArray();
          console.log(event.overlay.getPath().getArray());
          alert(
            google.maps.geometry.spherical.computeArea(event.overlay.getPath())
          );
          this.insertPlotData(event.overlay.getPath());

          console.log(this.postData);
        }
        if (event.type == google.maps.drawing.OverlayType.CIRCLE) {
          alert(event.overlay.getRadius());
        }
      }
    );
  }

  async insertPlotData(path) {
    var location = path.getArray();
    var area = google.maps.geometry.spherical.computeArea(path);
    console.log("hi:-->" + location);
    var bounds = new google.maps.LatLngBounds();
    for (var i = 0; i < location.length; i++) {
      bounds.extend(location[i]);
    }
    var obj = new Object();
    obj["area"] = area;
    obj["center"] = bounds.getCenter();
    obj["location"] = location;
    obj["sector_id"] = this.sector_id;
    obj["plot_id"] = this.plot_id;
    console.log("getCenter :" + bounds.getCenter());
    // The Center of the Bermuda Triangle - (25.3939245, -72.473816)

    this.postData = await this.http
      .post(this._apiHandler.baseURL + "plotInsert", obj)
      .toPromise();
    console.log("hello:-->" + this.postData);
  }

  async setPolygonSector() {
    var obj = new Object();

    obj["sector_id"] = this.sector_id;
    var response = await this.http
      .post(this._apiHandler.baseURL + "geo_loc_sector", obj)
      .toPromise();
    this.triangleCoords = response["location"];
    console.log("triangleCoords:-->" + this.triangleCoords);
    console.log("triangleCoords123:-->" + this.triangleCoords);
    //set polygon
    var myPolygon = new google.maps.Polygon({
      paths: this.triangleCoords,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
    });
    myPolygon.setMap(this.map);
    //set marker
    // console.log(response['data'][0]['lc_geo_latitude'])
    //   var myLatlng = new google.maps.LatLng(response['data'][0]['lc_geo_latitude'],response['data'][0]['lc_geo_longitude']);

    // var marker = new google.maps.Marker({
    //     position: myLatlng,
    //     title:response['data'][0]['lc_desc']
    // });
    // marker.setMap(this.map);
  }

  async setPlotPolygon() {
    // this.map = new google.maps.Map(document.getElementById('map'), {
    //   center: { lat: 29.9457, lng: 78.1642 },
    //   zoom: 12
    // });

    for (var i = 0; i < this.plotList.length; i++) {
      var obj = new Object();
      obj["sector_id"] = this.sector_id;
      obj["plot_id"] = this.plotList[i]["lc_cd"];
      var response = await this.http
        .post(this._apiHandler.baseURL + "geo_loc_Plot", obj)
        .toPromise();
      this.triangleCoords = response["location"];

      console.log("triangleCoords:-->" + this.triangleCoords);
      console.log("triangleCoords123:-->" + this.triangleCoords);
      console.log("response['data'][0].lc_cd:-->" + response["data"][0].lc_cd);
      //set polygon
      var myPolygon = new google.maps.Polygon({
        paths: this.triangleCoords,
        strokeColor: "#008000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#008000",
        fillOpacity: 0.35,
        indexID: response["data"][0].lc_cd,
      });
      myPolygon.setMap(this.map);
      //this.addListenersOnPolygon(myPolygon);

      console.log(response["data"][0]["lc_geo_latitude"]);
      var myLatlng = new google.maps.LatLng(
        response["data"][0]["lc_geo_latitude"],
        response["data"][0]["lc_geo_longitude"]
      );

      var marker = new google.maps.Marker({
        position: myLatlng,
        title: response["data"][0]["lc_desc"],
      });

      // To add the marker to the map, call setMap();
      marker.setMap(this.map);
      //for end
    }
  }
  //set sector
  async setSector() {
    this.sectorList = await this.http
      .get(this._apiHandler.baseURL + "sector_list")
      .toPromise();
    this.sectorList = this.sectorList.data;
    this.filteredList1 = this.sectorList.slice();
    console.log(this.sectorList);
    console.log(this.filteredList1);
  }
  async setPlot() {
    var obj = new Object();
    obj["sec_id"] = this.sector_id;
    this.plotList = await this.http
      .post(this._apiHandler.baseURL + "plot_list", obj)
      .toPromise();

    this.plotList = this.plotList.data;
    // this.filteredList1 = this.sectorList.slice();
    console.log(this.plotList);
  }
}
