<br>
<div class="page-content fade-in-up" style="background-color: white; width: 90%; margin-left: 5%; margin-bottom: 5%;">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">


                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line" style="margin-top: 2%;">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle" aria-hidden="true"></i>
                                {{localizationData['add_land']}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-cogs" aria-hidden="true"></i> {{localizationData['update_plot']}}</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle" aria-hidden="true"></i>
                                {{localizationData['add_plot_loc']}}</a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">
                            <br>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>

                                <div class="col-3">
                                    <h3>{{localizationData['select_sector']}} :</h3>

                                </div>
                                <div class="col-3">
                                    <ng-select [items]="data" bindLabel="sec_name" bindValue="sec_id" [multiple]="false"
                                        placeholder="Select sector" [(ngModel)]="obj['sec_id']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>

                                <div class="col-3">
                                    <h3>{{localizationData['select_category']}} :</h3>

                                </div>
                                <div class="col-3">
                                    <ng-select [items]="cat" bindLabel="value" bindValue="id" [multiple]="false"
                                        placeholder="Select catagory" [(ngModel)]="obj['catagory']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>

                                <div class="col-3">
                                    <h3>{{localizationData['plot_desc']}} :</h3>

                                </div>
                                <div class="col-3">
                                    <input type="text" name="" id="" placeholder="Enter plot description"
                                        [(ngModel)]="obj['plot_desc']" class="form-control">
                                </div>
                                <div class="col-3"></div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>

                                <div class="col-3">
                                    <h3>{{localizationData['enter_area']}}:</h3>

                                </div>
                                <div class="col-3">
                                    <input type="number" name="" id="" placeholder="Enter plot area"
                                        [(ngModel)]="obj['area']" class="form-control">
                                </div>
                                <div class="col-3"></div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>

                                <div class="col-3">
                                    <h3>{{localizationData['status']}} :</h3>

                                </div>
                                <div class="col-3">
                                    <ng-select [items]="isActive" bindLabel="value" bindValue="value" [multiple]="false"
                                        placeholder="Select plot status" [(ngModel)]="obj['isActive']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center" style="margin-bottom: 5%;">
                                    <button (click)="submit1()"
                                        class="btn btn-primary">{{localizationData['submit']}}</button>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-2">
                            <br>
                            <div class="row">
                                <div class="col-3"></div>

                                <div class="col-3">
                                    <h3>{{localizationData['select_sector']}}</h3>

                                </div>
                                <div class="col-3">
                                    <ng-select [items]="data" bindLabel="sec_name" bindValue="sec_id" [multiple]="false"
                                        placeholder="Select sector" [(ngModel)]="obj2['sec_id']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" (change)="showPlots()"
                                        [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>

                            </div>
                            <br>

                            <div class="col-12">
                                <div>
                                    <mat-form-field fxFlex="40%">
                                        <input matInput type="text" (keyup)="filter($event.target.value)"
                                            placeholder="Filter">
                                    </mat-form-field>
                                </div>
                                <table mat-table [dataSource]="verifydataSource" matSort class="mat-elevation-z8">

                                    <!--- Note that these columns can be defined in any order.
                                          The actual rendered columns are set as a property on the row definition" -->

                                    <!-- Position Column -->
                                    <ng-container matColumnDef="sno">
                                        <th mat-header-cell *matHeaderCellDef> {{localizationData['s_no']}} </th>
                                        <td mat-cell *matCellDef="let element"> {{element.sno}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="plot_name">
                                        <th mat-header-cell *matHeaderCellDef> {{localizationData['plot_name']}} </th>
                                        <td mat-cell *matCellDef="let element"> {{element.lc_desc}} </td>
                                    </ng-container>

                                    <!-- Name Column -->
                                    <!-- <ng-container matColumnDef="plot_cd">
                                        <th mat-header-cell *matHeaderCellDef>Plot Code </th>
                                        <td mat-cell *matCellDef="let element"> {{element.lc_cd}} </td>
                                    </ng-container> -->

                                    <!-- Weight Column -->
                                    <ng-container matColumnDef="plot_area">
                                        <th mat-header-cell *matHeaderCellDef> {{localizationData['plot_area']}}</th>
                                        <td mat-cell *matCellDef="let element"> {{element.lc_geo_area}} SQFT</td>
                                    </ng-container>

                                    <!-- Symbol Column -->
                                    <ng-container matColumnDef="isActive">
                                        <th mat-header-cell *matHeaderCellDef> {{localizationData['status']}} </th>
                                        <td mat-cell *matCellDef="let element"> {{element.isActive}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef> {{localizationData['action']}}</th>
                                        <td mat-cell *matCellDef="let element">
                                            <button class="btn btn-primary" style="font-size: 16px;"
                                                data-target="#exampleModalPreview" data-toggle="modal"
                                                (click)="update_modal(element)">{{localizationData['edit_details']}}</button>&nbsp;
                                            <button class="btn btn-danger" style="font-size: 16px;"
                                                (click)="delete_swal(element)">{{localizationData['delete']}}</button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                </table>
                                <div>
                                    <mat-paginator [pageSizeOptions]="[5, 10, 20, 50]"></mat-paginator>
                                </div>
                            </div>
                            <br>
                            <br>
                            <br>

                        </div>
                        <div class="tab-pane fade" id="tab-3">
                            <br>
                            <div class="row">
                                <div class="col-3"></div>

                                <div class="col-3">
                                    <h3>{{localizationData['select_sector']}}</h3>

                                </div>
                                <div class="col-3">
                                    <ng-select [items]="data" bindLabel="sec_name" bindValue="sec_id" [multiple]="false"
                                        placeholder="Select sector" [(ngModel)]="obj2['sec_id']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" (change)="showPlots()"
                                        [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>

                                <div class="col-3">
                                    <h3>{{localizationData['select_plot']}}</h3>

                                </div>
                                <div class="col-3">
                                    <ng-select [items]="all_Plots" bindLabel="lc_desc" bindValue="lc_cd"
                                        [multiple]="false" placeholder="Select plot" [(ngModel)]="obj2['plot_cd']"
                                        [selectableGroup]="true" (change)="getPlotLocation()"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-1"></div>
                                <div class="col-10">
                                    <button (click)="addRow3()" class="btn btn-primary text-right"> <i
                                            class="fa fa-plus" aria-hidden="true"></i></button>
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>

                                                <th>{{localizationData['s_no']}}</th>
                                                <th>{{localizationData['long']}}</th>
                                                <th>{{localizationData['lang']}}</th>
                                                <th>{{localizationData['action']}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of allLocations;let i=index">
                                                <td>{{i+1}}</td>
                                                <td>
                                                    <input type="number" placeholder="Enter plot area"
                                                        [(ngModel)]="item['lng']" class="form-control">

                                                </td>
                                                <td>
                                                    <input type="number" placeholder="Enter plot area"
                                                        [(ngModel)]="item['lat']" class="form-control">
                                                </td>
                                                <td>
                                                    <button class="btn btn-danger" (click)="removeRow3(item,i)"> <i
                                                            class="fa fa-trash" aria-hidden="true"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-1"></div>
                            </div>
                            <br>
                            <div class="row" style="margin-bottom: 5%;">
                                <div class="col-12 text-center">
                                    <button (click)="submit3()"
                                        class="btn btn-primary">{{localizationData['submit']}}</button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
<div class="modal fade" id="exampleModalPreview" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLabel">
                    <h1>{{localizationData['plot_details']}}</h1>
                </h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <!-- <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 text-left">
                        <h3>Plot Code :</h3>
                    </div>
                    <div class="col-3">
                        <input type="text" name="" class="form-control" [(ngModel)]="updateObj['lc_cd']" disabled>
                    </div>
                    <div class="col-3"></div>

                </div> -->
                <br>
                <div class="row">
                    <div class="col-3"></div>

                    <div class="col-3 text-left">
                        <h3>{{localizationData['plot_name']}} :</h3>
                    </div>
                    <div class="col-3">
                        <input type="text" name="" class="form-control" [(ngModel)]="updateObj['lc_desc']">
                    </div>
                    <div class="col-3"></div>

                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 text-left">
                        <h3>{{localizationData['plot_area']}} (SQFT) :</h3>
                    </div>
                    <div class="col-3">
                        <input type="text" name="" class="form-control" [(ngModel)]="updateObj['lc_geo_area']">
                    </div>
                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 text-left">
                        <h3>{{localizationData['status']}}</h3>

                    </div>
                    <div class="col-3">
                        <ng-select [items]="isActive" bindLabel="value" bindValue="value" [multiple]="false"
                            placeholder="Select catagory" [(ngModel)]="updateObj['isActive']" [selectableGroup]="true"
                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                        </ng-select>
                    </div>
                    <div class="col-3"></div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" style="font-size: 16px;"
                        (click)="update_plotInfo()">{{localizationData['update_plot']}}</button>
                    <button type="button" class="btn btn-danger" style="font-size: 16px;" data-dismiss="modal">
                        {{localizationData['close']}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>