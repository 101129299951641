<ngx-spinner>Loading...</ngx-spinner>
<br>
<div class="loader" *ngIf="show_spinner"></div>
<div class="row">
    <div class="col-2"></div>
    <div class="col-10 text-right">

    </div>
    <div class="col-2"></div>
</div>
<div class="row" style="width: 60%; margin-left: 20%;margin-bottom: 1%; ">
    <div class="col-12 text-right">
        <button class="btn btn-danger" (click)="back()">{{localizationData['back']}}</button> &nbsp;&nbsp;&nbsp;
        <button (click)="downloadPdf2()" *ngIf="obj['isAppliedAfterPayment']=='YES'" class="btn btn-primary"
            style="font-size: 16px;">{{localizationData['generate_letter']}}</button>
            <button (click)="downloadPdf()" *ngIf="obj['isAppliedAfterPayment']!='YES'" class="btn btn-primary"
            style="font-size: 16px;">{{localizationData['generate_letter']}}</button>
    </div>
</div>
<div *ngIf="obj['isAppliedAfterPayment']!='YES'"
    style="background-color: white;width: 60%; margin-left: 20%;margin-bottom: 5%; ">
    <div class="row" style="margin: 5%;">
        <div class="col-3">
            <img width="50%" height="85px" style="margin-top: 5%;" width="70px"
                src="../../../../assets/1200px-Seal_of_Uttarakhand.svg.png">
        </div>
        <div class="col-6 text-center">
            <p style="margin-top: 10%;"></p>
            <b style="font-size: 15px;">कार्यालय मेलाधिकारी कुम्भ मेला - 2021</b>

            <h5>
                मेला नियंत्रण भवन,
                हरिद्वार
                <br>
                <b> भूमि आवंटन पत्र</b>
            </h5>


        </div>
        <div class="col-3 text-right">
            <img width="50%" height="85px" style="margin-top: 5%;" width="70px" src="../../../../assets/mahakumbh.png">
        </div>
    </div>
    <div class="row" style="margin-left: 5%;">
        <div class="col-8">
            <p>प्रत्रांक - {{sr_alt_id}}/भू० आ०/भूमि व्यवस्था/कुम्भ-2021</p>
        </div>
        <div class="col-4 text-center">
            <p>
                दिनांक : {{today}}</p>
        </div>
    </div>

    <div id="paraWithBG">
        <div class="row" style="margin-left: 5%;">
            <div class="col-4">
                <b>{{localizationData['org_code']}} :</b>
            </div>
            <div class="col-8">
                <b> {{obj['ip_newID']}}</b>
            </div>
        </div>
        <div class="row" style="margin-left: 5%;">
            <div class="col-4">
                <b>{{localizationData['name']}} :</b>
            </div>
            <div class="col-8">
                <b> {{obj['ip_name']}} |</b>
            </div>
        </div>
        <div class="row" style="margin-left: 5%;">
            <div class="col-4">
                <b>{{localizationData['organization_type']}} :</b>
            </div>
            <div class="col-8">
                <b> {{obj['ref_val_desc']}} |</b>
            </div>
        </div>
        <div class="row" style="margin-left: 5%;">
            <div class="col-4">
                <b> {{localizationData['address']}} :</b>
            </div>
            <div class="col-8">
                <b> {{obj['ip_address']}} |</b>
            </div>
        </div>
        <div class="row" style="margin-left: 5%;">
            <div class="col-12 text-center">
                 <p>
                <b>विषय :कुम्भ मेला - 2021 हरिद्वार मे<span *ngIf="obj['ref_val_cd'] =='COM'"> व्यवसायिक </span>अस्थाई भूमि का आवंटन</b>
            </p>
            </div>
        </div>
        <div class="row" style="margin-left: 3%; margin-right: 3%;">
            <p *ngIf="obj['ref_val_cd'] =='COM'">
                आपके प्रार्थना पत्र दिनांक <b>{{obj['application_date']}}</b> के सन्दर्भ में आपको सूचित किया
                जाता है कि आपकी संस्था के
                नाम <b>{{obj['area_alloted']}}</b> वर्ग फुट भूमि का अस्थायी आवंटन कुम्भ मेला अवधि की दिनांक
                <b>2021-04-30</b> तक <b>{{obj['alloted_sector']}}</b> सैक्टर में <b>{{obj['alloted_area']}} </b>
                भूखंड आवंटित कर दिया गया है। जिसका मेला अवधि का 
                आपके द्वारा बोली के दौरान बोली गयी धनराशी रु0 
                <b>{{obj['amount']}}</b>  होता है।
                सेक्टर मे भूमि/प्लाट की स्थिति को देखते हुए उक्त आवंटित प्लाट/भूमि की स्थिति मे परिवर्तन किया जा सकता है |
                 </p>
                
                <p *ngIf="obj['ref_val_cd'] !='COM'">
                    &nbsp;&nbsp;&nbsp;
                            आपके प्रार्थना पत्र दिनांक <b>{{obj['application_date']}}</b> के सन्दर्भ में आपको सूचित किया
                            जाता है कि आपकी संस्था के
                            नाम <b>{{obj['area_alloted']}}</b> वर्ग फुट भूमि का अस्थायी आवंटन कुम्भ मेला अवधि की दिनांक
                            <b>2021-04-30</b> तक <b>{{obj['alloted_sector']}}</b> सैक्टर में <b>{{obj['alloted_area']}} </b>
                            भूखंड  <span *ngIf="obj['amount']==0" ><b>नि:शुल्क </b></span> आवंटित कर दिया गया है।
                            <span *ngIf="obj['amount']!>0" >
                            जिसका मेला अवधि का शुल्क रू0 
                            <b>{{obj['rate']}}</b>  प्रति सौ वर्ग फुट की दर से रू0  
                            <b>{{obj['amount']}}</b>  होता है । </span>
                            सेक्टर मे भूमि/प्लाट की स्थिति को देखते हुए उक्त आवंटित प्लाट/भूमि की स्थिति मे परिवर्तन किया जा सकता है |<br>
                         
                      </p>
       
            </div>

       
        <div class="row" style="margin-left: 3%; margin-right: 3%;">
                    
                <p>
                    <b>शर्तें/प्रतिबन्ध</b><br>
                    1 आवंटित भूमि पर किसी भी प्रकार का स्थायी निर्माण नही किया जायेगा तथा यह आवंटन कुम्भ मेला अवधि की समाप्ति
                    तक पूर्णतः अस्थायी होगा। अवधि समाप्त होने पर आपको अपना कब्जा तत्काल हटाना होगा।<br>
                    2 आवंटित भूमि पर किया जाने वाला अस्थायी निर्माण टिन-टैन्ट आदि भारतीय मानक ब्यूरो द्वारा निर्धारित दिशा निर्देशों
                    के अनुरूप करना होगा।<br>
                    3 आवंटित भूमि का उपयोग निर्धारित उदेश्य हेतु ही किया जायेगा।<br>
                    4 आवंटित भूमि किसी अन्य के पक्ष में हस्तांतरित नही की जायेगी।<br>
                    5 निर्धारित अवधि के पश्चात् भूमि का कब्जा यथावत् मेला प्रशासन द्वारा ले लिया जायेगा।<br>
                    6 आवंटित भूमि के सम्बन्ध में उठने वाले किसी भी विवाद के निस्तारण का क्षेत्राधिकार हरिद्वार स्थित मा0 न्यायालय को होगा।<br>
                    7 आवंटित भूमि पर संस्था के द्वारा अग्निशमन उपकरणो को भी रखना होगा एवंम स्टाल/दूकान/फ़ूड कोर्ट पर घरेलु सिलेंडर का उपयोग नहीं किया जियेगा|<br>
                    8 आवंटित भूमि में साफ-सफाई आदि नियमित रुप से करना होगा।<br>
                    9 भारत सरकार एंव राज्य सरकार द्वारा कुम्भ मेला 2021 हरिद्वार हेतु समय-समय पर जारी कोविड-19 महामारी के दृश्टिगत जारी गाईडलाईन का अनुपालन करना अनिवार्य होगा।<br>
                    10 उपरोक्त शर्तों/प्रतिबंधो के उल्लंघन की दशा में अथवा कोई अन्य कारण जिसे मेलाधिकारी कुम्भ मेला-2021 हरिद्वार उचित समझे का पालन न होने पर आवंटन निरस्त कर दिया जायेगा।<br>
                    11 आवंटी को अपने निजी खर्चे पर प्लाट/दूकान हेतु विधुत एवंम पानी का सयोजन करना होगा |<br>
                    12 खाद्य सामग्री सम्बंधित स्टाल/दूकान के आवंटन पर आवंटी को खाद्य सुरक्षा विभाग से विभागीय अनुमति प्राप्त करनी होगी|
                </p>
            

        </div>

        <div class="row">
           
            <div class="col-10">
                <p style="margin-left: 5%;">संख्या एवं दिनांक तदैव। <br>
                    प्रतिलिपि :-
                </p>
            </div>
            
            <div class="col-2">
                 <img style="margin-left: 10%;" height="65px" 
                src="../../../../assets/sign2.jpg">
                <p style="margin-left: 2%;margin-right: 2%;">
                    ( हरवीर सिंह )<br>
                    अपर मेलाधिकारी
                    <br>कुम्भ मेला 2021,हरिद्वार 

                </p>

           
          </div>
        </div>
      
        <div class="row" style="margin-left: 3%; margin-right: 3%;">

          
                <p> 1. मेला अधिकारी महोदय, कुम्भ मेला-2021, हरिद्वार को सादर सूचनार्थ प्रेषित |<br>
                    2. वरिष्ट पुलिस अधीक्षक महोदय, कुम्भ मेला-2021, हरिद्वार को सादर सूचनार्थ प्रेषित |<br>
                    3. नगर आयुक्त नगर निगम हरिद्वार को सूचनार्थ एंव आवष्यक कार्यवाही हेतू प्रेशित। <br>
                    4. मुख्य वित्त अधिकारी ,कुम्भ मेला-2021, हरिद्वार को सादर सूचनार्थ प्रेषित । <br>
                    5. सम्बन्धित सैक्टर मजिस्ट्रेट,कुम्भ मेला-2021, हरिद्वार को सादर को सूचनार्थ एवं आवश्यक कार्यवाही हेतु। <br>
                    6. अधिशासी अभियंता जल निगम/विधुत वितरण खंड कुम्भ मेला 2021 हरिद्वार को इस आषय के साथ ही आवंटी को नियमतः विधुत एंव पानी का संयोजन देना सुनिष्चित करें।<br>
                    7. अभिहित अधिकारी, खाद्य सुरक्षा विभाग, कुम्भ मेला 2021 हरिद्वार को आवश्यक कार्यवाही हेतु प्रेषित |<br>
                    8. तहसीलदार, कुम्भ मेला-2021, हरिद्वार को इस आशय से की वे धन प्राप्ति की पुष्टि कर
                    प्लाट नम्बर एवं कब्जा आवंटी को उपलब्ध कराना सुनिश्चित करें ।

                </p>
         



        </div>
    </div>

    <div class="row">
        <div class="col-9">


        </div>
        <div class="col-3 ">
            <p style="margin-left: 15%;">

                अपर मेलाधिकारी
                <br>कुम्भ मेला-2021, हरिद्वार <br>


            </p>

        </div>
    </div>

    
    <!-- <div class="setImg" style="text-align: left;">
     ,kumbh mela 2021 uchit samjhe ka paalan na hone par aavantan nirast kar diya jayega
    </div> -->


</div>


<div *ngIf="obj['isAppliedAfterPayment']=='YES'"
    style="background-color: white;width: 60%; margin-left: 20%;margin-bottom: 5%; ">
    <div class="row" style="margin: 5%;">
        <div class="col-3">
            <img width="50%" height="85px" style="margin-top: 5%;" width="70px"
                src="../../../../assets/1200px-Seal_of_Uttarakhand.svg.png">
        </div>
        <div class="col-6 text-center">
            <p style="margin-top: 10%;"></p>
            <b style="font-size: 15px;">कार्यालय मेलाधिकारी कुम्भ मेला - 2021</b>

            <h5>
                मेला नियंत्रण भवन,
                हरिद्वार
                <br>
                <b>संशोधित भूमि आवंटन पत्र</b>
            </h5>


        </div>
        <div class="col-3 text-right">
            <img width="50%" height="85px" style="margin-top: 5%;" width="70px" src="../../../../assets/mahakumbh.png">
        </div>
    </div>
    <div class="row">
    </div>
    <div class="row" style="margin-left: 5%;">
        <div class="col-8">
            <p>प्रत्रांक - {{sr_alt_id}}/भू० आ०/भूमि व्यवस्था/कुम्भ-2021</p>
        </div>
        <div class="col-4 text-center">
            <p>
                दिनांक : {{today}}</p>
        </div>
    </div>
    <div id="paraWithBG">
        <div class="row" style="margin-left: 5%;">
            <div class="col-4">
                <b>{{localizationData['org_code']}} :</b>
            </div>
            <div class="col-8">
                <b> {{obj['ip_newID']}}</b>
            </div>
        </div>
        <div class="row" style="margin-left: 5%;">
            <div class="col-4">
                <b>{{localizationData['name']}} :</b>
            </div>
            <div class="col-8">
                <b> {{obj['ip_name']}} |</b>
            </div>
        </div>
        <div class="row" style="margin-left: 5%;">
            <div class="col-4">
                <b>{{localizationData['organization_type']}} :</b>
            </div>
            <div class="col-8">
                <b> {{obj['ref_val_desc']}} |</b>
            </div>
        </div>
        <div class="row" style="margin-left: 5%;">
            <div class="col-4">
                <b> {{localizationData['address']}} :</b>
            </div>
            <div class="col-8">
                <b> {{obj['ip_address']}} |</b>
            </div>
        </div>

        <div class="row" style="margin-left: 5%;">
            <div class="col-12 text-center">
                <p>
                    <b>विषय :कुम्भ मेला - 2021 हरिद्वार मे<span *ngIf="obj['ref_val_cd'] =='COM'"> व्यवसायिक </span>अस्थाई भूमि का आवंटन</b>
                </p>
            </div>
        </div>
        <div class="row" style="width: 90%; margin-left: 5%;">
            <div class="col-4">पूर्व आवंटित भूमि का क्षेत्रफल</div>
            <div class="col-4">सैक्टर का नाम</div>
            <div class="col-4">प्लाट सं0</div>
        </div>
        <div class="row" style="width: 90%; margin-left: 5%;" *ngFor="let item of cancled_data">
            <div class="col-4">{{item['area_alloted']}}</div>
            <div class="col-4">{{item['alloted_Sector']}}</div>
            <div class="col-4">{{item['alloted_area']}}</div>
        </div>
        <br>
        <div class="row" style="width: 90%; margin-left: 5%;">
            <div class="col-4">संशोधित आवंटित भूमि का क्षेत्रफल</div>
            <div class="col-4">सैक्टर का नाम</div>
            <div class="col-4">प्लाट सं0</div>
        </div>
        <div class="row" style="width: 90%; margin-left: 5%;" *ngFor="let item of alt_data">
            <div class="col-4">{{item['area_alloted']}}</div>
            <div class="col-4">{{item['alloted_Sector']}}</div>
            <div class="col-4">{{item['alloted_area']}}</div>
        </div>
        <div class="row" style="margin-left: 5%; margin-right: 3%;">
            <br>
            <div class="col-12">
                अतिरिक्त देय निर्धारित राशि - {{checkObj['payment']}}
            </div>

        </div>
        <div class="row" style="margin-left: 3%; margin-right: 3%;">
          
                   
                <p>
                    <b>शर्तें/प्रतिबन्ध</b><br>
                    1 आवंटित भूमि पर किसी भी प्रकार का स्थायी निर्माण नही किया जायेगा तथा यह आवंटन कुम्भ मेला अवधि की समाप्ति
                    तक पूर्णतः अस्थायी होगा। अवधि समाप्त होने पर आपको अपना कब्जा तत्काल हटाना होगा।<br>
                    2 आवंटित भूमि पर किया जाने वाला अस्थायी निर्माण टिन-टैन्ट आदि भारतीय मानक ब्यूरो द्वारा निर्धारित दिशा निर्देशों
                    के अनुरूप करना होगा।<br>
                    3 आवंटित भूमि का उपयोग निर्धारित उदेश्य हेतु ही किया जायेगा।<br>
                    4 आवंटित भूमि किसी अन्य के पक्ष में हस्तांतरित नही की जायेगी।<br>
                    5 निर्धारित अवधि के पश्चात् भूमि का कब्जा यथावत् मेला प्रशासन द्वारा ले लिया जायेगा।<br>
                    6 आवंटित भूमि के सम्बन्ध में उठने वाले किसी भी विवाद के निस्तारण का क्षेत्राधिकार हरिद्वार स्थित मा0 न्यायालय को होगा।<br>
                    7 आवंटित भूमि पर संस्था के द्वारा अग्निशमन उपकरणो को भी रखना होगा एवंम स्टाल/दूकान/फ़ूड कोर्ट पर घरेलु सिलेंडर का उपयोग नहीं किया जियेगा|<br>
                    8 आवंटित भूमि में साफ-सफाई आदि नियमित रुप से करना होगा।<br>
                    9 भारत सरकार एंव राज्य सरकार द्वारा कुम्भ मेला 2021 हरिद्वार हेतु समय-समय पर जारी कोविड-19 महामारी के दृश्टिगत जारी गाईडलाईन का अनुपालन करना अनिवार्य होगा।<br>
                    10 उपरोक्त शर्तों/प्रतिबंधो के उल्लंघन की दशा में अथवा कोई अन्य कारण जिसे मेलाधिकारी कुम्भ मेला-2021 हरिद्वार उचित समझे का पालन न होने पर आवंटन निरस्त कर दिया जायेगा।<br>
                    11 आवंटी को अपने निजी खर्चे पर प्लाट/दूकान हेतु विधुत एवंम पानी का सयोजन करना होगा|<br>
                    12 खाद्य सामग्री सम्बंधित स्टाल/दूकान के आवंटन पर आवंटी को खाद्य सुरक्षा विभाग से विभागीय अनुमति प्राप्त करनी होगी|
                </p>


        </div>

        <div class="row">
           
            <div class="col-10">
                <p style="margin-left: 5%;">संख्या एवं दिनांक तदैव। <br>
                    प्रतिलिपि :-
                </p>
            </div>
            
            <div class="col-2">
                 <img style="margin-left: 10%;" height="65px" 
                src="../../../../assets/sign2.jpg">
                <p style="margin-left: 2%;margin-right: 2%;">
                    ( हरवीर सिंह )<br>
                    अपर मेलाधिकारी
                    <br>कुम्भ मेला 2021,हरिद्वार 

                </p>

           
          </div>
        </div>
      
        <div class="row" style="margin-left: 3%; margin-right: 3%;">
            

          
                <p> 1. मेला अधिकारी महोदय, कुम्भ मेला-2021, हरिद्वार को सादर सूचनार्थ प्रेषित |<br>
                    2. वरिष्ट पुलिस अधीक्षक महोदय, कुम्भ मेला-2021, हरिद्वार को सादर सूचनार्थ प्रेषित |<br>
                    3. नगर आयुक्त नगर निगम हरिद्वार को सूचनार्थ एंव आवष्यक कार्यवाही हेतू प्रेशित। <br>
                    4. मुख्य वित्त अधिकारी ,कुम्भ मेला-2021, हरिद्वार को सादर सूचनार्थ प्रेषित । <br>
                    5. सम्बन्धित सैक्टर मजिस्ट्रेट,कुम्भ मेला-2021, हरिद्वार को सादर को सूचनार्थ एवं आवश्यक कार्यवाही हेतु। <br>
                    6. अधिशासी अभियंता जल निगम/विधुत वितरण खंड कुम्भ मेला 2021 हरिद्वार को इस आषय के साथ ही आवंटी को नियमतः विधुत एंव पानी का संयोजन देना सुनिष्चित करें।<br>
                    7.अभिहित अधिकारी, खाद्य सुरक्षा विभाग, कुम्भ मेला 2021 हरिद्वार को आवश्यक कार्यवाही हेतु प्रेषित |<br>
                    8. तहसीलदार, कुम्भ मेला-2021, हरिद्वार को इस आशय से की वे धन प्राप्ति की पुष्टि कर
                    प्लाट नम्बर एवं कब्जा आवंटी को उपलब्ध कराना सुनिश्चित करें ।
                </p>
          



        </div>
    </div>

    <div class="row">
        <div class="col-9">


        </div>
        <div class="col-3 ">
            <p style="margin-left: 15%;">

                अपर मेलाधिकारी
                <br>कुम्भ मेला-2021, हरिद्वार <br>


            </p>

        </div>
    </div>
    <!-- <div class="setImg" style="text-align: left;">
     ,kumbh mela 2021 uchit samjhe ka paalan na hone par aavantan nirast kar diya jayega
    </div> -->


</div>
<div hidden>
    <div id="obrz_2">
        <div class="row" style="width: 94%; margin-left: 8%;">
            <div class="col-3 ">
                <img height="60px" style="margin-top: 5%;" width="80px"
                    src="../../../../assets/1200px-Seal_of_Uttarakhand.svg.png">
            </div>
            <div class="col-6 text-center">
                <p style="margin-top: 10%;"></p>
                <b style="font-size: 15px;">कार्यालय मेलाधिकारी कुम्भ मेला - 2021</b>

                <h5>

                    मेला नियंत्रण भवन,
                    हरिद्वार
                    <br>
                    <b>संशोधित भूमि आवंटन पत्र</b>
                </h5>
            </div>
            <div class="col-3 text-center">
                <img width="50%" height="60px" style="margin-top: 5%;" width="70px"
                    src="../../../../assets/mahakumbh.png">
            </div>
        </div>
        <div class="row" style="margin-left: 8%;">
            <div class="col-8">
                <p>प्रत्रांक - {{sr_alt_id}}/भू० आ०/भूमि व्यवस्था/कुम्भ-2021</p>
            </div>
            <div class="col-4 text-center">
                <p>
                    दिनांक : {{today}}</p>
            </div>
        </div>
        <div id="paraWithBG">
            <div class="row" style="margin-left: 8%;">
                <div class="col-3">
                    <b>{{localizationData['org_code']}} :</b>
                </div>
                <div class="col-9">
                    <b> {{obj['ip_newID']}}</b>
                </div>
            </div>
            <div class="row" style="margin-left: 8%;">
                <div class="col-3">
                    <b>{{localizationData['name']}} :</b>
                </div>
                <div class="col-9">
                    <b> {{obj['ip_name']}} | </b>
                </div>
            </div>
            <div class="row" style="margin-left: 8%;">
                <div class="col-3">
                    <b>{{localizationData['organization_type']}} :</b>
                </div>
                <div class="col-9">
                    <b> {{obj['ref_val_desc']}} | </b>
                </div>
            </div>
            <div class="row" style="margin-left: 8%;">
                <div class="col-3">
                    <b> {{localizationData['address']}} :</b>
                </div>
                <div class="col-9">
                    <b> {{obj['ip_address']}} | </b>
                </div>
            </div>

            <div class="row">
                <div class="col-12 text-center">
                    <p>
                        <b>विषय :कुम्भ मेला - 2021 हरिद्वार मे<span *ngIf="obj['ref_val_cd'] =='COM'"> व्यवसायिक </span>अस्थाई भूमि का आवंटन</b>
                    </p>
                </div>

            </div>
            <div class="row" style="width: 80%; margin-left: 10%;">
                <div class="col-4">पूर्व आवंटित भूमि का क्षेत्रफल</div>
                <div class="col-6">सैक्टर का नाम</div>
                <div class="col-2">प्लाट सं0</div>
            </div>
            <div class="row" style="width: 80%; margin-left: 10%;" *ngFor="let item of cancled_data">
                <div class="col-4">{{item['area_alloted']}}</div>
                <div class="col-6">{{item['alloted_Sector']}}</div>
                <div class="col-2">{{item['alloted_area']}}</div>
            </div>
            <br>
            <div class="row" style="width: 80%; margin-left: 10%;">
                <div class="col-4">संशोधित आवंटित भूमि का क्षेत्रफल</div>
                <div class="col-6">सैक्टर का नाम</div>
                <div class="col-2">प्लाट सं0</div>
            </div>
            <div class="row" style="width: 80%; margin-left: 10%;" *ngFor="let item of alt_data">
                <div class="col-4">{{item['area_alloted']}}</div>
                <div class="col-6">{{item['alloted_Sector']}}</div>
                <div class="col-2">{{item['alloted_area']}}</div>
            </div>
                <div class="col-12">
                    अतिरिक्त देय निर्धारित राशि - {{checkObj['payment']}}
                </div>
                <div class="row" style="margin-left: 3%; margin-right: 3%;">
                   
                        <p>
                            <b>शर्तें/प्रतिबन्ध</b><br>
                            1 आवंटित भूमि पर किसी भी प्रकार का स्थायी निर्माण नही किया जायेगा तथा यह आवंटन कुम्भ मेला अवधि की समाप्ति
                    तक पूर्णतः अस्थायी होगा। अवधि समाप्त होने पर आपको अपना कब्जा तत्काल हटाना होगा।<br>
                    2 आवंटित भूमि पर किया जाने वाला अस्थायी निर्माण टिन-टैन्ट आदि भारतीय मानक ब्यूरो द्वारा निर्धारित दिशा निर्देशों
                    के अनुरूप करना होगा।<br>
                    3 आवंटित भूमि का उपयोग निर्धारित उदेश्य हेतु ही किया जायेगा।<br>
                    4 आवंटित भूमि किसी अन्य के पक्ष में हस्तांतरित नही की जायेगी।<br>
                    5 निर्धारित अवधि के पश्चात् भूमि का कब्जा यथावत् मेला प्रशासन द्वारा ले लिया जायेगा ।<br>
                    6 आवंटित भूमि के सम्बन्ध में उठने वाले किसी भी विवाद के निस्तारण का क्षेत्राधिकार हरिद्वार स्थित मा0 न्यायालय को होगा।<br>
                    7 आवंटित भूमि पर संस्था के द्वारा अग्निशमन उपकरणो को भी रखना होगा एवंम स्टाल/दूकान/फ़ूड कोर्ट पर घरेलु सिलेंडर का उपयोग नहीं किया जियेगा|<br>
                    8 आवंटित भूमि में साफ-सफाई आदि नियमित रुप से करना होगा।<br>
                    9 भारत सरकार एंव राज्य सरकार द्वारा कुम्भ मेला 2021 हरिद्वार हेतु समय-समय पर जारी कोविड-19 महामारी के दृश्टिगत जारी गाईडलाईन का अनुपालन करना अनिवार्य होगा।<br>
                    10 उपरोक्त शर्तों/प्रतिबंधो के उल्लंघन की दशा में अथवा कोई अन्य कारण जिसे मेलाधिकारी कुम्भ मेला-2021 हरिद्वार उचित समझे का पालन न होने पर आवंटन निरस्त कर दिया जायेगा।<br>
                    11 आवंटी को अपने निजी खर्चे पर प्लाट/दूकान हेतु विधुत एवंम पानी का सयोजन करना होगा|<br>
                    12 खाद्य सामग्री सम्बंधित स्टाल/दूकान के आवंटन पर आवंटी को खाद्य सुरक्षा विभाग से विभागीय अनुमति प्राप्त करनी होगी|
                        </p>

               


            </div>

            
            <div class="row">
           
                <div class="col-10">
                    <p style="margin-left: 5%;">संख्या एवं दिनांक तदैव। <br>
                        प्रतिलिपि :-
                    </p>
                </div>
                
                <div class="col-2">
                     <img style="margin-left: 10%;" height="65px" 
                    src="../../../../assets/sign2.jpg">
                    <p style="margin-left: 2%;margin-right: 2%;">
                        ( हरवीर सिंह )<br>
                        अपर मेलाधिकारी
                        <br>कुम्भ मेला 2021,हरिद्वार 
    
                    </p>
    
               
              </div>
            </div>
            <div class="row" style="margin-left: 3%; margin-right: 3%;">

               
                    <p> 1. मेला अधिकारी महोदय, कुम्भ मेला-2021, हरिद्वार को सादर सूचनार्थ प्रेषित |<br>
                        2. वरिष्ट पुलिस अधीक्षक महोदय, कुम्भ मेला-2021, हरिद्वार को सादर सूचनार्थ प्रेषित |<br>
                        3. नगर आयुक्त नगर निगम हरिद्वार को सूचनार्थ एंव आवष्यक कार्यवाही हेतू प्रेशित। <br>
                        4. मुख्य वित्त अधिकारी ,कुम्भ मेला-2021, हरिद्वार को सादर सूचनार्थ प्रेषित । <br>
                        5. सम्बन्धित सैक्टर मजिस्ट्रेट,कुम्भ मेला-2021, हरिद्वार को सादर को सूचनार्थ एवं आवश्यक कार्यवाही हेतु। <br>
                        6. अधिशासी अभियंता जल निगम/विधुत वितरण खंड कुम्भ मेला 2021 हरिद्वार को इस आषय के साथ ही आवंटी को नियमतः विधुत एंव पानी का संयोजन देना सुनिष्चित करें।<br>
                        7.अभिहित अधिकारी, खाद्य सुरक्षा विभाग, कुम्भ मेला 2021 हरिद्वार को आवश्यक कार्यवाही हेतु प्रेषित |<br>
                        8. तहसीलदार, कुम्भ मेला-2021, हरिद्वार को इस आशय से की वे धन प्राप्ति की पुष्टि कर
                        प्लाट नम्बर एवं कब्जा आवंटी को उपलब्ध कराना सुनिश्चित करें ।
                    </p>
               
            </div>
        </div>
        <div class="row">
            <div class="col-9">


            </div>
            <div class="col-3 ">
                <p style="margin-left: 10%;">

                    अपर मेलाधिकारी
                    <br>कुम्भ मेला-2021, हरिद्वार <br>


                </p>

            </div>
        </div>
    </div>
</div>
<div hidden>
    <div id="obrz">
        <div class="row" style="width: 94%; margin-left: 8%;">
            <div class="col-3 ">
                <img height="60px" style="margin-top: 5%;" width="80px"
                    src="../../../../assets/1200px-Seal_of_Uttarakhand.svg.png">
            </div>
            <div class="col-6 text-center">
                <p style="margin-top: 10%;"></p>
                <b style="font-size: 15px;">कार्यालय मेलाधिकारी कुम्भ मेला - 2021</b>

                <h5>

                    मेला नियंत्रण भवन,
                    हरिद्वार
                    <br>
                    <b> भूमि आवंटन पत्र</b>
                </h5>
            </div>
            <div class="col-3 text-center">
                <img width="50%" height="60px" style="margin-top: 5%;" width="70px"
                    src="../../../../assets/mahakumbh.png">
            </div>
        </div>
        <div class="row" style="margin-left: 8%;">
            <div class="col-8">
                <p>प्रत्रांक - {{sr_alt_id}}/भू० आ०/भूमि व्यवस्था/कुम्भ-2021</p>
            </div>
            <div class="col-4 text-center">
                <p>
                    दिनांक : {{today}}</p>
            </div>
        </div>
        <div id="paraWithBG">
            <div class="row" style="margin-left: 8%;">
                <div class="col-3">
                    <b>{{localizationData['org_code']}} :</b>
                </div>
                <div class="col-9">
                    <b> {{obj['ip_newID']}}</b>
                </div>
            </div>
            <div class="row" style="margin-left: 8%;">
                <div class="col-3">
                    <b>{{localizationData['name']}} :</b>
                </div>
                <div class="col-9">
                    <b> {{obj['ip_name']}} | </b>
                </div>
            </div>
            <div class="row" style="margin-left: 8%;">
                <div class="col-3">
                    <b>{{localizationData['organization_type']}} :</b>
                </div>
                <div class="col-9">
                    <b> {{obj['ref_val_desc']}} | </b>
                </div>
            </div>
            <div class="row" style="margin-left: 8%;">
                <div class="col-3">
                    <b> {{localizationData['address']}} :</b>
                </div>
                <div class="col-9">
                    <b> {{obj['ip_address']}} | </b>
                </div>
            </div>

            <div class="row">
                <div class="col-12 text-center">
                    <p>
                        <b>विषय :कुम्भ मेला - 2021 हरिद्वार मे<span *ngIf="obj['ref_val_cd'] =='COM'"> व्यवसायिक </span>अस्थाई भूमि का आवंटन</b>
                    </p>
                    
                </div>

            </div>
            <div class="row" style="margin-left: 3%; margin-right: 3%;">
               
                    <p *ngIf="obj['ref_val_cd'] =='COM'">
                        आपके प्रार्थना पत्र दिनांक <b>{{obj['application_date']}}</b> के सन्दर्भ में आपको सूचित किया
                        जाता है कि आपकी संस्था के
                        नाम <b>{{obj['area_alloted']}}</b> वर्ग फुट भूमि का अस्थायी आवंटन कुम्भ मेला अवधि की दिनांक
                        <b>2021-04-30</b> तक <b>{{obj['alloted_sector']}}</b> सैक्टर में <b>{{obj['alloted_area']}} </b>
                        भूखंड आवंटित कर दिया गया है। जिसका मेला अवधि का 
                        आपके द्वारा बोली के दौरान बोली गयी धनराशी रु0 
                        <b>{{obj['amount']}}</b>  होता है।
                        सेक्टर मे भूमि/प्लाट की स्थिति को देखते हुए उक्त आवंटित प्लाट/भूमि की स्थिति मे परिवर्तन किया जा सकता है |  </p>

                        <p *ngIf="obj['ref_val_cd'] !='COM'">
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            आपके प्रार्थना पत्र दिनांक <b>{{obj['application_date']}}</b> के सन्दर्भ में आपको सूचित किया
                            जाता है कि आपकी संस्था के
                            नाम <b>{{obj['area_alloted']}}</b> वर्ग फुट भूमि का अस्थायी आवंटन कुम्भ मेला अवधि की दिनांक
                            <b>2021-04-30</b> तक <b>{{obj['alloted_sector']}}</b> सैक्टर में <b>{{obj['alloted_area']}} </b>
                            भूखंड  <span *ngIf="obj['amount']==0" ><b>नि:शुल्क </b></span> आवंटित कर दिया गया है।
                            <span *ngIf="obj['amount']!>0" >
                            जिसका मेला अवधि का शुल्क रू0 
                            <b>{{obj['rate']}}</b>  प्रति सौ वर्ग फुट की दर से रू0  
                            <b>{{obj['amount']}}</b>  होता है । </span>
                            सेक्टर मे भूमि/प्लाट की स्थिति को देखते हुए उक्त आवंटित प्लाट/भूमि की स्थिति मे परिवर्तन किया जा सकता है |
                              </p>
                             
                               
                             
                              <p>
                                <b>शर्तें/प्रतिबन्ध</b><br>
                                1 आवंटित भूमि पर किसी भी प्रकार का स्थायी निर्माण नही किया जायेगा तथा यह आवंटन कुम्भ मेला अवधि की समाप्ति
                                तक पूर्णतः अस्थायी होगा। अवधि समाप्त होने पर आपको अपना कब्जा तत्काल हटाना होगा।<br>
                                2 आवंटित भूमि पर किया जाने वाला अस्थायी निर्माण टिन-टैन्ट आदि भारतीय मानक ब्यूरो द्वारा निर्धारित दिशा निर्देशों
                                के अनुरूप करना होगा।<br>
                                3 आवंटित भूमि का उपयोग निर्धारित उदेश्य हेतु ही किया जायेगा।<br>
                                4 आवंटित भूमि किसी अन्य के पक्ष में हस्तांतरित नही की जायेगी।<br>
                                5 निर्धारित अवधि के पश्चात् भूमि का कब्जा यथावत् मेला प्रशासन द्वारा ले लिया जायेगा।<br>
                                6 आवंटित भूमि के सम्बन्ध में उठने वाले किसी भी विवाद के निस्तारण का क्षेत्राधिकार हरिद्वार स्थित मा0 न्यायालय को होगा।<br>
                                7 आवंटित भूमि पर संस्था के द्वारा अग्निशमन उपकरणो को भी रखना होगा एवंम स्टाल/दूकान/फ़ूड कोर्ट पर घरेलु सिलेंडर का उपयोग नहीं किया जियेगा|<br>
                                8 आवंटित भूमि में साफ-सफाई आदि नियमित रुप से करना होगा।<br>
                                9 भारत सरकार एंव राज्य सरकार द्वारा कुम्भ मेला 2021 हरिद्वार हेतु समय-समय पर जारी कोविड-19 महामारी के दृश्टिगत जारी गाईडलाईन का अनुपालन करना अनिवार्य होगा।<br>
                                10 उपरोक्त शर्तों/प्रतिबंधो के उल्लंघन की दशा में अथवा कोई अन्य कारण जिसे मेलाधिकारी कुम्भ मेला-2021 हरिद्वार उचित समझे का पालन न होने पर आवंटन निरस्त कर दिया जायेगा।<br>
                                11 आवंटी को अपने निजी खर्चे पर प्लाट/दूकान हेतु विधुत एवंम पानी का सयोजन करना होगा|<br>
                                12 खाद्य सामग्री सम्बंधित स्टाल/दूकान के आवंटन पर आवंटी को खाद्य सुरक्षा विभाग से विभागीय अनुमति प्राप्त करनी होगी|
                           
                        </p>
            </div>
            <div class="row">
           
                <div class="col-10">
                    <p style="margin-left: 5%;">संख्या एवं दिनांक तदैव। <br>
                        प्रतिलिपि :-
                    </p>
                </div>
                
                <div class="col-2">
                     <img style="margin-left: 10%;" height="65px" 
                    src="../../../../assets/sign2.jpg">
                    <p style="margin-left: 2%;margin-right: 2%;">
                        ( हरवीर सिंह )<br>
                        अपर मेलाधिकारी
                        <br>कुम्भ मेला 2021,हरिद्वार 
    
                    </p>
    
               
              </div>
            </div>
            <div class="row" style="margin-left: 3%; margin-right: 3%;">
              
                    <p> 1. मेला अधिकारी महोदय, कुम्भ मेला-2021, हरिद्वार को सादर सूचनार्थ प्रेषित |<br>
                        2. वरिष्ट पुलिस अधीक्षक महोदय, कुम्भ मेला-2021, हरिद्वार को सादर सूचनार्थ प्रेषित |<br>
                        3. नगर आयुक्त नगर निगम हरिद्वार को सूचनार्थ एंव आवष्यक कार्यवाही हेतू प्रेशित। <br>
                        4. मुख्य वित्त अधिकारी ,कुम्भ मेला-2021, हरिद्वार को सादर सूचनार्थ प्रेषित । <br>
                        5. सम्बन्धित सैक्टर मजिस्ट्रेट,कुम्भ मेला-2021, हरिद्वार को सादर को सूचनार्थ एवं आवश्यक कार्यवाही हेतु। <br>
                        6. अधिशासी अभियंता जल निगम/विधुत वितरण खंड कुम्भ मेला 2021 हरिद्वार को इस आषय के साथ ही आवंटी को नियमतः विधुत एंव पानी का संयोजन देना सुनिष्चित करें।<br>
                        7.अभिहित अधिकारी, खाद्य सुरक्षा विभाग, कुम्भ मेला 2021 हरिद्वार को आवश्यक कार्यवाही हेतु प्रेषित |<br>
                        8. तहसीलदार, कुम्भ मेला-2021, हरिद्वार को इस आशय से की वे धन प्राप्ति की पुष्टि कर
                        प्लाट नम्बर एवं कब्जा आवंटी को उपलब्ध कराना सुनिश्चित करें ।
                    </p>
                
            </div>
        </div>
        <div class="row">
            <div class="col-9">


            </div>
            <div class="col-3 ">
                <p style="margin-left: 10%;">

                    अपर मेलाधिकारी
                    <br>कुम्भ मेला-2021, हरिद्वार <br>


                </p>

            </div>
        </div>
    </div>
</div>