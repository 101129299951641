<ngx-spinner>Loading...</ngx-spinner>
<div class="row" style="margin-right:5%; margin-top: 2%;">
    <div class="col-6"></div>
    <div class="col-6" *ngIf="file_status_flag">
        <div class="alert alert-info" role="alert" style="width: 90%; margin-left: 5;">
            <div class="row">
                <div class="col-4">
                    <h2> {{localizationData['your_file_status']}} :</h2>
                </div>
                <div class="col-7">
                    <span style="font-size: 20px;"> {{file_status_data['exempted_file_status']}}</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button (click)="getDocument()"
                        class="btn btn-success">{{localizationData['view_doc']}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
<br><br>
<div>
    <mat-card style="background-color: #EE9A4D; width: 80%; margin-left: 10%;">
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ localizationData.total_application }}
                    </mat-panel-title>
                    <mat-panel-description style="margin-left: 2%; text-align: center;">
                        &nbsp;&nbsp;&nbsp;&nbsp; {{ allCount }}
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <!-- <div fxLayout fxLayoutAlign="center center"> -->
                <mat-form-field fxFlex="40%">
                    <input matInput type="text" (keyup)="totalFilter($event.target.value)" placeholder="Filter" />
                </mat-form-field>
                <!-- </div> -->
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
                    <ng-container matColumnDef="ip_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.name }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.ip_name }}</td>
                    </ng-container>
                    <ng-container matColumnDef="ip_address">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.address }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.ip_address }}</td>
                    </ng-container>
                    <ng-container matColumnDef="ip_mobile">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.mobile_number }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.ip_mobile }}</td>
                    </ng-container>
                    <ng-container matColumnDef="rep_id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.rep_id }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.rep_id }}</td>
                    </ng-container>
                    <ng-container matColumnDef="ip_email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.email }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="element.ip_email =='null'"></span>
                            <span *ngIf="element.ip_email !='null'">{{ element.ip_email }}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="details">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.action }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div *ngIf="element.status == 1">
                                <button mat-raised-button color="primary"
                                    [routerLink]="['/registration_form/' + element.ip_mobile]">
                                    {{ localizationData.complete_application }} <Form></Form>
                                </button>
                            </div>
                            <div *ngIf="element.status >3 && element.status <=7 && element['isApplicable_service']=='YES'">
                                <button class="btn btn-primary" (click)="clickNavi('/melaServices/', element.ip_mobile)">
                                    {{ localizationData.apply_service }}
                                </button>
                            </div>
                            <div *ngIf="element.status == 2" [routerLink]="['/registration_form/' + element.ip_mobile]">
                                <button mat-raised-button color="primary">
                                    {{ localizationData.complete_application }} <Form></Form>
                                </button>
                            </div>
                            <div *ngIf="element.status == 3 || element.status == 8 ||  element.status == 10">
                                <button mat-raised-button color="primary">
                                    {{ localizationData.wait_for_further_notice }}
                                </button>
                            </div>
                            <div *ngIf="element.isService_ltr_generated == 1 && element.status !=9">
                                <button mat-raised-button (click)="view_service_letter(element)" color="primary">
                                   Download Service Letter
                                </button>
                            </div>
                            <!-- isService_ltr_generated -->
                            <div *ngIf="element.status == 4 || element.status == 11">
                                <button mat-raised-button color="primary">
                                    {{ localizationData.wait_for_further_notice }}
                                </button>
                                <button class="btn btn-danger" (click)="cancle_Your_Application(element)">
                                    {{ localizationData.cancle_your_app_and_get_refund }}
                                </button>

                            </div>
                            <div *ngIf="element.status == 9 ">
                                <button class="btn btn-danger" (click)="cancleSwal()">
                                    {{ localizationData.your_app_cancled }}
                                </button>
                            </div>
                            <div *ngIf="element.status == 4">
                                <button mat-raised-button color="primary" (click)="downloadPdf(element)">
                                    {{ localizationData.download_msg }}
                                </button>
                            </div>
                            <div *ngIf="element.status == 5 ">
                                <button mat-raised-button color="primary"
                                    [routerLink]="['/payment/' + element.ip_mobile]">
                                    {{ localizationData.pay_allot_fee }}
                                </button>
                                <button mat-raised-button class="btn btn-info" data-target="#exampleModalPreview3"
                                    data-toggle="modal" (click)="setReAllotData(element)">
                                    {{ localizationData.req_for_reallot }}
                                </button>
                            </div>
                            <div
                                *ngIf="(element.status == 6 || element.status == 7 || element.status == 5 ) && element['fee_type']=='Payment'">
                                <button mat-raised-button color="primary" (click)="redirectToIciciPage(element)">
                                    {{ localizationData['check_txn_status'] }}
                                </button>
                            </div>
                            <button mat-raised-button *ngIf=" (element.status == 7 || element.status == 6) "
                                data-target="#exampleModalPreview3" data-toggle="modal" class="btn btn-primary"
                                (click)='setReallotData(element)'>{{localizationData.req_reallot}}</button>

                            <div
                                *ngIf="(element.status == 6 || element.status == 7||element.status == 5||element.status == 10) && element['fee_type']=='Exempted'">
                                <button class="btn btn-success" (click)="set_file_status_flag(element)"
                                    (click)="(element)">
                                    {{ localizationData['file_status'] }}
                                </button>

                            </div>
                            <div *ngIf="element.status == 7">
                                <button mat-raised-button color="primary" (click)="allotLetter(element.ip_mobile)">
                                    {{ localizationData.down_allot_letter }}
                                </button>
                            </div>
                            <!-- <div *ngIf="element.status == 8">
                                <button mat-raised-button color="primary">
                  {{ localizationData.down_allot_letter }}
                </button>
                            </div> -->

                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsa"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsa"></tr>
                </table>
                <div>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20, 50]"></mat-paginator>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ localizationData.partial_application }}
                    </mat-panel-title>
                    <mat-panel-description style="text-indent: 2em;">
                        {{ partCount }}
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-form-field fxFlex="40%">
                    <input matInput type="text" (keyup)="partFilter($event.target.value)" placeholder="Filter" />
                </mat-form-field>
                <table mat-table [dataSource]="partDataSource" class="mat-elevation-z8" matSort>
                    <ng-container matColumnDef="ip_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.name }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.ip_name }}</td>
                    </ng-container>
                    <ng-container matColumnDef="ip_address">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.address }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.ip_address }}</td>
                    </ng-container>
                    <ng-container matColumnDef="ip_mobile">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.mobile_number }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.ip_mobile }}</td>
                    </ng-container>
                    <ng-container matColumnDef="rep_id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.rep_id }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.rep_id }}</td>
                    </ng-container>
                    <ng-container matColumnDef="ip_email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.email }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="element.ip_email =='null'"></span>
                            <span *ngIf="element.ip_email !='null'">{{ element.ip_email }}</span>

                        </td>
                    </ng-container>

                    <ng-container matColumnDef="details">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.action }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div *ngIf="element.status == 1">
                                <button mat-raised-button color="primary"
                                    [routerLink]="['/registration_form/' + element.ip_mobile]">
                                    {{ localizationData.complete_application }} <Form></Form>
                                </button>
                            </div>

                            <div *ngIf="element.status == 2" [routerLink]="['/registration_form/' + element.ip_mobile]">
                                <button mat-raised-button color="primary">
                                    {{ localizationData.complete_application }} <Form></Form>
                                </button>
                            </div>
                            <button class="btn btn-danger" (click)="deleteUser(element)">
                                {{localizationData['delete_user']}} <Form></Form>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsa"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsa"></tr>
                </table>
                <div>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20, 50]"></mat-paginator>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ localizationData.completed_application }}
                    </mat-panel-title>
                    <mat-panel-description style="text-indent: 0%;">
                        {{ comCount }}
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="row">
                    <div class="col-6">
                        <mat-form-field fxFlex="40%">
                            <input matInput type="text" (keyup)="comFilter($event.target.value)" placeholder="Filter" />
                        </mat-form-field>
                    </div>

                </div>
                <table mat-table [dataSource]="comDataSource" class="mat-elevation-z8" matSort>
                    <ng-container matColumnDef="ip_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.name }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.ip_name }}</td>
                    </ng-container>
                    <ng-container matColumnDef="ip_address">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.address }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.ip_address }}</td>
                    </ng-container>
                    <ng-container matColumnDef="ip_mobile">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.mobile_number }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.ip_mobile }}</td>
                    </ng-container>
                    <ng-container matColumnDef="rep_id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.rep_id }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.rep_id }}</td>
                    </ng-container>
                    <ng-container matColumnDef="ip_email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.email }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="element.ip_email =='null'"></span>
                            <span *ngIf="element.ip_email !='null'">{{ element.ip_email }}</span>

                        </td>
                    </ng-container>
                    <ng-container matColumnDef="details">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.action }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div *ngIf="element.status == 3 ">
                                <button mat-raised-button color="primary">
                                    {{ localizationData.wait_for_further_notice }}
                                </button>
                            </div>
                            <div *ngIf="element.status == 4 || element.status == 11 || element.status == 10">
                                <button mat-raised-button color="primary">
                                    {{ localizationData.wait_for_further_notice }}
                                </button>
                                <button class="btn btn-danger" *ngIf="element.status == 4 || element.status == 11"
                                    (click)="cancle_Your_Application(element)">
                                    {{ localizationData.cancle_your_app_and_get_refund }}
                                </button>
                                <div *ngIf="element.status == 4">
                                    <button mat-raised-button color="primary" (click)="downloadPdf(element)">
                                        {{ localizationData.download_msg }}
                                    </button>
                                </div>
                            </div>
                            <div *ngIf="element.isService_ltr_generated == 1 && element.status != 9">
                                <button mat-raised-button (click)="view_service_letter(element)" color="primary">
                                   Download Service Letter
                                </button>
                            </div>
                            <div *ngIf="element.status == 9 ">
                                <button class="btn btn-danger" (click)="cancleSwal()">
                                    {{ localizationData.your_app_cancled }}
                                </button>
                            </div>
                            <div *ngIf="element.status >3 && element.status <=7 && element['isApplicable_service']=='YES'">
                                <button class="btn btn-primary" (click)="clickNavi('/melaServices/', element.ip_mobile)">
                                    {{ localizationData.apply_service }}
                                </button>
                            </div>
                            <div *ngIf="element.status == 5">
                                <button mat-raised-button color="primary"
                                    [routerLink]="['/payment/' + element.ip_mobile]">
                                    {{ localizationData.pay_allot_fee }}
                                </button>
                                <button mat-raised-button class="btn btn-info" data-target="#exampleModalPreview2"
                                    data-toggle="modal" (click)="setReAllotData(element)">
                                    {{ localizationData.req_for_reallot }}
                                </button>
                            </div>
                            <div
                                *ngIf="(element.status == 6 || element.status == 7 || element.status == 5) && element['fee_type']=='Payment'">
                                <button mat-raised-button color="primary" (click)="redirectToIciciPage(element)">
                                    {{ localizationData['check_txn_status'] }}
                                </button>
                            </div>
                            <button mat-raised-button *ngIf=" (element.status == 7 || element.status == 6) "
                                data-target="#exampleModalPreview3" data-toggle="modal" class="btn btn-primary"
                                (click)='setReallotData(element)'>{{localizationData.req_reallot}}</button>

                            <div
                                *ngIf="(element.status == 6 ||element.status == 10|| element.status == 7) && element['fee_type']=='Exempted'">
                                <button class="btn btn-success" (click)="set_file_status_flag(element)"
                                    (click)="(element)">
                                    {{ localizationData['file_status'] }}
                                </button>

                            </div>
                            <div *ngIf="element.status == 7">
                                <button mat-raised-button color="primary" (click)="allotLetter(element.ip_mobile)">
                                    {{ localizationData.down_allot_letter }}
                                </button>
                            </div>

                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsa"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsa"></tr>
                </table>
                <div>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20, 50]"></mat-paginator>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-card>
</div>
<div class="loader" *ngIf="show_spinner"></div>

<div class="modal fade" id="exampleModalPreview2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLabel">
                    <h1> {{ localizationData.req_for_reallot }}
                    </h1>
                </h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 text-left">
                        <h3>Applicant Mobile :</h3>
                    </div>
                    <div class="col-3">
                        <input type="text" [(ngModel)]="reAllotObj['ip_mobile']" disabled class="form-control" name=""
                            id="">
                    </div>
                    <div class="col-3"></div>

                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 text-left">
                        <h3>Applicant Name :</h3>
                    </div>
                    <div class="col-3">
                        <input type="text" [(ngModel)]="reAllotObj['ip_name']" disabled class="form-control" name=""
                            id="">
                    </div>
                    <div class="col-3"></div>

                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 text-left">
                        <h3>Applicant Address :</h3>
                    </div>
                    <div class="col-3">
                        <input type="text" [(ngModel)]="reAllotObj['ip_address']" disabled class="form-control" name=""
                            id="">
                    </div>
                    <div class="col-3"></div>

                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 text-left">
                        <h3>Requested Sector :</h3>
                    </div>
                    <div class="col-3">
                        <input type="text" [(ngModel)]="reAllotObj['req_sector']" disabled class="form-control" name=""
                            id="">
                    </div>
                    <div class="col-3"></div>

                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 text-left">
                        <h3>Requested Area (SQFT):</h3>
                    </div>
                    <div class="col-3">
                        <input type="text" [(ngModel)]="reAllotObj['req_area']" disabled class="form-control" name=""
                            id="">
                    </div>
                    <div class="col-3"></div>

                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 text-left">
                        <h3>Alloted Sector :</h3>
                    </div>
                    <div class="col-3">
                        <input type="text" [(ngModel)]="reAllotObj['alloted_sector']" disabled class="form-control"
                            name="" id="">
                    </div>
                    <div class="col-3"></div>

                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 text-left">
                        <h3>Alloted Area :</h3>
                    </div>
                    <div class="col-3">
                        <input type="text" [(ngModel)]="reAllotObj['alloted_area']" disabled class="form-control"
                            name="" id="">
                    </div>
                    <div class="col-3"></div>

                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 text-left">
                        <h3>Total Area Alloted (SQFT) :</h3>
                    </div>
                    <div class="col-3">
                        <input type="text" [(ngModel)]="reAllotObj['total_area_alloted']" disabled class="form-control"
                            name="" id="">
                    </div>
                    <div class="col-3"></div>

                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 text-left">
                        <h3>Reason For Re-allotment :</h3>
                    </div>
                    <div class="col-3">
                        <!-- <input type="text" [(ngModel)]="reAllotObj['re_alt_desc']" class="form-control" name="" id=""> -->
                        <textarea ng-model="myTextarea" class="form-control"
                            [(ngModel)]="reAllotObj['re_alt_desc']"></textarea>
                    </div>
                    <div class="col-3"></div>

                </div>
                <br>

                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" style="font-size: 16px;"
                        (click)="sendForRe_allot()">Submit</button>
                    <button type="button" class="btn btn-danger" style="font-size: 16px;" data-dismiss="modal">
                        close
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalPreview3" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLabel">
                    <h1> {{ localizationData.req_for_reallot }}
                    </h1>
                </h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 text-left">
                        <h3>Applicant Mobile :</h3>
                    </div>
                    <div class="col-3">
                        <input type="text" [(ngModel)]="reAllotObj['ip_mobile']" disabled class="form-control" name=""
                            id="">
                    </div>
                    <div class="col-3"></div>

                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 text-left">
                        <h3>Applicant Name :</h3>
                    </div>
                    <div class="col-3">
                        <input type="text" [(ngModel)]="reAllotObj['ip_name']" disabled class="form-control" name=""
                            id="">
                    </div>
                    <div class="col-3"></div>

                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 text-left">
                        <h3>Applicant Address :</h3>
                    </div>
                    <div class="col-3">
                        <input type="text" [(ngModel)]="reAllotObj['ip_address']" disabled class="form-control" name=""
                            id="">
                    </div>
                    <div class="col-3"></div>

                </div>
                <br>
                <div class="row" *ngIf="reAllotObj['req_sec_desc']">
                    <div class="col-3"></div>
                    <div class="col-3 text-left">
                        <h3>Requested Sector :</h3>
                    </div>
                    <div class="col-3">
                        <input type="text" [(ngModel)]="reAllotObj['req_sec_desc']" disabled class="form-control"
                            name="" id="">
                    </div>
                    <div class="col-3"></div>

                </div>
                <br>
                <div class="row" *ngIf="reAllotObj['req_area']">
                    <div class="col-3"></div>
                    <div class="col-3 text-left">
                        <h3>Requested Area (SQFT):</h3>
                    </div>
                    <div class="col-3">
                        <input type="text" [(ngModel)]="reAllotObj['req_area']" disabled class="form-control" name=""
                            id="">
                    </div>
                    <div class="col-3"></div>

                </div>
                <br>
                <div class="row" *ngIf="reAllotObj['alloted_Sec']">
                    <div class="col-3"></div>
                    <div class="col-3 text-left">
                        <h3>Alloted Sector :</h3>
                    </div>
                    <div class="col-3">
                        <input type="text" [(ngModel)]="reAllotObj['alloted_Sec']" disabled class="form-control" name=""
                            id="">
                    </div>
                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row" *ngIf="reAllotObj['alloted_area_desc']">
                    <div class="col-3"></div>
                    <div class="col-3 text-left">
                        <h3>Alloted Area :</h3>
                    </div>
                    <div class="col-3">
                        <input type="text" [(ngModel)]="reAllotObj['alloted_area_desc']" disabled class="form-control"
                            name="" id="">
                    </div>
                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row" *ngIf="reAllotObj['area_alloted']">
                    <div class="col-3"></div>
                    <div class="col-3 text-left">
                        <h3>Total Area Alloted (SQFT) :</h3>
                    </div>
                    <div class="col-3">
                        <input type="text" [(ngModel)]="reAllotObj['area_alloted']" disabled class="form-control"
                            name="" id="">
                    </div>
                    <div class="col-3"></div>

                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 text-left">
                        <h3>Reason For Re-allotment :</h3>
                    </div>
                    <div class="col-3">
                        <!-- <input type="text" [(ngModel)]="reAllotObj['re_alt_desc']" class="form-control" name="" id=""> -->
                        <textarea ng-model="myTextarea" class="form-control"
                            [(ngModel)]="reAllotObj['re_alt_desc']"></textarea>
                    </div>
                    <div class="col-3"></div>
                </div>
                <br>

                <div class="modal-footer">
                    <button *ngIf="reAllotObj['status']==6 || reAllotObj['status']==7" type="button"
                        class="btn btn-primary" style="font-size: 16px;"
                        (click)="submitRequestForReAlot()">Submit</button>
                    <button *ngIf="reAllotObj['status']==5" type="button" class="btn btn-primary"
                        style="font-size: 16px;" (click)="sendForRe_allot()">Submit</button>
                    <button type="button" class="btn btn-danger" style="font-size: 16px;" data-dismiss="modal">
                        close
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- sendForRe_allot -->
<div hidden>
    <div style="background-color: white;" id="obrz">

        <div class="row">
            <div class="col-12 text-center">
                <img width="45%" height="80px" width="70px" src="../../../../assets/mahakumbh.png">
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center">
                <!-- <h3>
                दिव्य कुम्भ भाव कुम्भ</h3> -->
                <p style="margin-top: 2px; font-size: 14px;">कार्यालय मेलाधिकारी कुम्भ मेला - 2021</p>
                <h5>
                    मेला नियंत्रण भवन,
                    हरिद्वार</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center" style="font-size: 25px;">
                पावती पर्ची
            </div>
        </div>
        <br>
        <hr style="width: 90%; margin-left: 5%;">
        <div class="row" style="margin-left:3%;margin-right: 3%;">
            <div class="col-3">
                <h4>S No. :</h4>
            </div>
            <div class="col-9" *ngIf="print_data['id']">
                {{print_data['id']}}
            </div>
        </div>
        <div class="row" style="margin-left:3%;margin-right: 3%;">
            <div class="col-3">
                <h4>Applicant Name :</h4>
            </div>
            <div class="col-9">
                {{print_data['ip_name']}}
            </div>
        </div>

        <div class="row" style="margin-left:3%;margin-right: 3%;">
            <div class="col-3">
                <h4>Address :</h4>
            </div>
            <div class="col-9">
                {{print_data['ip_address']}}
            </div>
        </div>

        <div class="row" style="margin-left:3%;margin-right: 3%;">
            <div class="col-3">
                <h4>Applicant Mobile :</h4>
            </div>
            <div class="col-3">
                {{print_data['ip_mobile']}}
            </div>
            <div class="col-3">
                <h4>Applicant Email :</h4>
            </div>
            <div class="col-3" *ngIf="print_data['ip_email']">
                {{print_data['ip_email']}}
            </div>
        </div>
        <div class="row" style="margin-left:3%;margin-right: 3%;">
            <div class="col-3">
                <h4>Organization Type :</h4>
            </div>
            <div class="col-3">
                {{print_data['ref_val_desc']}}
            </div>
            <div class="col-3">
                <h4>Requested Sector :</h4>
            </div>
            <div class="col-3">
                {{print_data['req_sector']}}
            </div>
        </div>

        <div class="row" style="margin-left:3%;margin-right: 3%;">
            <div class="col-3">
                <h4>Requested Area :</h4>
            </div>
            <div class="col-3" *ngIf="print_data['req_area_id']">
                {{print_data['req_area_id'] +' SQFT'}}
            </div>
            <div class="col-3" *ngIf="print_data['req_area']">
                {{print_data['req_area'] +' SQFT'}}
            </div>
            <div class="col-3">
                <h4>Purpose :</h4>
            </div>
            <div class="col-3" *ngIf="print_data['purpose']">
                {{print_data['purpose']}}
            </div>
        </div>

        <div class="row" style="margin-left:3%;margin-right: 3%;">
            <div class="col-3">
                <h4>Comment :</h4>
            </div>
            <div class="col-3" *ngIf="print_data['comment']">
                {{print_data['comment']}}
            </div>
            <div class="col-3"></div>
            <div class="col-3"></div>
        </div>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <div class="row" style="margin-left:3%;margin-right: 3%;">
            <div class="col-8">
                * Note : This is a computer generated document.
            </div>
            <div class="col-4 text-right">
                Date :
                {{today}}
            </div>
        </div>
    </div>
</div>