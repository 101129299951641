import { Injectable } from "@angular/core";
import { ApiHandlerService } from "src/app/shares/services/api-handler.service";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { tap } from "rxjs/operators";
import { ILogin, Datum } from "./models/login.model";
import { MobileNumberService } from "src/app/shares/store/mobile-number.service";
import { ILoginRequest } from "./models/login-request.model";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  loginInfo: Datum;
  userInfo$ = new BehaviorSubject<ILogin>(null);
  totalAppNum;
  partAppNum;
  login_mob;

  setTotalApp(totalAppCount: string) {
    this.totalAppNum = totalAppCount;
  }
  getTotalApp(): string {
    return this.totalAppNum;
  }
  setPartApp(partAppCount: string) {
    this.partAppNum = partAppCount;
  }
  getPartApp(): string {
    return this.partAppNum;
  }
  constructor(
    private _apiHandler: ApiHandlerService,
    private _mobileService: MobileNumberService
  ) {}
  private setLogInData(data: Datum) {
    this.loginInfo = data;
  }
  getLogInData(): Datum {
    return this.loginInfo;
  }

  login(mobileNo: ILoginRequest,is_otp): Observable<ILogin> {
    console.log("authentication", mobileNo);
    this.login_mob=mobileNo['ip_mobile']
    const mobile ={ip_mobile:mobileNo.ip_mobile,is_otp:is_otp}
    const api = "isMobileFound";
    return this._apiHandler.post<any,any>(api, mobile).pipe(
      tap((ev) => {
        // this.setLogInData(ev);
        // console.log("ev", ev);
        this.userInfo$.next(ev);
      })
    );
  }
  getAppCount():Observable<any>{
    const mobile ={rep_id:this._mobileService.getMobileNumber()}
    const api = "rep_data_count"
    return this._apiHandler.postAdmin<any,any>(api,mobile)
  }
  getAppCount2():Observable<any>{
    const mobile ={rep_id:this._mobileService.getMobileNumber()}
    const api = "master_rep_AllData"
    return this._apiHandler.postAdmin<any,any>(api,mobile)
  }
  getPartAppCount():Observable<any>{
    const mobile ={rep_id:this._mobileService.getMobileNumber()}
    const api = "rep_data_2_count"
    return this._apiHandler.postAdmin<any,any>(api,mobile)
  }
  getPartAppCount2():Observable<any>{
    const mobile ={rep_id:this._mobileService.getMobileNumber()}
    const api = "rep_data_2_for_msr"
    return this._apiHandler.postAdmin<any,any>(api,mobile)
  }
  getProfile(): Observable<ILogin> {
    const mobileNo = this._mobileService.getMobileNumber();
    const data = { ip_mobile: mobileNo };
    //const api = "isMobileFound";
    const api = "profile";
    return this._apiHandler.post<any, any>(api, data).pipe(
      tap((ev) => {
        //this.setLogInData(ev);
        console.log("ev", ev);
        this.userInfo$.next(ev);
      })
    );
  }
  getServDetail(mobile):Observable<any>{
    
    const api ="user_service_id"
    return this._apiHandler.postServiceData<any,any>(api,mobile)

  }
  loggedIn(){
    return !!localStorage.getItem('kbh_token')
  }
  getToken(){
    return localStorage.getItem('kbh_token')
  }
}
