import {
  Component,
  OnInit,
  QueryList,
  ViewChildren,
  ViewChild,
  ElementRef,
  Inject,
} from "@angular/core";
import { ApiHandlerService } from 'src/app/shares/services/api-handler.service';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { HttpClient } from "@angular/common/http";
import { MatTableDataSource } from "@angular/material/table";
import { NgxSpinnerService } from 'ngx-spinner'
import "jspdf-autotable";
import { RegistrationFormService } from "../registration-form/registration-form.service";
import { ToastrService } from "ngx-toastr";
import Swal from 'sweetalert2'
import { ILocalization } from "src/app/shares/models";
import { AuthService } from "../auth/auth.service";
import { LocalizationService } from "src/app/shares/store/localization.service";
@Component({
  selector: 'app-add-land',
  templateUrl: './add-land.component.html',
  styleUrls: ['./add-land.component.css']
})
export class AddLandComponent implements OnInit {
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  displayedColumns: string[] = ['sno', 'plot_name', 'plot_area', 'isActive', 'action'];
  dataSource;
  localizationData: ILocalization;

  constructor(private spinner: NgxSpinnerService,
    private _registrationLandService: RegistrationFormService,
    private _apiHandler: ApiHandlerService,
    private http: HttpClient,
    private _toastrService: ToastrService,
    private _localization:LocalizationService,
    private authService:AuthService
  ) { }
  cat = [{ id: 0, value: 'General' }, { id: 1, value: 'Reserved' }]
  isActive = [{ value: 'Active' }, { value: 'InActive' }]
  login_mob;
  async ngOnInit() {
    this._localization.geti18nData().subscribe((res: ILocalization) => {
      this.localizationData = res;
    });
    this.login_mob=this.authService.login_mob
    await this.getSec()
  }
  
  obj = {}
  obj2 = {}
  get_sector;
  enableSector = []
  data;
  refresh() {

  }
  filter = (value: string) => {
    //("inside filter method");
    this.verifydataSource.filter = value.trim().toLocaleLowerCase();
  };
  updateObj = {}
  update_modal(data) {
    console.log(data)
    this.updateObj = data
  }
  async getSec() {
    this.spinner.show()
    this.enableSector = []
    this._registrationLandService.getSector().subscribe((res) => {
      let data: any = res
      console.log(res)
      let dummy = []
      for (let i = 0; i < data.length; i++) {
        data[i]['sno'] = i + 1
        if (data[i]['sec_id'] != 0) {
          if (data[i]['isActive'] == 'Active') {
            data[i]['sec_id'] = Number(data[i]['sec_id'])
            this.enableSector.push(data[i])
          }
          this.data = this.enableSector
          dummy.push(data[i])
        }
      }
      console.log(this.enableSector, 'enableSector')
      // this.verifydataSource = new MatTableDataSource(data);
      // this.verifydataSource.paginator = this.paginator.toArray()[0];
      // this.verifydataSource.sort = this.sort.toArray()[0];
      this.spinner.hide()
    });
  }
  data2 = []
  removeRow(item, i) {
    this.data2.splice(i, 1)
  }
  addRow() {
    this.data2.push({ lat: 0, long: 0 })
  }
  submit1() {

    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i]['sec_id'] == this.obj['sec_id']) {
        this.obj['sec_name'] = this.data[i]['sec_name']
      }
    }
    this.spinner.show()
    this.http.post(this._apiHandler.baseURL2 + 'add_Plot_MAA', {
      'isActive': this.obj['isActive'],
      'area': this.obj['area'],
      'catagory': this.obj['catagory'],
      'sec_id': this.obj['sec_id'],
      'plot_desc': this.obj['plot_desc'],
      'sec_name': this.obj['sec_name'],
      'ip_mobile':this.login_mob
      //  'data':JSON.stringify(this.data2)
    }).subscribe(res => {
      console.log(res, 'resp')
      this.spinner.hide()
      if (res['Success'] == 'Ok') {
        if (res['data'] == 'Plot Description already exists') {
          this._toastrService.success('This Plot Name Is Already Exists', "",
            {
              positionClass: "toast-top-center",
              progressBar: true,
            });
        } else {
          this._toastrService.success('Successfully Added', "",
            {
              positionClass: "toast-top-center",
              progressBar: true,
            });
          this.showPlots()
        }
      } else {
        this._toastrService.error('Server Side Error', "",
          {
            positionClass: "toast-top-center",
            progressBar: true,
          });
      }
    })
  }
  obj3 = {}
  all_Plots = []
  allPlots = []
  verifydataSource;
  showPlots() {
    this.spinner.show()
    this.http.post(this._apiHandler.baseURL2 + 'getAllPlot_By_a_sector', {
      'sec_id': this.obj2['sec_id'],
      //  'data':JSON.stringify(this.data2)
    }).subscribe(res => {
      console.log(res, 'resp')
      this.spinner.hide()
      this.all_Plots = res['data']
      this.allPlots = this.all_Plots
      this.spinner.hide()
      console.log(this.all_Plots)
      // this._toastrService.success('Status Changed To Active', "",
      // {
      //   positionClass: "toast-top-center",
      //   progressBar: true,
      // });
      // this.getSec()
      this.obj2['plot_cd']=null
      this.allLocations=[]
      for (let i = 0; i < this.allPlots.length; i++) {
        this.allPlots[i]['sno'] = i + 1
      }
      this.verifydataSource = new MatTableDataSource(this.allPlots);
      this.verifydataSource.paginator = this.paginator.toArray()[0];
      this.verifydataSource.sort = this.sort.toArray()[0];

    })

  }
  submit2() {
    this.spinner.show()
    this.http.post(this._apiHandler.baseURL2 + 'insert_locationOfPlot', {
      'sec_id': this.obj2['sec_id'],
      'plot_cd': this.obj2['plot_cd'],
      'data': JSON.stringify(this.data2)
    }).subscribe(res => {
      console.log(res, 'resp')
      this.spinner.hide()
      this.all_Plots = res['data']
      this.spinner.hide()
      console.log(this.all_Plots)
      if (res['Success'] == 'Ok') {
        this._toastrService.success('Successfully Inserted', "",
          {
            positionClass: "toast-top-center",
            progressBar: true,
          });
        this.getSec()
      } else {
        this._toastrService.error('Server Side Error', "",
          {
            positionClass: "toast-top-center",
            progressBar: true,
          });
      }

      // this.getSec()

    })
  }
  removeRow3(item, i) {
    this.allLocations.splice(i, 1)
  }
  addRow3() {
    this.allLocations.push({ lat: 0, lng: 0 })
  }
  allLocations = []
  getPlotLocation() {
    this.spinner.show()
    this.http.post(this._apiHandler.baseURL2 + 'getPlot_locations', {
      'plot_cd': this.obj2['plot_cd'],
    }).subscribe(res => {
      this.spinner.hide()
      this.allLocations = res['data']
      this.spinner.hide()
    })
  }
  submit3() {
    console.log(this.allLocations)
    this.spinner.show()
    this.http.post(this._apiHandler.baseURL2 + 'updateOrDeleteLocations', {
      'sec_id': this.obj2['sec_id'],
      'plot_cd': this.obj2['plot_cd'],
      'data': JSON.stringify(this.allLocations)
    }).subscribe(res => {
      console.log(res, 'resp')
      this.spinner.hide()
      this.all_Plots = res['data']
      this.spinner.hide()
      console.log(this.all_Plots)
      if (res['Success'] == 'Ok') {
        this._toastrService.success('Successfully Inserted', "",
          {
            positionClass: "toast-top-center",
            progressBar: true,
          });
      } else {
        this._toastrService.error('Server Side Error', "",
          {
            positionClass: "toast-top-center",
            progressBar: true,
          });
      }
    })
  }
  update_plotInfo() {
    console.log(this.updateObj)
    this.spinner.show()
    this.http.post(this._apiHandler.baseURL2 + 'updatePlotDetails', {
      'sec_id': this.obj2['sec_id'],
      'plot_cd': this.updateObj['lc_cd'],
      'isActive': this.updateObj['isActive'],
      'lc_geo_area': this.updateObj['lc_geo_area'],
      'lc_desc': this.updateObj['lc_desc'],
      'ip_mobile':this.login_mob
    }).subscribe(res => {
      console.log(res, 'resp')
      this.spinner.hide()
      this.all_Plots = res['data']
      this.spinner.hide()
      console.log(this.all_Plots)
      if (res['Success'] == 'Ok') {
        if (res['data'] == 'Plot Description already exists') {
          this._toastrService.success('This Plot Name Is Already Exists', "",
            {
              positionClass: "toast-top-center",
              progressBar: true,
            });
        } else {
          this._toastrService.success('Successfully Updated', "",
            {
              positionClass: "toast-top-center",
              progressBar: true,
            });
          this.showPlots()
        }
      } else {
        this._toastrService.error('Server Side Error', "",
          {
            positionClass: "toast-top-center",
            progressBar: true,
          });
      }
    })
  }
  async delete_swal(element) {
    Swal.fire({
      title: 'Are You Sure',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',

    }).then((result) => {
      if (result.value) {
        this.spinner.show()
        this.http.post(this._apiHandler.baseURL2 + 'deletePlot', {
          'lc_cd': element['lc_cd'],
          'par_lc_cd': element['par_lc_cd'],
        }).subscribe(res => {
          console.log(res, 'resp')
          this.showPlots()
          if (res['Success'] == 'Ok') {
            this._toastrService.success('' + res['data'], "",
              {
                positionClass: "toast-top-center",
                progressBar: true,
              });
          } else {
            this._toastrService.error('Some Error Occured At Server Side', "",
              {
                positionClass: "toast-top-center",
                progressBar: true,
              });
          }
          this.spinner.hide()
        })
      }
    })
  }
}
// 'Plot Description already exists'
// getAllPlot_By_a_sector
// insert_locationOfPlot
// updateOrDeleteLocations