<br>
<div class="page-content fade-in-up" style="background-color: white; width: 90%; margin-left: 5%; margin-bottom: 5%;">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">


                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line" style="margin-top: 2%;">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="fa fa-list"
                                    aria-hidden="true"></i>
                                {{localizationData['sector_org_list']}}</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab" ><i
                                    class="fa fa-cogs" aria-hidden="true"></i> {{localizationData['update_price']}}</a>
                        </li> -->

                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab" ><i
                                    class="fa fa-plus-circle" aria-hidden="true"></i>
                                {{localizationData['add_plot_loc']}}</a>
                        </li> -->
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">
                            <br>
                            <div class="col-12">
                                <div>
                                    <mat-form-field fxFlex="40%">
                                        <input matInput type="text" (keyup)="filter($event.target.value)"
                                            placeholder="Filter">
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-right">
                                        <button data-target="#exampleModalPreview" data-toggle="modal"
                                            class="btn btn-primary text-right" (click)="setDefaultData()"> {{localizationData['add_sector_rate']}}</button>
                                    </div>
                                </div>
                                <br>
                                <table mat-table [dataSource]="verifydataSource" matSort class="mat-elevation-z8">

                                    <!--- Note that these columns can be defined in any order.
                                          The actual rendered columns are set as a property on the row definition" -->

                                    <!-- Position Column -->
                                    <ng-container matColumnDef="sno">
                                        <th mat-header-cell *matHeaderCellDef> {{localizationData['s_no']}} </th>
                                        <td mat-cell *matCellDef="let element"> {{element.sno}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="sec_name">
                                        <th mat-header-cell *matHeaderCellDef> {{localizationData['sec_name']}} </th>
                                        <td mat-cell *matCellDef="let element"> {{element.sec_name}} </td>
                                    </ng-container>

                                    <!-- Name Column -->
                                    <!-- <ng-container matColumnDef="plot_cd">
                                        <th mat-header-cell *matHeaderCellDef>Plot Code </th>
                                        <td mat-cell *matCellDef="let element"> {{element.lc_cd}} </td>
                                    </ng-container> -->

                                    <!-- Weight Column -->
                                    <ng-container matColumnDef="org_cd">
                                        <th mat-header-cell *matHeaderCellDef> {{localizationData['organization_type']}}
                                        </th>
                                        <td mat-cell *matCellDef="let element"> {{element.ref_val}} </td>
                                    </ng-container>

                                    <!-- Symbol Column -->
                                    <ng-container matColumnDef="rate">
                                        <th mat-header-cell *matHeaderCellDef> {{localizationData['rate']}} </th>
                                        <td mat-cell *matCellDef="let element"> {{element.rate}} </td>
                                    </ng-container>

                                    <!-- sqft -->
                                    <ng-container matColumnDef="sqft">
                                        <th mat-header-cell *matHeaderCellDef> {{localizationData['per_sqft']}}</th>
                                        <td mat-cell *matCellDef="let element">
                                            {{element['sqft']}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef> {{localizationData['action']}}</th>
                                        <td mat-cell *matCellDef="let element">
                                            <!-- <button class="btn btn-primary" style="font-size: 16px;"
                                                data-target="#exampleModalPreview" data-toggle="modal"
                                                (click)="update_modal(element)">{{localizationData['edit_details']}}</button>&nbsp;
                                            <button class="btn btn-danger" style="font-size: 16px;"
                                                (click)="delete_swal(element)">{{localizationData['delete']}}</button> -->
                                            <button class="btn btn-primary" data-target="#exampleModalPreviewUpdate"
                                                data-toggle="modal"
                                                (click)="setUpdateData(element)">{{localizationData['update']}}</button>
                                            <button class="btn btn-danger" (click)="delete_swal(element)">{{localizationData['delete']}}</button>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                </table>
                                <div>
                                    <mat-paginator [pageSizeOptions]="[5, 10, 20, 50]"></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
<div class="modal fade" id="exampleModalPreview" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLabel">
                    <h1>{{localizationData['add_sector_rate']}}</h1>
                </h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-3">
                        <h3> {{localizationData['select_sector']}} :</h3>
                    </div>
                    <div class="col-3">
                        <ng-select [items]="allSector" bindLabel="sec_name" bindValue="sec_id" [multiple]="false"
                            placeholder="Select sector" [(ngModel)]="obj['sec_id']" [selectableGroup]="true"
                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                        </ng-select>


                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-3">
                        <h3>{{localizationData['organization_type']}} :</h3>
                    </div>
                    <div class="col-3">
                        <ng-select [items]="orgType" bindLabel="seg_desc" bindValue="seg_cd" [multiple]="false"
                            placeholder="Select organization type" [(ngModel)]="obj['org_type']"
                            [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="field_name">
                        </ng-select>

                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-3">
                        <h3>{{localizationData['rate']}} :</h3>
                    </div>
                    <div class="col-3">
                        <input type="number" placeholder="enter rate" class="form-control" name="" id=""
                            [(ngModel)]="obj['rate']">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-3">
                        <h3>{{localizationData['per_sqft']}} :</h3>
                    </div>
                    <div class="col-3">
                        <input type="number" class="form-control" placeholder="enter sqft rate" name="" id=""
                            [(ngModel)]="obj['per_sqft']">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-12 text-center">
                        <button (click)="submit()" class="btn btn-primary">{{localizationData['submit']}}</button>
                    </div>
                </div>
                <br>
                <div class="modal-footer">
                    <!-- <button type="button" class="btn btn-primary" style="font-size: 16px;"
                        (click)="update_plotInfo()">{{localizationData['update_plot']}}</button> -->
                    <button type="button" class="btn btn-danger" style="font-size: 16px;" data-dismiss="modal">
                        {{localizationData['close']}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalPreviewUpdate" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLabel">
                    <h1>{{localizationData['update_sector_rate']}}</h1>
                </h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-3">
                        <h3>{{localizationData['select_sector']}} :</h3>
                    </div>
                    <div class="col-3">
                        <!-- <ng-select [items]="allSector" bindLabel="sec_name" bindValue="sec_id" [multiple]="false"
                            placeholder="Select sector" [(ngModel)]="obj['sec_id']" [selectableGroup]="true"
                            [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="field_name">
                        </ng-select> -->
                        {{obj['sec_name']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-3">
                        <h3>{{localizationData['organization_type']}} :</h3>
                    </div>
                    <div class="col-3">
                        <!-- <ng-select [items]="orgType" bindLabel="seg_desc" bindValue="seg_cd" [multiple]="false"
                            placeholder="Select organization type" [(ngModel)]="obj['org_type']" [selectableGroup]="true"
                            [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="field_name">
                        </ng-select> -->
                        {{obj['ref_val']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-3">
                        <h3>{{localizationData['rate']}} :</h3>
                    </div>
                    <div class="col-3">
                        <input type="number" placeholder="enter rate" class="form-control" name="" id=""
                            [(ngModel)]="obj['rate']">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-3">
                        <h3>{{localizationData['per_sqft']}} :</h3>
                    </div>
                    <div class="col-3">
                        <input type="number" placeholder="enter sqft rate" class="form-control" name="" id=""
                            [(ngModel)]="obj['per_sqft']">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-12 text-center">
                        <button (click)="update()" class="btn btn-primary">{{localizationData['update']}}</button>
                    </div>
                </div>
                <br>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" style="font-size: 16px;" data-dismiss="modal">
                        {{localizationData['close']}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner>Loading...</ngx-spinner>
