import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';

import {AngularMaterialModule} from './../../angular-material/angular-material.module';
import { MainHeaderComponent } from './main-header/main-header.component';
import { GenNavbarComponent } from './gen-navbar/gen-navbar.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';



@NgModule({
  declarations: [NavbarComponent,MainHeaderComponent, GenNavbarComponent],
  imports: [
    CommonModule,
    AngularMaterialModule,
  ],
  exports: [NavbarComponent,MainHeaderComponent,GenNavbarComponent]
})
export class NavbarModule { }
