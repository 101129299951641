import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router'

@Component({
  selector: 'app-gen-navbar',
  templateUrl: './gen-navbar.component.html',
  styleUrls: ['./gen-navbar.component.css']
})
export class GenNavbarComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit(): void {
  }
  genNavi(path: string) {
    this.router.navigate([path]);
  }

}
