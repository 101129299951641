import {
  Component,
  OnInit,
  QueryList,
  ViewChildren,
  ViewChild,
  ElementRef,
  Inject,
} from "@angular/core";
import { ApiHandlerService } from 'src/app/shares/services/api-handler.service';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { HttpClient } from "@angular/common/http";
import { MatTableDataSource } from "@angular/material/table";
import { NgxSpinnerService } from 'ngx-spinner'
import "jspdf-autotable";
import { RegistrationFormService } from "../registration-form/registration-form.service";
import { ToastrService } from "ngx-toastr";
import Swal from 'sweetalert2'
import { AuthService } from '../auth/auth.service'
import { LocalizationService } from "src/app/shares/store/localization.service";
import { ILocalization } from "src/app/shares/models";

declare var $: any
@Component({
  selector: 'app-approve-refund',
  templateUrl: './approve-refund.component.html',
  styleUrls: ['./approve-refund.component.css']
})
export class ApproveRefundComponent implements OnInit {


  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  displayedColumns: string[] = ['sno', 'name', 'ip_mobile', 'catagory', 'amt','status', 'action'];
  dataSource = [];
  localizationData: ILocalization;
  constructor(
    private _apiHandler: ApiHandlerService,
    private http: HttpClient,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private _registrationLandService: RegistrationFormService,
    private _toastrService: ToastrService,
    private _localization: LocalizationService,

  ) { }
  obj = {}
  today;
  comdataSource;
  cars = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];
  login_mob;

  async ngOnInit() {
    this._localization.geti18nData().subscribe((res: ILocalization) => {
      this.localizationData = res;
    });
    this.login_mob = this.authService.login_mob
    let date = new Date()
    this.today = date.getDate() + '/' + date.getMonth() + 1 + '/' + date.getFullYear()
    // await this.get_Cancle_Land_Data()
    // await this.get_Allot_Data()
    await this.allRefundRecord()
  }
  downloadPdf(){
    
  }
  async allRefundRecord() {
     this.spinner.show()
    var obj = new Object();
    obj["ip_mobile"] = this.login_mob;
    var resp = await this.http.post(this._apiHandler.baseURL1 + "allRefundRecords", obj).toPromise();
    console.log(resp)
    if(resp['Success']=='Ok'){
      this.spinner.hide()
      this.verifydataSource = new MatTableDataSource(resp['data']);
      this.verifydataSource.paginator = this.paginator.toArray()[0];
      this.verifydataSource.sort = this.sort.toArray()[0];
    }else{
      this.spinner.hide()
      this._toastrService.error('Error While getting Cancled land data', "",
      {
        positionClass: "toast-top-center",
        progressBar : true,
      });
    }
 
  }
  allotedData = []
  async get_Allot_Data() {
    this.spinner.show()
    var obj = new Object();
    obj["ip_mobile"] = this.selectedObj['ip_mobile'];
    var resp = await this.http.post(this._apiHandler.baseURL1 + "user_all_AllotedLand", obj).toPromise();
    console.log(resp)
    this.allotedData = resp['data']
    if(resp['Success']=='Ok'){
      this.spinner.hide()
    }else{
      this.spinner.hide()
      this._toastrService.error('Error While getting alloted land data', "",
      {
        positionClass: "toast-top-center",
        progressBar : true,
      });
    }
  }
  verifydataSource
  filter = (value: string) => {
    //("inside filter method");
    this.verifydataSource.filter = value.trim().toLocaleLowerCase();
  };
  cancled_data = []
  async get_Cancle_Land_Data() {
    this.spinner.show()
    this.obj['cancle_amt'] = 0
    var obj = new Object();
    obj["ip_mobile"] = this.selectedObj['ip_mobile'];
    var resp = await this.http.post(this._apiHandler.baseURL1 + "allCancled_land_Data", obj).toPromise();
    console.log(resp['data'], 'cancled data');
    this.cancled_data = resp['data']
if(resp['Success']=='Ok'){
  this.spinner.hide()
}else{
  this.spinner.hide()
  this._toastrService.error('Error While getting Cancled land data', "",
  {
    positionClass: "toast-top-center",
    progressBar : true,
  });
}
    // for (let i = 0; i < resp['data'].length; i++) {
    //   this.obj['cancle_amt'] = this.obj['cancle_amt'] + Number(resp['data'][i]['pmnt_amount'])
    // }
    // console.log(this.obj)
  }
  selectedObj = {}
  async setData(data) {
    this.selectedObj = data
    await this.get_Cancle_Land_Data()
    await this.get_Allot_Data()
  }

  async approve_swal() {
    Swal.fire({
      title: 'Do You Really Want To Approve ??',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes..! Approve it',
    }).then((result) => {
      if (result.value) {
       this.approve()
      }
    })
  }
 async approve(){
   this.spinner.show()
    let obj={}
    obj["id"] = this.selectedObj['sno'];
    var resp = await this.http.post(this._apiHandler.baseURL1 + "approveFefundableAmount", obj).toPromise();
    if(resp['Success']=='Ok'){
      await this.allRefundRecord()
      this.spinner.hide()
      this._toastrService.success('Successfully Submitted', "",
          {
            positionClass: "toast-top-center",
            progressBar : true,
          });
    }else{
      this.spinner.hide()
      this._toastrService.error('Error While Submitting data..!' , "",
          {
            positionClass: "toast-top-center",
            progressBar : true,
          });
    }
  }
}
