import {
  Component,
  OnInit,
  QueryList,
  ViewChildren,
  ViewChild,
  ElementRef,
  Inject,
} from "@angular/core";
import { ApiHandlerService } from 'src/app/shares/services/api-handler.service';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { HttpClient } from "@angular/common/http";
import { MatTableDataSource } from "@angular/material/table";
import { NgxSpinnerService } from 'ngx-spinner'
import "jspdf-autotable";
import { RegistrationFormService } from "../registration-form/registration-form.service";
import { ToastrService } from "ngx-toastr";
import Swal from 'sweetalert2'
import { ILocalization } from "src/app/shares/models";
import { AuthService } from "../auth/auth.service";
import { LocalizationService } from "src/app/shares/store/localization.service";

@Component({
  selector: 'app-set-org-rate',
  templateUrl: './set-org-rate.component.html',
  styleUrls: ['./set-org-rate.component.css']
})
export class SetOrgRateComponent implements OnInit {
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  displayedColumns: string[] = ['sno', 'sec_name', 'org_cd', 'rate', 'sqft', 'action'];
  dataSource;
  localizationData: ILocalization;

  constructor(private spinner: NgxSpinnerService,
    private _registrationLandService: RegistrationFormService,
    private _apiHandler: ApiHandlerService,
    private http: HttpClient,
    private _toastrService: ToastrService,
    private _localization: LocalizationService,
    private authService: AuthService
  ) { }

  async ngOnInit() {
    this._localization.geti18nData().subscribe((res: ILocalization) => {
      this.localizationData = res;
    });
    this.getSec()
    this.allSectorRate()
    this.getOrganizationType()
  }
  obj = {}
  enableSector;
  allSector;
  async getSec() {
    this.spinner.show()
    this.enableSector = []
    this._registrationLandService.getSector().subscribe((res) => {
      let data: any = res
      console.log(res)
      this.enableSector = res
      this.allSector = this.enableSector
      console.log(this.allSector)
      this.spinner.hide()
    });
  }
  setDefaultData(){
    this.obj['rate']=100
    this.obj['per_sqft']=100
  }
  orgType;
  getOrganizationType() {
    this._registrationLandService.getOrgType().subscribe((res) => {
      this.orgType = res.data;
      console.log(this.orgType)
    });
  }
  verifydataSource;
  allSectorRate() {
    this.spinner.show()
    this.http.post(this._apiHandler.baseURL + 'allSectorRate', {
      'sec_id': '',
      //  'data':JSON.stringify(this.data2)
    }).subscribe(res => {
      console.log(res, 'resp')
      this.spinner.hide()
      for (let i = 0; i < res['data'].length; i++) {
        res['data'][i]['sno'] = i + 1
      }
      this.verifydataSource = new MatTableDataSource(res['data']);
      this.verifydataSource.paginator = this.paginator.toArray()[0];
      this.verifydataSource.sort = this.sort.toArray()[0];

    })

  }
  filter = (value: string) => {
    //("inside filter method");
    this.verifydataSource.filter = value.trim().toLocaleLowerCase();
  };
  submit() {
    this.spinner.show()
    if (this.obj['sec_id'] && this.obj['org_type'] && this.obj['rate'] && this.obj['per_sqft']) {
      this.http.post(this._apiHandler.baseURL + 'insertSectorRate', {
        'sec_id': this.obj['sec_id'],
        'org_type': this.obj['org_type'],
        'rate': this.obj['rate'],
        'sqft': this.obj['per_sqft'],
        //  'data':JSON.stringify(this.data2)
      }).subscribe(res => {
        console.log(res)
        if (res['Success'] == 'Ok' && res['data'] != "Already Exists") {
          this.allSectorRate()
          this.spinner.hide()
          this._toastrService.success('Added Successfully', "",
            {
              positionClass: "toast-top-center",
              progressBar: true,
            });
        } else if (res['Success'] != 'Ok') {
          this.spinner.hide()
          this._toastrService.error('Error Occured While Adding..!', "",
            {
              positionClass: "toast-top-center",
              progressBar: true,
            });
        } else {
          this.spinner.hide()
          this._toastrService.warning('This sector and organization rate is allready exists..!', "",
            {
              positionClass: "toast-top-center",
              progressBar: true,
            });
        }

      })
    }
    else {
      this.spinner.hide()
      Swal.fire('Error', 'Please Select All Fields', 'error')
    }
  }
  setUpdateData(data) {
    console.log(data)
    this.obj = {}
    this.obj = data
    this.obj['per_sqft'] = data['sqft']
  }
  update() {
    this.spinner.show()
    if (this.obj['rate'] && this.obj['per_sqft']) {
      this.http.post(this._apiHandler.baseURL + 'updateSectorRate', {
        'sec_id': this.obj['sec_id'],
        'org_type': this.obj['org_type'],
        'rate': this.obj['rate'],
        'sqft': this.obj['per_sqft'],
        'r_id': this.obj['r_id'],
        //  'data':JSON.stringify(this.data2)
      }).subscribe(res => {
        console.log(res)
        if (res['Success'] == 'Ok') {
          this.allSectorRate()
          this.spinner.hide()
          this._toastrService.success('Updated Successfully', "",
            {
              positionClass: "toast-top-center",
              progressBar: true,
            });
        } else {
          this.spinner.hide()
          this._toastrService.warning('Error Occured While Updating', "",
            {
              positionClass: "toast-top-center",
              progressBar: true,
            });
        }

      })
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Please Enter all fileds', 'error')
    }
  }
  async delete_swal(element) {
    Swal.fire({
      title: 'Are You Sure',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',

    }).then((result) => {
      if (result.value) {
        this.spinner.show()
        this.http.post(this._apiHandler.baseURL + 'deleteSectorRate', {
          'r_id': element['r_id'],
        }).subscribe(res => {
          console.log(res, 'resp')
          if (res['Success'] == 'Ok') {
            this.allSectorRate()
            this.spinner.hide()
            this._toastrService.success('Deleted Succesfully', "",
              {
                positionClass: "toast-top-center",
                progressBar: true,
              });
          } else {
            this.spinner.hide()
            this._toastrService.error('Some Error Occured At Server Side While Deleting..!', "",
              {
                positionClass: "toast-top-center",
                progressBar: true,
              });
          }
        })
      }
    })
  }

}
