import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApiHandlerService {
//production
 //public ip="bhumiavantan.haridwarkumbhmela2021.com"
//testing


//local
// public ip="localhost"
//  public baseURL = "http://localhost:3010/login/";
//  public baseURL1 = "http://localhost:3010/admin/";
//  public baseURL2 = "http://localhost:3010/";

 public ip="kumbh.upda.co.in"
  public baseURL = "https://"+this.ip+":3010/login/";
  public baseURL1 = "https://"+this.ip+":3010/admin/";
  public baseURL2 = "https://"+this.ip+":3010/";
 
  constructor(private http: HttpClient) { }
  post<T, D>(apiTarget: string, data: D): Observable<T> {
    const api: string = [this.baseURL, apiTarget].join("");
    console.log("path", api, data);
    return this.http.post<T>(api, data);
  }
  get<T>(apiTarget: string): Observable<T> {
    const api: string = [this.baseURL, apiTarget].join("");
    console.log("path", api);
    return this.http.get<T>(api);
  }

  getAdmin<T>(apiTarget: string): Observable<T> {
    const api: string = [this.baseURL1, apiTarget].join("");
    console.log("path", api);
    return this.http.get<T>(api);
  }

  getData<T, D>(apiTarget: string, data: D): Observable<T> {
    const api: string = [this.baseURL1, apiTarget].join("");
    console.log("path", api, data);
    return this.http.get<T>(api, data);
  }
  postAdmin<T, D>(apiTarget: string, data: D): Observable<T> {
    const api: string = [this.baseURL1, apiTarget].join("");
    console.log("path", api, data);
    return this.http.post<T>(api, data);
  }
  postServiceData<T, D>(apiTarget: string, data: D): Observable<T> {
    const api: string = [this.baseURL2, apiTarget].join("");
    console.log("path", api, data);
    return this.http.post<T>(api, data);
  }
  getServiceData<T>(apiTarget: string): Observable<T> {
    const api: string = [this.baseURL2, apiTarget].join("");
    console.log("path", api);
    return this.http.get<T>(api);
  }
}
