import { Injectable } from "@angular/core";
import { ApiHandlerService } from "src/app/shares/services/api-handler.service";
import { Observable, from } from "rxjs";
import { IRegistrationForm } from "../auth/models/registration-form.model";
import { ISectorObject } from "../auth/models/sector.model";
//import {HttpClient,HttpErrorResponse,HttpEvent,HttpEventType} from "@angular/common/http"
import { MobileNumberService } from "src/app/shares/store/mobile-number.service";
import { map } from "rxjs/operators";
@Injectable({
  providedIn: "root"
})
export class RegistrationFormService {
  mobileNo;
  constructor(
    private _apiHandler: ApiHandlerService,
    private _mobileNumber: MobileNumberService
  ) {}

  landRegistration(landRegistrationData: IRegistrationForm): Observable<any> {
    const mobileNumber = this._mobileNumber.getMobileNumber();
    const data = {
      role: "R",
     
      is_past_id: landRegistrationData.is_past_id,
      is_service_app : landRegistrationData.is_service_app,
      rep_id: mobileNumber,
      ip_mobile: landRegistrationData.applicantPhone,
      ip_email: landRegistrationData.applicantEmail,
      past_app_id: landRegistrationData.applicantId,
      ip_name: landRegistrationData.applicantName,
      ip_address: landRegistrationData.applicantAddress,
      ref_val: landRegistrationData.organizationType,
      req_sector: landRegistrationData.sector = null,
      req_area: landRegistrationData.area = null,
      purpose:landRegistrationData.applicantPurpose=null,
      comment:landRegistrationData.applicantComment=null,
      is_applied_service: landRegistrationData.is_applied_service = null
    };
    this.mobileNo = data.ip_mobile;

    const api = "insertRegistration";
    return this._apiHandler.post<any, any>(api, data);
  }

  insertLandDetail(landRegistrationData: IRegistrationForm) {
    //const mobileNumber = this._mobileNumber.getMobileNumber();
    console.log(landRegistrationData);
    
    const data = {
      ip_mobile: this.mobileNo,
      req_sector: landRegistrationData.reqSector,
      req_area: landRegistrationData.requestArea,
      is_applied_service: landRegistrationData.is_applied_service,
      purpose:landRegistrationData.applicantPurpose,
      comment:landRegistrationData.applicantComment
    };
    console.log(data)
    const api = "insertRegistration";
    return this._apiHandler.post<any, any>(api, data);
  }
  getPastData(): Observable<any> {
    const api = "past_app";
    return this._apiHandler.get<any>(api);
  }
  getPastData2(): Observable<any> {
    const api = "get_past_details";
    return this._apiHandler.get<any>(api);
  }
  getOrgType(): Observable<any> {
    const api = "party_segment_code";
    return this._apiHandler.get<any>(api);
  }
  getSector(): Observable<ISectorObject> {
    const api = "sector";
    return this._apiHandler.get<ISectorObject>(api);
  }
  getSector2(): Observable<ISectorObject> {
    const api = "sector_at_MLA";
    return this._apiHandler.get<ISectorObject>(api);
  }
  getArea(): Observable<ISectorObject> {
    const api = "lc_area";
    return this._apiHandler.get<ISectorObject>(api);
  }
  getRegistermsg(): Observable<any> {
    const api = "application_desc";
    return this._apiHandler.get<any>(api);
  }

  postFile(fileToUpload: File): Observable<boolean> {
    const api = "upload";
    const formData: FormData = new FormData();
    formData.append("fileKey", fileToUpload, fileToUpload.name);
    console.log("file type", fileToUpload.type);
    return this._apiHandler.postAdmin<any, any>(api, formData);
  }
  getAppData(mobile: any): Observable<any> {
    const data = { ip_mobile: mobile };
    console.log("applicant", mobile);
    const api = "single_app_data";
    return this._apiHandler.postAdmin<any, any>(api, data);
  }
  getAppAmount():Observable<any>{
    const data ={pd_id:4}
    const api ="pd_info"
    return this._apiHandler.postAdmin<any,any>(api,data)

  }
  payLandFee(mobile):Observable<any>{
    const api = "land_payment"
    return this._apiHandler.postAdmin<any,any>(api,mobile)

  }
  generatePdf(mobile):Observable<any>{
    const api = "update_status_7_for_altmnt_ltr"
    return this._apiHandler.postAdmin<any,any>(api,mobile)
  }
  uploadLetter(data):Observable<any>{
    const api = "upload_allotment_ltr"
    return this._apiHandler.postAdmin<any,any>(api,data)

  }
  uploadService_letter(data):Observable<any>{
    const api = "upload_service_letter"
    return this._apiHandler.postAdmin<any,any>(api,data)

  }
  uploadApplication(data):Observable<any>{
    const api = "applicantion_form_upload"
    return this._apiHandler.postAdmin<any,any>(api,data)

  }
}
