<mat-toolbar class="main">
    <!-- <div class="image"> -->
    <img style="margin-top: 2%; margin-bottom: 2%;" width="5%" height=" 90%" src="../../../../assets/1200px-Seal_of_Uttarakhand.svg.png" />
    <!-- </div> -->
    <span class="head" style="color: white;">{{localizationData.kumbh_mela_2021}}</span>
    
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="lang">
        <label  style="color: white;font-size: medium;">Support :{{email}} </label>&nbsp;
        <mat-button-toggle  value="bold" (click)="selectLanguage('EN')">English</mat-button-toggle>
        <mat-button-toggle  value="italic" (click)="selectLanguage('HI')"> हिंदी</mat-button-toggle>
    </mat-button-toggle-group>

</mat-toolbar>