import { ILocalization } from "./localization.model";

let i18nData: ILocalization = {
  name: "नाम",
  address: "पता",
  mobile_number: "मोबाइल नंबर",
  verify_number: "नंबर सत्यापित करें",
  mobile: "मोबाइल",
  email: "ईमेल",
  kumbh_mela_2021: "भूमि आवंटन और सुविधाएं - कुंभ मेला 2021",
  kumbh_mela: "कुम्भ मेला,हरिद्वार",
  notice_board: "सूचना पट्ट",
  profile: "प्रोफ़ाइल",
  dashboard: "डैशबोर्ड",
  application: "आवेदन",
  all_application: "कुल आवेदन",
  total_application: "कुल आवेदन",
  welcome: "अभिवादन",
  ji: "जी",
  land_allotment: "भूमि आवंटन",
  interactive_map: "संवादात्मक मानचित्र",
  completed_application: "पूरा किया गया आवेदन",
  complete_application: "आवेदन को पूरा करें",
  partial_application: "आंशिक आवेदन",
  applicant_detail: "आवेदक विवरण",
  land_details: "भूमि विवरण",
  payment: "भुगतान",
  done: "संपन्न",
  representative: "Representative",
  applicant: "आवेदक",
  select_past_allotment_detail: "पिछले आबंटन विवरण का चयन करें",
  enter_name: "नाम दर्ज करें",
  applicant_contact: "आवेदक संपर्क विवरण",
  applicant_email: "आवेदक ईमेल",
  select_organization_type: "संगठन प्रकार का चयन करें",
  next: "आगे बढ़ें",
  pay_app_fee: "आवेदन शुल्क का भुगतान करें",
  app_progress: "आपका आवेदन प्रगति में है!!!",
  wait_for_further_notice: "आगे की सूचना के लिए प्रतीक्षा करें",
  pay_allot_fee: "आबंटन शुल्क का भुगतान करें",
  down_allot_letter: "आवंटन पत्र डाउनलोड करें",
  Enter_otp: "ओ.टी.पी. दर्ज करें",
  submit: "प्रस्तुत",
  under_process_app: "प्रक्रियाधीन आवेदन",
  alloted_app: "आबंटित आवेदन",
  request_sec: "अनुरोधित सेक्टर",
  request_area: "अनुरोधित क्षेत्र",
  close: "बंद करे",
  save_changes: "सुरक्षित करें",
  item_per_page: "Items per page",
  personal_details: "व्यक्तिगत विवरण",
  allotment: "आवंटन",
  previous_year_record: "पिछले वर्ष का रिकॉर्ड",
  paid_fee: "भुगतान किया गया शुल्क",
  is_exemp: "छूट प्राप्त",
  mela_allot_admin: "Mela Allotment Admin",
  mela_adikari: "मेला अधिकारी",
  alloted_sec: "आवंटित सेक्टर",
  alloted_area: "आवंटित क्षेत्र",
  generate_letter: "पत्र बनाएँ",
  generate: "बनाएँ",
  login: "लॉग इन करें",
  role: "भूमिका",
  signout: "लॉग आउट",
  rate:"मूल्यांकन",
  apply_for_allotment: "आवंटन के लिए आवेदन करें",
  complete_land_details: "भूमि विवरण पूरा करें",
  select_sector: "सेक्टर चुनें",
  Select_Required_Area: "आवश्यक क्षेत्र डाले",
  back: "वापस",
  select_sector_from_map: "मानचित्र से सेक्टर का चयन करें",
  select_plot_from_map: "मानचित्र से प्लॉट का चयन करें",
  for_exem_applicant: "कृपया छूट प्राप्त आवेदक के लिए क्लिक करें",
  upload_support_doc: "सहायक दस्तावेज़ अपलोड करें",
  app_fee: "आवेदन शुल्क",
  deposit: "जमा",
  pay: "भुगतान करें",
  go_to_dashboard: "डैशबोर्ड पर जाएं",
  choose_file: "फ़ाइल चुनें",
  upload: "अपलोड करें",
  action: "कार्य",
  or: "अथवा",
  allot: "आबंटन",
  service_desc_hi:"सेवा विवरण हिंदी में",
  services: "सेवाएं",
  finance: "वित्त",
  check_txn_status:"शुल्क की स्थिति जांचें",
  allService:"सभी स्तर की सेवा",
  file_status:"अपलोड की गई फ़ाइल स्थिति की जाँच करें",
  export:"निर्यात सूची",
  represent_msg:
    "भूमि आवंटन और मेला प्रशासन की अन्य सेवाओं के प्रबंधन में आपका स्वागत है |",
  confirm_allotment: "आबंटन की पुष्टि करें",
  Allotmnet_Fees_Payment: "आबंटन शुल्क भुगतान",
  Fees_Payable: "देय शुल्क",
  Payment_Type: "भुगतान का प्रकार",
  your_file_status:"आपकी फ़ाइल स्थिति है",
  Alloted_Plot: "आबंटित प्लॉट",
  Plot_Size: "प्लॉट का आकार",
  view_doc:"दस्तावेज़ देखें",
  pageNotFound: "पृष्ठ नहीं मिला",
  A: "आवेदक",
  R: "कुम्भ सहभागी",
  MAA: "मेला आवंटन व्यवस्थापक",
  MA: "मेला प्रमुख",
  MSA: "मेला सेवा व्यवस्थापक",
  MTA: "मेला स्थल व्यवस्थापक",
  MLA:'मेला भूमि व्यवस्थापक',
  M: "मास्टर व्यवस्थापक",
  MFA: "मेला वित्त व्यवस्थापक",
  MAP: "मेला आवेदन व्यवस्थापक",
  completed_allotment: "पूर्ण आवंटन",
  not_allotted: "आवंटन नहीं हुआ है",
  count: "संख्या",
  fees: "शुल्क",
  total: "कुल",
  update_plot:"अपडेट भूखंड",
  add_land:"जमीन जोड़ें",
  plot_name:"प्लॉट का नाम",
  edit_details:"संशोधन",
  plot_number:"प्लॉट नंबर",
  current_status:"वर्तमान स्थिति",
  enter_area_error:"उपलब्ध क्षेत्र आपके द्वारा प्रदान किए जाने वाले क्षेत्र से कम है",
  enter_area:"क्षेत्र भरें",
  land_status:"भूमि की स्थिति",
  selected:"चयनित",
  provided_area:"प्रदान किया गया क्षेत्र",
  selected_plot_area:"चयनित भूखंड क्षेत्र",
  plot_aval_area:"प्लॉट उपलब्ध क्षेत्र",
  long:"देशान्तर",
  enable:"सक्षम",
  disble:"अक्षम बनाना",
  change_sector_status:"सेक्टर की स्थिति बदलें",
  change_plot_status:"भूखंड की स्थिति बदलें",
 
   lang:"अक्षांश",
  plot_desc:"प्लॉट विवरण",
  amount: "रकम",
  select_plot:"प्लॉट का चयन करें",
  add_plot_loc:"भूखंड स्थान जोड़ें",
  do_you_realy_go_back:"क्या आप सच में वापस जाना चाहते हो",
  // ----
  mela_txn_no:"मेला लेनदेन आईडी",
  description:"विवरण",
  pmnt_status:"भुगतान की स्थिति",
  ici_txn_no:"आईसीआईसीआई ट्रांजेक्शन नंबर",
  language:"hindi",
  // ---
  service_name:"सेवा का नाम",
  add_service:"सेवा जोड़ें",
  denomination: "संज्ञा",
  identification: "पहचान",
  payment_detail:"भुगतान विवरण",
  contact_details: "संपर्क विवरण",
  representative_signin_msg:
    "अब आप एक कुम्भ सहभागी के रूप में लॉग इन कर सकते हैं",
  go_for_login: "लॉगिन के लिए जाएं",
  fee_ack:"शुल्क पावती",
  registration_Form_msg1: "",
  registration_Form_msg2: "",
  profile_form_msg: "",
  application_payment_is_completed: "आवेदन का भुगतान पूरा हो गया है ",
  waiting_for_payment: "भुगतान की प्रतीक्षा",
  allotment_letter_generated: "आवंटन पत्र उत्पन्न",
  exempted: "छूट प्राप्त",
  total_fee: "कुल शुल्क",
  alloted: "आवंटित",
  requested_land_details: "प्रार्थित भूमि का विवरण",
  no_past_allot_record: "इस वर्ष के लिए कोई आबंटन रिकॉर्ड नहीं है |",
  no_prev_record: "कोई पिछला आबंटन रिकॉर्ड नहीं है |",
  go_to_notice: "नोटिस बोर्ड पर जाएं",
  cancel: "वापस जाएं",
  please: "कृपया",
  field_is_required: "यह फ़ील्ड आवश्यक है",
  enter_valid_number: "कृपया एक मान्य मोबाइल नंबर दर्ज करें",
  submit_correct_info: "कृपया सही जानकारी दर्ज करें",
  invalid_email: "अवैध ईमेल",
  logged_in: "लॉग इन",
  registration_invalid: "पंजीकरण अमान्य है",
  mr: "श्री",
  mrs: "श्रीमती",
  requested: "प्रार्थित",
  req_for_reallot: "पुनः आवंटन के लिए अनुरोध करें",
  req_reallot: "पुनः आवंटन के लिए अनुरोध किया गया ",
  app: "",
  reason: "पुन: आवंटन का कारण",
  cancelled: "रद्द",
  re_allot: "पुनः आवंटित करें",
  notify_msg1: "आवेदक विवरण पूरा हो गया है कृपया अपनी भूमि का विवरण पूरा करें।",
  notify_msg2:
    "आवेदन का विवरण पूरा हो गया है कृपया आवेदन शुल्क का भुगतान करें।",
  notify_msg3:
    "आवेदन पूरा हो गया है कृपया आगे की अधिसूचना के लिए प्रतीक्षा करें।",
  notify_msg4:
    "आपकी आवेदन प्रक्रिया जारी है, कृपया आगे की अधिसूचना की प्रतीक्षा करें",
  notify_msg5:
    "आपकी भूमि का आवंटन पूरा हो गया है कृपया अपनी भूमि का विवरण देखें और आवंटन शुल्क का भुगतान करें।",
  notify_msg6:
    "आपका आवंटन शुल्क जमा किया गया है, कृपया आवंटन पत्र की प्रतीक्षा करें",
  notify_msg7:
    "आपकी आवंटन प्रक्रिया पूरी हो गई है अब आप आवंटन पत्र डाउनलोड कर सकते हैं और सेवाओं के लिए आवेदन कर सकते हैं",
  enter_valid_emai: "कृपया वैध ईमेल दर्ज़ करें",
  download_msg: "आवेदन फार्म डाउनलोड करें",
  view: "देखें",
  clear: "चुना हुआ हटाएं",
  download_list: "डाउनलोड सूची",
  download_letter: "पत्र डाउनलोड करें",
  print: "प्रिंट",
  register_success: "पंजीकरण सफल हुआ",
  number_registered: "नंबर पंजीकृत हो गया है",
  file_upload: "फाईल अपलोड हुई",
  is_exemp_file_upload: "छूट प्राप्त आवेदक के लिए फाइल सफलतापूर्वक अपलोड की गई",
  sector_details: "सेक्टर का विवरण",
  sector_name: "सेक्टर का नाम",
  total_land_area: "कुल भूमि क्षेत्र",
  avail_land_area: "उपलब्ध भूमि क्षेत्र",
  fee_exemption: "शुल्क में छूट का अनुरोध",
  apply_service: "सेवाओं के लिए आवेदन करें",
  serv_msg:
    "आप यहां से सेवाओं के लिए आवेदन कर सकते हैं। आप केवल तभी आवेदन कर सकते हैं जब प्लॉट आवंटित किया गया हो। कृपया विवरण जांचें और आवेदन के लिए आगे बढ़ें",
  select: "चुने",
  price: "मूल्य प्रति आइटम",
  reconsiled:"पुनर्विचार किया",
  app_name:"आवेदक का नाम",
  all_pmnt_status_info:"सभी भुगतान स्थिति की जानकारी",
  size: "आकार",
  types: "प्रकार",
  isPayment_done:"भुगतान किया गया है या नहीं",
  you_dont_need_to_pay:"आपको भूमि राशि का भुगतान करने की आवश्यकता नहीं है, आप छूट के लिए फाइल अपलोड कर सकते हैं",
  info_status:"स्थिति पर जानकारी",
  s_no: "क्र. सं.",
  total_amount:"कुल राशि",
  all_fee_exempted_record:"सभी शुल्क छूट रिकॉर्ड",
  remove: "हटाएँ",
  yes_go:"हाँ।! वापस जाओ",
  no_of_app:"आवेदन की संख्या",
  serv_item_detail: "सेवा आइटम विवरण",
  service_list: "सेवा श्रेणी सूची",
  mela_serv: "मेला सेवाएँ",
  list_item: "सेवा वस्तुओं की सूची",
  filter: "फ़िल्टर",
  service_selected: "सेवा चयनित",
  select_service: "कृपया सबमिट करने से पहले सेवा का चयन करें |",
  selected_service_list: "चयनित सेवा सूची",
  unit: "इकाई",
  req_serv_app: "सेवा के लिए अनुरोध किया गया आवेदन",
  serv_approve_app: "सेवा स्वीकृत आवेदन",
  no_serv_select: "कोई सेवा चयनित नहीं है",
  req_unit: "अनुरोधित मात्रा",
  total_req_serv: "कुल अनुरोधित सेवा मद",
  total_ser_req: "कुल सेवा अनुरोध",
  organization_type: "संगठन प्रकार",
  allottedServiceList: "आवंटित सेवा सूची",
  land: "भूमि",
  serv_req_id: "सेवा अनुरोध आईडी",
  date_time: "दिनांक और समय",
  status: "स्थिति",
  date:"दिनांक",
  txn_id:"लेनदेन आईडी",
  ordered_request: "आदिष्ट",
  inProgress: "प्रक्रियाधीन",
  and: "और",
  add_plot:"भूखंड जोड़ें",
  add_serv: "और सेवाएँ जोड़ें",
  serv_detail: "सेवा विवरण",
  line_nmbr: "क्रमांक",
  product: "उत्पाद",
  serv_req: "सेवा अनुरोध",
  set_org_rate:"सेक्टर दर निर्धारित करें",
  recon:"सुलह",
  per_sqft:"प्रति SQFT",
  update_price:" अपडेट/मिटाना मूल्य",
  sector_org_list:"सेक्टर संगठन दर सूची",
  not_recon:"सुलह नहीं",
  newly_alloted_plot:"नवनियुक्त भूखंड",
  current_allotment:"वर्तमान आवंटन",
  recon_by_date:"तारीख से सुलह",
  recon_of_date:"एक तिथि द्वारा सुलह",
  update:"अपडेट करें",
  recon_status:"सुलह की स्थिति",
yes_recon:"सुलह की गई",
  // select: " चुने " ,
  // types: "सेवा प्रकार ",
  timestamp: "दिनांक और समय",
  is_alloted: "आवंटित है",
  no_of_demand: "अनुरोध की संख्या",
  alloted_quantity: "आवंटित मात्रा",
  exem: "छूट",
  cost: "शुद्ध राशि",
  total_cost: "कुल रकम",
  sel_ser_list: "चयनित सेवाओं की सूची",
  item: "मद",
  measurement: "माप",
  total_avail: "कुल खरीदी मात्रा",
  accept:"स्वीकार करना",
  reject:"अस्वीकार",
  rem_aial: "शेष मात्रा",
  add_sector_rate:"सेक्टर दर जोड़ें",
  kumbh_year:"कुंभ वर्ष",
  update_sector_rate:"सेक्टर दर अपडेट करें",
  app_past_details:"आवेदक विगत विवरण",
  demand: "अपेक्षित मांग",
  threshold: "दहलीज मात्रा",
  past_org_cd:"पिछले संगठन कोड",
  add: " और सामान जोडें",
  change_thresh: "दहलीज मात्रा बदलें",
  cancled_app:"रद्द किए गए आवेदक",
  disable: "अक्षम",
  service_desc: "  सेवा का प्रकार",
  ordered_quantity: "",
  low: "निम्न इन्वेंटरी",
  normal: "सामान्य इन्वेंटरी",
  sufficient: "पर्याप्त इन्वेंटरी",
  inventory: "इन्वेंटरी",
  item_details: "आइटम विवरण",
  service: "सेवा का नाम",
  inventory_details: "सूची विवरण",
  list_of_suff: "पर्याप्त वस्तुओं की सूची",
  list_of_norm: "सामान्य मात्रा आइटम की सूची",
  list_of_low: "कम मात्रा की वस्तुओं की सूची",
  list_of_deli: "वितरित सेवाओं की सूची",
  list_of_ord: "आदेशित सेवा सूची",
  list_of_all: "सभी सेवा अनुरोधों की सूची",
  serId: "सेवा आईडी",
  pay_success: "भुगतान सफलतापूर्वक जमा किया गया",
  list_desc: "अनुरोधित वस्तुओं की सूची",
  desc: "विवरण",
  quantity: "मात्रा",
  msg:"संदेश",
  rep_id:"प्रतिनिधि मोबाइल नंबर",
  MR:"मास्टर प्रतिनिधि",
  master_rep:"मास्टर प्रतिनिधि",
  request_after_or_befor_payment:"",
  you_will_shortly_get_amt:"आपकी राशि मेला की पुष्टि के बाद स्वचालित रूप से वापस कर दी जाएगी। इसमें 5 से 10 दिन का समय लग सकता है। कृपया 'फॉर्म भरें' को क्लिक करके भरें।",
  nmbr_exist: " इस नंबर से आवेदन मौजूद है । ",
  org_code:"संस्था कोड",
  your_app_cancled:"आपका आवेदन रद्द कर दिया गया है",
  refund_amt:"वापसी योग्य राशि",
  cancle_your_app_and_get_refund:"अपना आवेदन रद्द करें",
  reconcile:"समाधान करना",
  refundable_amt:"वापसी योग्य राशि",
  fill_form:" प्रपत्र भरिये",
  given_quantity:"दी गई मात्रा",
  refund:"धन की वापसी",
  txn_date:"लेन - देन की तारीख",

  cancle_application:"आवेदन रद्द करें",
  view_letter:"आवंटन पत्र देखें",
  all_previouse_land:"सभी पिछली भूमि",
  change_allotment:"आबंटन बदलें",
  allow_user_for_service:"सेवा के लिए आवेदकों को अनुमति दें",
  nmbr_exist2:
    "यदि आप नया आवेदन जमा करना चाहते हैं तो कृपया अलग नंबर दर्ज करें ।",
  kumbh_logo1: "दिव्य कुंभ भव्य कुंभ",
  kumbh_logo2: "मेला अधकारी कार्यालय कुंभ मेला",
  rejected:"शुल्क छूट अनुरोध अस्वीकार कर दिया गया",
  accepted:"शुल्क छूट का अनुरोध स्वीकार किया गया",
  sec_plot_warning: "कृपया सेक्टर या प्लॉट चुनें",
  added: "उपयोगकर्ता जोड़ा गया",
  delete:"हटाए",
  deleted: "उपयोगकर्ता हटा दिया गया",
  generated : "आबंटन पत्र उत्पन्न",
  confirm_app: "आपके आवेदन की पुष्टि और जमा कर दी गई है",
  wait : "आगे की अधिसूचना का इंतजार करें",
  app_list : "स्वीकृत सेवा अनुरोधों की सूची",
  plot_details:"प्लॉट का विवरण",
  cancel_list : "रद्द सेवा अनुरोधों की सूची",
  pending_list : "लंबित सेवा अनुरोधों की सूची",
  delivered_list : 'वितरित सेवा अनुरोधों की सूची',
  approved : 'सेवा को स्वीकार किया गया',
  cancled : 'सेवा रद्द कर दी गयी',
  red_service : 'मेला सेवा पोर्टल पर पुनर्निर्देशित करना',
  ordered_ser_req : 'सभी सेवा अनुरोध',
  ser_Ready_app : 'अनुमोदन के लिए सेवा अनुरोध',
  pending_ser_req : 'लंबित सेवा अनुरोध',
  app_ser_req : 'स्वीकृत सेवा अनुरोध',
  cancelled_ser_req : 'अस्वीकृत सेवा अनुरोध',
  deli_ser_req : 'वितरित सेवा अनुरोध',
  past_question : 'क्या आप उस आवेदक के लिए आवेदन कर रहे हैं जिसे पहले २०१० या २०१६ के कुंभ में जमीन आवंटित की गई है?',
  past_details : 'पिछले विवरण',
  yes : 'हाँ',
  no : 'नहीं',
  service_question : 'क्या आप अपने अनुरोध में कोई मेला सेवाएँ जोड़ना चाहते हैं?',
  cnf_details : 'विवरण की पुष्टि करें',
  final_submit : 'अंतिम जमा',
  approve : 'स्वीकृत',
  sec_name : "सेक्टर का नाम",
  sec_ar : 'सेक्टर क्षेत्र',
  total_plots : 'भूखंडों की कुल संख्या',
  total_sec_ar : 'कुल क्षेत्रफल',
  general : 'सामान्य',
  reserved : 'आरक्षित',
  total_pl : 'भूखंडों की कुल संख्या',
  total_ar : 'कुल क्षेत्रफल',
  allt_pl : 'आवंटित भूखंड',
  allt_ar : 'आवंटित क्षेत्रफल',
  unallt_pl : 'अनाबंटित भूखंड',
  unallt_ar : 'अनाबंटित क्षेत्रफल',
  req_land_details : 'अनुरोधित भूमि का विवरण',
  alt_land_details : 'आवंटित भूमि का विवरण',
  A_list: 'आवेदक सूची',
  R_list: 'प्रतिनिधि सूची',
  MAA_list : 'मेला आबंटन व्यवस्थापक सूची',
  MA_list: 'मेला प्रमुख सूची',
  MSA_list: 'मेला सेवा व्यवस्थापक सूची',
  MTA_list: 'मेला साइट व्यवस्थापक लिस्ट',
  MFA_list: 'मेला वित्त व्यवस्थापक सूची',
  add_user: 'नए उपयोगकर्ता को जोड़िये',
  new_member_detail : 'नया सदस्य का विवरण दर्ज करें',
  sel_name : "नाम दर्ज करें",
  sel_phone : "मोबाइल नंबर दर्ज करें",
  sel_add : 'पता लिखिए',
  sel_email : "ईमेल आईडी दर्ज करें",
  sel_role : "भूमिका का चयन करें",
  delete_user : 'उपभोक्ता मिटायें',
  ser_id : 'सेवा आईडी',
  p_p_u : 'प्रति इकाई मूल्य',
  app_req : "सभी आइटम स्वीकृत करें",
  rej_req : "अनुरोध अस्वीकार करें",
  user : 'उपयोगकर्ता',
  land_app : 'भूमि के लिए आवेदन',
  serv_app : 'सेवा के लिए आवेदन',
  payment_master : 'भुगतान',
  active_users : 'सक्रिय उपयोगकर्ता',
  blocked_pl : 'ब्लॉक किए गए प्लॉट',
  blocked_ar : 'अवरुद्ध क्षेत्र',
  view_full_rep : 'पूरी रिपोर्ट देखें',
  d_a_p : 'आवेदन फार्म डाउनलोड करें',
  land_master : 'भूमि',
  select_category : 'श्रेणी का चयन करें',
  total_availability : 'कुल क्षेत्र की उपलब्धता',
  land_cat : 'श्रेणी के अनुसार भूमि',
  sec_avail : 'क्षेत्रवार उपलब्धता',
  ar_avail : 'क्षेत्र की उपलब्धता (SQFT)',
  l_a_c : 'श्रेणी के अनुसार भूमि क्षेत्र (SQFT)',
  l_a_s : 'सेक्टर द्वारा भूमि क्षेत्र की उपलब्धता (SQFT)',
  p_a : 'प्लॉट की उपलब्धता',
  p_c : 'श्रेणी के अनुसार भूखंड',
  p_a_s : 'सेक्टर द्वारा प्लॉट की उपलब्धता',
  plot_count : 'प्लॉट काउंट',
  plot_area : 'भूखंड क्षेत्रफल',
  Available : 'उपलब्ध',
  unAvailable : 'अनुपलब्ध',
  cat : 'वर्ग',
  plot : 'भूखंड',
  Blocked_Plots:'  ब्लॉक किए गए प्लॉट',
  Blocked_area:' ब्लॉक किए गए क्षेत्र',
    avai_plot:"उपलब्ध भूखंड",
  alloted_sector_in_2010:"2010 में आवंटित क्षेत्र",
  avai_area:"उपलब्ध क्षेत्र",
  purpose:"उद्देश्य",
  comment:"टिप्पणी",
  login_page:"कुंभ मेला हिंदू धर्म में एक प्रमुख तीर्थ और त्योहार है। हरिद्वार में कुंभ मेला हर 12 साल में हरिद्वार, भारत में आयोजित किया जाता है। हरिद्वार उत्तर भारत के उत्तराखंड राज्य का एक प्राचीन शहर और महत्वपूर्ण हिंदू तीर्थ स्थल है, जहाँ गंगा नदी हिमालय की तलहटी से बाहर निकलती है। कई पवित्र घाटों (स्नान के चरणों) में सबसे बड़ा, हर की पौड़ी एक रात्रि गंगा आरती (नदी-पूजा समारोह) का आयोजन करती है जिसमें छोटे-छोटे टिमटिमाते दीप जलाए जाते हैं। वार्षिक कांवर मेला सहित प्रमुख त्योहारों के दौरान उपासक शहर भरते हैं। हरिद्वार कुंभ मेले के चार स्थलों में से एक है, अन्य प्रयाग (इलाहाबाद), त्र्यंबक (नासिक) और उज्जैन हैं। यद्यपि प्राचीन भारतीय साहित्य में नदियों के स्नान पर्वों के कई संदर्भ हैं, लेकिन कुंभ मेले की सही उम्र अनिश्चित है",

  user_guide:"उपयोगकर्ता गाइड"


};
export const profileHIMsg = (msg: string) => {
  i18nData.profile_form_msg = msg;
};
export const UpdateHIMsg = (msg1: string, msg2: string) => {
  i18nData.registration_Form_msg1 = msg1;
  i18nData.registration_Form_msg2 = msg2;
};
export const HI = (): ILocalization => {
  return i18nData;
};
