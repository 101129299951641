<br><br>
<div style="width: 80%; margin-left: 10%; background: white;">
    <div class="row" style="width: 96%; margin-left: 2%;">
        <!-- <div class="row">
            <div class="col-12 text-right">
               
                    <button style="float: right;" class="btn btn-success" (click)="downloadPdf()">{{localizationData['download_list']}}</button>
   
            </div>
        </div> -->
        <div class="col-12">
            <div>
                <mat-form-field fxFlex="40%">
                    <input matInput type="text" (keyup)="filter($event.target.value)" placeholder="Filter">
                </mat-form-field>
            </div>
            <table mat-table [dataSource]="verifydataSource" matSort class="mat-elevation-z8">

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

                <!-- Position Column -->
                <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef> {{localizationData['s_no']}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.sno}} </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> {{localizationData['app_name']}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.ip_name}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="ip_mobile">
                    <th mat-header-cell *matHeaderCellDef>{{localizationData['applicant_contact']}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.ip_mobile}} </td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="catagory">
                    <th mat-header-cell *matHeaderCellDef> {{localizationData['organization_type']}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.ref_val}} </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="amt">
                    <th mat-header-cell *matHeaderCellDef>{{localizationData['amount']}}</th>
                    <td mat-cell *matCellDef="let element"> {{element.amt}} </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>{{localizationData['status']}}</th>
                    <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                </ng-container>


                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> {{localizationData['action']}}</th>
                    <td mat-cell *matCellDef="let element">
                        <button class="btn btn-primary" *ngIf="element['status']!='Approved'" style="font-size: 16px;"
                            data-target="#exampleModalPreview" data-toggle="modal"
                            (click)="setData(element)">{{localizationData['approve']}}</button>&nbsp;
                        <!-- <button class="btn btn-danger" style="font-size: 16px;"
                            >Delete</button> -->
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div>
                <mat-paginator [pageSizeOptions]="[5, 10, 20, 50]"></mat-paginator>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalPreview" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLabel">
                    <h1> {{localizationData['applicant_detail']}}:</h1>
                </h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-4">
                        <h3>{{localizationData['app_name']}}:</h3>
                    </div>
                    <div class="col-4">
                        <input type="text" name="" id="" disabled [(ngModel)]="selectedObj['ip_name']"
                            class="form-control">
                    </div>
                    <div class="col-2"></div>
                </div>

                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-4">
                        <h3>{{localizationData['applicant_contact']}} :</h3>
                    </div>
                    <div class="col-4">

                        <input type="text" name="" id="" disabled [(ngModel)]="selectedObj['ip_mobile']"
                            class="form-control">
                    </div>
                    <div class="col-2"></div>
                </div>

                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-4">
                        <h3>{{localizationData['refundable_amt']}} :</h3>
                    </div>
                    <div class="col-4">

                        <input type="text" name="" id="" disabled [(ngModel)]="selectedObj['amt']" class="form-control">
                    </div>
                    <div class="col-2"></div>
                </div>
                <br>
               
                <div class="row" style="width: 90%; margin-left: 5%;">
                    <div class="col-12">
                        <b>{{localizationData['current_allotment']}}</b>
                    </div>
                </div>
                <div class="row" style="width: 90%; margin-left: 5%;">
                    <div class="col-12">
                        <table class="table table-bordered" style="width: 90%;margin-left: 5%;margin-top: 3%;">
                            <thead>
                              <tr>
                                <th>{{localizationData['s_no']}}</th>
                                <th>{{ localizationData.alloted_sec }}</th>
                                <th> {{ localizationData.alloted_area }}</th>
                                <th>Plot Area</th>
                                <th>Land Status</th>
                                <th>{{localizationData['Payment_Type']}}</th>
                                <th>{{localizationData['isPayment_done']}}</th>
                                <th>{{ localizationData['amount'] }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let item of allotedData;let i =index">
                                <td>{{i+1}}</td>
                                <td>{{ item.alloted_Sector }}</td>
                                <td>{{ item.alloted_area }}</td>
                                <td>{{ item.area_alloted }} {{item.lc_geo_area_unit}}</td>
                                <td>{{item['alt_status']}}</td>
                                <td>{{item['payment_type']}}</td>
                                <td>{{item['isPaid']}}</td>
                                <td>{{ item.pmnt_amount }}</td>
                              </tr>
                            </tbody>
                          </table>
                            
              
                    </div>
                </div>


                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" style="font-size: 16px;"
                        (click)="approve_swal()">{{localizationData['approve']}}</button>
                    <button type="button" class="btn btn-danger" style="font-size: 16px;" data-dismiss="modal">
                       {{localizationData['close']}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner>Loading...</ngx-spinner>