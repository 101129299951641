<nav class="navbar navbar-expand-lg">
  <!-- <a class="navbar-brand" href="#">Navbar</a> -->
  <button class="navbar-toggler" style="background-color: rgb(197, 197, 219); margin-left: 90%;" type="button"
    data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
    aria-expanded="false" aria-label="Toggle navigation">
    <i class="fa fa-bars" style="font-size: 80px;" aria-hidden="true" style="color: white;"></i>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent" *ngIf="displayElement">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a mat-button *ngIf="selected === 'R' || selected === 'MR' || selected === 'A'" (click)="navi('/notice')">
          <b>{{ localizationData.notice_board }}</b>
        </a>
      </li>
      <li class="nav-item">

        <a mat-button *ngIf="selected === 'MA'" (click)="navi('/melaHead')"><b>{{ localizationData.dashboard }}</b></a>
      </li>
      <li class="nav-item">
        <a mat-button *ngIf="selected === 'MFA'" (click)="navi('/finance')"><b>{{ localizationData.dashboard }}</b></a>
      </li>
      <li class="nav-item">

        <a mat-button *ngIf="selected === 'MTA'" (click)="navi('/melaSiteAdmn')"><b>{{ localizationData.dashboard
            }}</b></a>
      </li>
      <li class="nav-item">

        <a mat-button *ngIf="selected === 'M'" (click)="navi('/master')"><b>{{ localizationData.dashboard }}</b></a>
      </li>
      <li class="nav-item">

        <a mat-button *ngIf="selected === 'MAA'" (click)="navi('/melaAllotAdmn')"><b>{{ localizationData.dashboard
            }}</b></a>
      </li>
      <!-- <li class="nav-item">
      
              <a mat-button *ngIf="selected === 'MLA'" (click)="navi('/melaLandAdmin')"
              ><b>{{ localizationData.dashboard }}</b></a
            >      </li> -->
      <li class="nav-item">
        <a mat-button *ngIf="selected === 'MAA'" (click)="navi('/landSummary')"><b>{{ localizationData.land_details
            }}</b></a>
      </li>
      <li class="nav-item">
        <a mat-button *ngIf="selected === 'MA'" (click)="navi('/landSummary')"><b>{{ localizationData.land_details
            }}</b></a>
      </li>
      <li class="nav-item">
        <a mat-button *ngIf="selected === 'M' || selected === 'MLA'" (click)="navi('/landSummary')"><b>{{
            localizationData.land_details }}</b></a>
      </li>

      <!-- ---- -->

      <!--  -->
      <!-- <li class="nav-item">
              <a mat-button *ngIf="selected === 'MAP'" (click)="navi('/')"
              ><b>{{ localizationData.dashboard }}</b></a
            >      </li> -->
      <li class="nav-item">

        <a mat-button *ngIf="selected === 'MSA'" (click)="navi('/melaServAdmn')"><b>{{ localizationData.dashboard
            }}</b></a>
      </li>
      <!-- <li class="nav-item">
              <a mat-button *ngIf="selected === 'MAP'" (click)="navi('/allot')"
              ><b>{{ localizationData.allotment }}</b></a
            >      </li> -->
      <li class="nav-item">
        <a mat-button *ngIf="selected == 'MAP'" (click)="redirectToApp()">
          <b>Edit Application</b>
        </a>
      </li>
      <li class="nav-item">
        <a mat-button *ngIf="selected !== 'A'" (click)="navi('/profile')">
          <b>{{ localizationData.profile }}</b>
        </a>
      </li>
      <li class="nav-item">
        <a mat-button *ngIf="selected == 'MAA'" routerLinkActive="active" (click)="redirectToPayment()">
          <b>{{localizationData['payment']}}</b>
        </a>
      </li>
      <li class="nav-item">
        <a mat-button *ngIf="selected == 'MLA'" (click)="redirectToMA()">
          <b>{{localizationData['land_status']}}</b>
        </a>
      </li>
      <li class="nav-item">
        <a mat-button *ngIf="selected == 'MLA'" (click)="redirectToaddLand()">
          <b>{{localizationData['add_land']}}</b>
        </a>
      </li>
      <li class="nav-item">
        <a mat-button *ngIf="selected == 'MAA'" (click)="redirectToRefundRecord()">
          <b>{{localizationData['refund']}}</b>
        </a>
      </li>
      <li>
        <a mat-button *ngIf="selected == 'MLA'" (click)="redirectToSetRate()">
          <b>{{localizationData['set_org_rate']}}</b>
        </a>
      </li>
      <li class="nav-item">

        <a mat-button *ngIf="selected === 'R'||selected === 'MR' || selected === 'A'" (click)="navi('/registration_form')">
          <b>{{ localizationData.application }}</b></a>
      </li>
      <li class="nav-item">
        <a mat-button *ngIf=" selected === 'R'" (click)="navi('/viewApp')">
          <b>{{ localizationData.all_application }}</b>
        </a>
      </li>
      <li class="nav-item">
        <a mat-button *ngIf="selected === 'MR'" (click)="navi('/notice/all-application')">
          <b>{{ localizationData.all_application }}</b>
        </a>
      </li>
      <li class="nav-item">
        <a mat-button *ngIf="selected === 'R' " (click)="navi('/allotServList')">
          <b>{{ localizationData.services }}</b></a>
      </li>
      <li class="nav-item">
        <a mat-button *ngIf="selected === 'MR' " (click)="navi('/notice/all-service')">
          <b>{{ localizationData.services }}</b></a>
      </li>
      <li class="nav-item">

        <a mat-button *ngIf="selected === 'A' && status > '3' && is_service_applicable === 'YES' "
          (click)="navi('/allotServList')">
          <b>{{ localizationData.services }}</b></a>
      </li>
      <li class="nav-item">
        <a mat-button *ngIf="selected === 'M' || selected === 'MSA'" (click)="redirectToService()"><b>{{
            localizationData.add_service }}</b></a>
      </li>
      <!-- <li class="nav-item">
        <a mat-button *ngIf="selected === 'M' || selected === 'MSA'" (click)="redirectToService_letter()"><b>Service Letter</b></a>
      </li> -->
    </ul>

    <!-- service-letter -->
    <form class="form-inline ">
      <div class="form-row align-top">
        <div class="col align-top" style="margin-top: 0px;">
          <mat-form-field style="float:right; width: 120px;  margin-right: 10px; text-align:start;">
            <mat-label style="color: white; font: bold; font-size: 16px;">
              <h2>{{ localizationData.role }}</h2>
            </mat-label>
            <mat-select class="dropdown-menu align-top" [(value)]="selected"
              style="font-size: 13px; margin-top: 0px; padding-top: 0px; ">
              <mat-option class="dropdown-item" style="background-color: black; color: white; font:bold; "
                *ngFor="let Role of Roles" [(value)]="Role.RoleCode" (click)="onClickRole(Role.RoleCode)">{{
                getRoleName(Role.RoleCode) }}
              </mat-option>
            </mat-select>

          </mat-form-field>
        </div>
        <div class="col" style="margin-top: 5px;">
          <span class="justify-content-end" data-icon="mdi-logout" data-inline="false"></span>
          <a (click)="logOut('/auth')" class="justify-content-end"
            style="color: white; font-size: 15px;cursor: pointer;"> <i class="fa fa-sign-out" aria-hidden="true"></i> {{ localizationData.signout }}</a>
        </div>
      </div>
    </form>

  </div>


</nav>