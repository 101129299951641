import { ILocalization } from "./localization.model";
let i18nData: ILocalization = {
  name: "Name",
  address: "Address",
  mobile_number: "Mobile Number",
  verify_number: "Verify Number",
  mobile: "Mobile",
  email: "Email",
  kumbh_mela_2021: "Bhumi Avantan & Facilities - Kumbh Mela 2021",
  kumbh_mela: "Kumbh Mela,Haridwar",
  notice_board: "Notice Board",
  profile: "Profile",
  dashboard: "DashBoard",
  application: "Application",
  reconcile:"Reconsile",
  view_letter:"view allotment letter",
  all_application: "All Application",
  total_application: "Total Application",
  welcome: "Welcome",
  export:"Export List",
  reconsiled:"Reconsiled",
  info_status:"Information of status",
  all_pmnt_status_info:"All Payment Status Information",
  ji: "",
  all_fee_exempted_record:"All Fee Exempted Record",
  app_name:"Applicant Name",
  view_doc:"View Document",
  your_file_status:"Your File Status Is",
  no_of_app:"Number of application",
  land_allotment: "Land Allotment",
  interactive_map: "Interactive Map",
  completed_application: "Completed Application",
  partial_application: "Partial Application",
  applicant_detail: "Applicant Details",
  land_details: "Land Details",
  txn_date:"Transaction Date",
  payment: "Payment",
  done: "Done",
  representative: "Representative",
  applicant: "Applicant",
  select_past_allotment_detail: "Select past allotment details",
  enter_name: "Enter Name",
  applicant_contact: "Applicant Contact",
  applicant_email: "Applicant Email",
  select_organization_type: "Select Organization Type",
  next: "Next",
  pay_app_fee: "Pay Application Fee",
  wait_for_further_notice: "Wait For Further Notice",
  pay_allot_fee: "Pay Allotment Fees",
  down_allot_letter: "Download Allotment Letter",
  Enter_otp: "Enter Otp",
  submit: "Submit",
  under_process_app: "Under Process Application",
  alloted_app: "Allotted Application",
  request_sec: "Requested Sector",
  request_area: "Requested Area",
  close: "Close",
  save_changes: "Save Changes",
  item_per_page: "Items per page",
  personal_details: "Personnal Details",
  allotment: "Allotment",
  previous_year_record: "Previous Year Records",
  paid_fee: "Paid Fees",
  is_exemp: "Is Exempted",
  date:"Date",
  mela_allot_admin: "Mela Allotment Admin",
  mela_adikari: "Mela Adhikari",
  alloted_sec: "Alloted Sector",
  allService:"All Service Of Level",
  txn_id:'Transaction ID',
  check_txn_status:"Check Payment Status",
  alloted_area: "Alloted Area",
  generate_letter: "Generate Letter",
  payment_detail:"Payment Details",
  generate: "Generate",
  file_status:"Check Uploaded File Status",
  service_desc_hi:"Service Description In Hindi",
  login: "Login",
  service_name:"Service Name",
  language:"english",
  add_service:"Add Service",
  mela_txn_no:'Mela Transaction ID',
pmnt_status:"Payment Status",
ici_txn_no:"ICICI Transaction Number",
  fee_ack:'Fee Acknowledgement',
  role: "Role",
  signout: "Log-Out",
  apply_for_allotment: "Apply For Allotment",
  complete_land_details: "Complete Land Details",
  app_progress: "Your Application is in Progress!!!",
  select_sector: "Select Sector",
  Select_Required_Area: "Required Area",
  back: "Back",
  select_sector_from_map: "Select Sector from map",
  select_plot_from_map: "Select Plot from map",
  for_exem_applicant: "Please Check For Exempted Applicant",
  upload_support_doc: "Upload supporting document",
  app_fee: "Application fee",
  deposit: "Deposit",
  pay: "Pay",
  go_to_dashboard: "Go to dashboard",
  choose_file: "Choose File",
  upload: "Upload",
  action: "Action",
  complete_application: "Complete Application",
  or: "OR",
  allot: "Allot",
  services: "Services",
  finance: "Finance",
  represent_msg:
    "Welcome to the land allocation and management of other services of the fair administration.",
  confirm_allotment: "Confirm Allotment",
  Allotmnet_Fees_Payment: "Allotment Fees Payment",
  Fees_Payable: "Fees Payable",
  Payment_Type: "Payment Type",
  Alloted_Plot: "Alloted Plot",
  Plot_Size: "Plot Size",
  pageNotFound: "Page Not Found",
  A: "Applicant",
  R: "Representative",
  MAA: "Mela Allot Admin",
  MA: "Mela Head",
  MSA: "Mela Service Admin",
  MTA: "Mela Site Admin",
  MLA:"Mela Land Admin",
  M: "Master Admin",
  MAP: "Mela Application Admin",
  MFA: "Mela Finance Admin",
  completed_allotment: "Completed Allotment",
  not_allotted: "Unallotted",
  count: "Count",
  plot_name:"Plot Name",
  plot_desc:"Plot Description",
  edit_details:"Edit Details",
  delete:"Delete",
  fees: "Fees",
  enter_area:"Enter Area",
  update_plot:"Update Plot",
  add_land:"Add Land",
  land_status:"Land Status",
  plot_details:"Plot Details",
  total: "Total",
  select_plot:"Select Plot",
  add_plot_loc:"Add Plot Location",
  recon_status:"Reconciliation Status",
  recon:"Reconcilation",
  not_recon:"Not Reconciled",
  recon_by_date:"Reconcilation By Date",
  recon_of_date:"Reconcilation By A Date",
  amount: "Amount",
  yes_recon:"Reconciled",
  denomination: "Denomination",
  identification: "Identification",
  contact_details: "Contact Details",
  representative_signin_msg: "Now you can Login as a representative",
  go_for_login: "Go For Login",
  registration_Form_msg1: "",
  registration_Form_msg2: "",
  profile_form_msg: "",
  application_payment_is_completed: "Application payment is completed ",
  waiting_for_payment: "Waiting for payment",
  allotment_letter_generated: "Allotment letter generated",
  exempted: "Exempted",
  total_fee: "Total Payment",
  alloted: "Alloted",
  requested_land_details: "Requested Land Detail",
  no_past_allot_record: "No past allotment record for this year.",
  no_prev_record: "No past allotment record ",
  go_to_notice: "Go to notice board",
  cancel: "Cancel",
  please: "Please",
  field_is_required: "This field is required",
  enter_valid_number: "Please enter a valid mobile number",
  submit_correct_info: "Please enter correct information",
  invalid_email: "Invalid email",
  logged_in: "Logged In",
  registration_invalid: "Registration Invalid",
  mr: "Mr.",
  mrs: "Mrs.",
  requested: "Requested",
  req_for_reallot: "Request for re-allotment",
  req_reallot: "Requested for re-allotment",
  app: "",
  reason: "Reason For Re-Allotment",
  cancelled: "Cancelled",
  re_allot: "Re-allot",
  notify_msg1:
    "Applicant detail is completed please complete your land detail.",
  notify_msg2: "Application details are completed please pay application fee.",
  notify_msg3: "Application is completed please wait for further notification.",
  notify_msg4:
    "Your application is in progress please wait for further notification.",
  notify_msg5:
    "Your land Allotment is completed please check your land details and pay allotment fee.",
  notify_msg6:
    "Your allotment fee is submitted,please wait for allotment letter",
  notify_msg7:
    "Your allotment process is completed now you can download allotment letter and apply for services",
    download_msg: "Download Application Form",
  enter_valid_emai: "Please enter valid email",
  view: "View",
  clear: "Remove selected",
  download_list: "Download List",
  download_letter: "Download Letter",
  print: "Print",
  register_success: "Registration Successfull",
  number_registered: "Number Registered",
  file_upload: "File uploaded",
  is_exemp_file_upload: "File for exempted applicant is uploaded successfully",
  sector_details: "Sector Detail",
  sector_name: "Sector Name",
  total_land_area: "Area of Sector",
  avail_land_area: "Available area ",
  fee_exemption: "Fees Exemption Request",
  apply_service: "Apply for Services",
  serv_msg:
    "You can apply for services from here.You can apply only if plot is allotted.Please check details and proceed for application",
  select: "Select",
  price: "Price (per item)",
  size: "Size",
  remove: "Remove",
  types: "Types",
  MR:"Master Representative",
  s_no: "S.No.",
  master_rep:"Master Representative",
  rep_id:"Representative Mobile Number",
  you_dont_need_to_pay:"You don't need to  pay land amount ,you can upload file for exemption",
  serv_item_detail: "Service Items Detail",
  service_list: "Service Category List",
  mela_serv: "Mela Services",
  total_amount:"Total Amount",
  list_item: "List of service items",
  filter: "Filter",
  isPayment_done:"Is Payment Done",
  service_selected: "Service selected",
  select_service: "Please select service before submit.",
  selected_service_list: "Selected Service List",
  unit: "Unit",
  req_serv_app: "Requested for service application",
  serv_approve_app: "Service Approved Application",
  no_serv_select: "No Service is Selected",
  req_unit: "Requested Quantity",
  total_req_serv: "Total Requested Service Item",
  total_ser_req: "Total Service Requests",
  organization_type: "Organization Type",
  allottedServiceList: "Allotted Service List",
  land: "Land",
  serv_req_id: "Service Request Id",
  date_time: "Date & Time",
  status: "Status",
  ordered_request: "Ordered ",
  inProgress: "In Process",
  and: "&",
  add_serv: "Add more services",
  serv_detail: "Service Detail",
  line_nmbr: "Line Number",
  product: "Product",
  serv_req: "Service Requests",

  timestamp: "Date and Time",
  is_alloted: "Is Alloted",
  no_of_demand: "No of Request",
  alloted_quantity: "Allot Quantity",
  exem: "Exemption",
  cost: "Net Amount",
  total_cost: "Aotal Amount",
  sel_ser_list: "Selected Services List",
  item: "Item",
  measurement: "Measurement",
  total_avail: "Total Purchased Quantity",
  rem_aial: "Remaining Quantity",
  demand: "Expected Demand",
  threshold: "Low Threshold",
  add: "Add More Item",
  change_thresh: "Change Threshold",
  disable: "Disable Item",
  service_desc: "Service Type",
  ordered_quantity: "Ordered Quantity",
  low: "Services having low inventory",
  normal: " Service with normal inventory",
  sufficient: " Service with sufficient inventory",
  // serv_req: "Service Request",
  inventory: "Inventory",
  item_details: "Item Details",
  service: "Service Name",
  inventory_details: "Inventory Details",
  list_of_suff: "List of Sufficient Items",
  list_of_norm: "List of Normal Quantity Items",
  list_of_low: "List of Low Quantity Items",
  list_of_deli: "list of Delivered Services",
  list_of_ord: "Ordered Services List",
  list_of_all: "List of All Service Request",
  serId: "Service Id",
  pay_success: "Payment Submitted Successfully",
  list_desc: "Requested Items List",
  desc: "Description",
  quantity: "Quantity",
  nmbr_exist: "Application from this number is exists. ",
  nmbr_exist2:
    "Please enter different number if you want to submit new application.",
  kumbh_logo1: "Divya Kumbh Bhavya Kumbh",
  kumbh_logo2: "Mela Adhikari Office Kumbh Mela",
  accepted:"Fees exemption request accepted",
  rejected:" Fees exemption request rejected",
  sec_plot_warning: "Please select Sector or Plot",
  added : "User Added SuccessFully",
  deleted: "User Deleted Successfully",
  generated: "Allotment Letter Generated",
  confirm_app: "Your application is confirmed and submitted",
  wait : "Wait for further notification",
  app_list : "List of Approved Service Requests",
  cancel_list : "List of Cancelled Service Requests",
  pending_list : "List of Pending Service Requests",
  delivered_list : 'List of Delivered Service Requests',
  approved : 'Service is approved',
  cancled : 'Service is cancelled' , 
  red_service : 'Getting redirected  to Mela Service Portal',
  ordered_ser_req : 'All Service Requests',
  ser_Ready_app : 'Service Request for Approval',
  pending_ser_req : 'Pending Service Requests',
  app_ser_req : 'Approved Service Requests',
  org_code:"Orgainzation Code",
    cancelled_ser_req : 'Cancelled Service Requests',
  deli_ser_req : 'Delivered Service Requests',
  past_question : 'Are you applying for an applicant that has previously been allotted land in 2010 Kumbh? ',
  past_details : 'Past Details',
  yes : 'Yes',
  no : 'No',
  accept:"Accept",
  reject:"Reject",
  cancled_app:"Cancled Applications",
  service_question : 'Do you want to add any mela services to your request?',
  cnf_details : 'Confirm Details',
  final_submit : 'Final Submit',
  approve : 'Approved',
  sec_name : "Sector Name", 
  sec_ar : 'Sector Area',
  total_plots : 'Total No. of Plots',
  total_sec_ar : 'Total Area',
  general : 'General',
  reserved : 'Reserved' , 
  total_pl : 'Total Plots',
  total_ar : 'Total Area',
  allt_pl : 'Alloted Plots',
  allt_ar : 'Alloted Area',
  unallt_pl : 'Unalloted Plots',
  unallt_ar : 'Unalloted Area',
  blocked_pl : 'Blocked Plots',
  blocked_ar : 'Blocked Area',
  req_land_details : 'Requested Land Details',
  alt_land_details : 'Alloted Land Details',
  A_list: 'Applicant List',
  R_list: 'Representatives List',
  MAA_list : 'Mela Allotment Admin List',
  MA_list: 'Mela Head List',
  MSA_list: 'Mela Service Admin List',
  MTA_list: 'Mela Site Admin LIst',
  MFA_list: 'Mela Finance Admin List',
  add_user: 'Add New User',
  new_member_detail : 'Enter New Member Details',
  sel_name : "Enter Name",
  sel_phone : "Enter Mobile Number",
  sel_add : 'Enter Address',
  sel_email : "Enter Email ID",
  sel_role : "Select Role",
  delete_user : 'Delete User',
  ser_id : 'Service ID',
  p_p_u : 'Price(per Unit)',
  app_req : "Approve All Item",
  rej_req : "Reject Request",
  user : 'Users',
  land_app : 'Land Applications',
  serv_app : 'Service Applications',
  payment_master : 'Payment',
  active_users : 'Active Users',
  view_full_rep : 'View Full Report',
   d_a_p: 'Download Application Form',
   land_master : 'Land', 
   select_category : 'Select Category',
   total_availability : 'Total Availability',
   land_cat : 'Land By Category',
   sec_avail : 'Sector wise Availability',
   ar_avail : 'Area Availability (SQFT)',
   l_a_c : 'Land Area by Category (SQFT)',
   l_a_s : 'Land Area Availability by Sector (SQFT)',
   p_a : 'Plot Availability',
   p_c : 'Plots by Category',
   p_a_s : 'Plot Availability by Sector',
   plot_count : 'Plot Count',
   plot_area : 'Plot Area',
   Available : 'Available',
   unAvailable : 'Unavailable',
   cat : 'Category',
   plot : 'Plot',
   avai_plot:'Available plot',
   long:"Longitude",
   lang:"Latitude",
   avai_area:'Available area',
   Blocked_Plots:'Blocked plots',
  Blocked_area:'Bolocked area',
  current_status:"Current Status",
  alloted_sector_in_2010:'Alloted Sector in 2010',
  purpose:'Purpose',
  selected:"Selected",
  enable:"Enable",
  provided_area:"Provided Area",
  selected_plot_area:"Selected Plot Area",
  plot_aval_area:"Plot Available Area",
  disble:"Disable",
  per_sqft:"Per SQFT",
  rate:"Rate",
  update:"Update",
  set_org_rate:"Set Sector Rate",
  sector_org_list:"Sector Organization Rate List",
  add_plot:"Add Plot",
  update_price:"Update/Delete Price",
  current_allotment:"Current Allotment",
  yes_go:"Yes..!Go Back",
  plot_number:"Plot Number",
  newly_alloted_plot:"Newly Alloted Plots",
  comment:'Comment',
  description:"Description",
  do_you_realy_go_back:"Do you really want to go back..!",
  enter_area_error:"Available area is less than the area you are going to provide",
  change_sector_status:"Change Sector Status",
  change_plot_status:"Change Plot Status",
  app_past_details:"Applicant Past Details",
  login_page:"Kumbha Mela is a major pilgrimage and festival in Hinduism.\n The Kumbh Mela at Haridwar is a mela held every 12 years at Haridwar, India. \nHaridwar is an ancient city and important Hindu pilgrimage site in North India's Uttarakhand state, where the River Ganges exits the Himalayan foothills. The largest of several sacred ghats (bathing steps), Har Ki Pauri hosts a nightly Ganga Aarti (river-worshipping ceremony) in which tiny flickering lamps are floated off the steps. Worshipers fill the city during major festivals including the annual Kanwar Mela.\nHaridwar is one of the four sites of Kumbh Mela, the others being Prayag (Allahabad), Trimbak (Nashik) and Ujjain. Although there are several references to riverside bathing festivals in ancient Indian literature, the exact age of the Kumbh Mela is uncertain.",
  user_guide:"User Guide",
  you_will_shortly_get_amt:"Your Amount will be refunded automatically after mela confirmation.it can take 5 to 10 days.Please fill  form by clcking 'fill form'.",
  all_previouse_land:"All Previouse Land",
  msg:"Message",
  cancle_application:"Cancle Application",
  refundable_amt:"Refundable Amount",
  request_after_or_befor_payment:"Request After/Before Payment",
  refund_amt:"Refundable Amount ",
  refund:"Refund",
  your_app_cancled:"Your Application Is Cancled",
  add_sector_rate:"Add Sector Rate",
  update_sector_rate:"Update Sector Rate",
  given_quantity:"Given Quantity",
  kumbh_year:"kumbh Year",
  fill_form:"Fill Form",
  cancle_your_app_and_get_refund:"Cancle your Application ",
  change_allotment:"Change Allotment",
past_org_cd:"Past Organization code",
allow_user_for_service:"Allow users for service",
};
export const profileENMsg = (msg: string) => {
  i18nData.profile_form_msg = msg;
};
export const UpdateENMsg = (msg1: string, msg2: string) => {
  i18nData.registration_Form_msg1 = msg1;
  i18nData.registration_Form_msg2 = msg2;
};
export const EN = (): ILocalization => {
  return i18nData;
};