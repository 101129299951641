import { Component, OnInit } from '@angular/core';
import { LocalizationService } from 'src/app/shares/store/localization.service';
import { ILocalization } from 'src/app/shares/models';
import { ApiHandlerService } from 'src/app/shares/services/api-handler.service';
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.css']
})
export class MainHeaderComponent implements OnInit {
   email;
  localizationData:ILocalization;
  constructor(private _localization:LocalizationService,  private _apiHandler:ApiHandlerService,private http: HttpClient) { }

  ngOnInit(): void {
    this.getEmailId();
    this._localization.geti18nData().subscribe((res:ILocalization)=>{
      this.localizationData=res
    })
  }
  
  selectLanguage(language:string){
    //console.log("language",language)
    this._localization.seti18nData(language)
  }
  async getEmailId(){
    var response = await this.http.get(this._apiHandler.baseURL + 'getStaticEmail').toPromise();
    console.log(response)
    this.email=response['data'][0]['ref_val'];
    console.log(this.email)
  }

}
