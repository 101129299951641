// import { NgModule } from "@angular/core";
// import { Routes, RouterModule } from "@angular/router";
// import { LoginComponent } from "./login/login.component";
// import { LandRegistrationComponent } from "./land-registration/land-registration.component";
// import { NoticeBoardComponent } from "./notice-board/notice-board.component";
// import { ProfileComponent } from "./profile/profile.component";
// import { MelaHeadComponent } from "./mela-head/mela-head.component";
// import { FinanceComponent } from "./finance/finance.component";
// import { RegistrationFormComponent } from "./registration-form/registration-form.component";
// import { RegistrationComponent } from "./registration/registration.component";

// const routes: Routes = [
//   { path: "", pathMatch: "full", redirectTo: "login" },
//   { path: "login", component: LoginComponent },
//   { path: "login/landregister", component: LandRegistrationComponent },
//   { path: "login/landregister/notice", component: NoticeBoardComponent },
//   { path: "login/landregister/profile", component: ProfileComponent },
//   { path: "mela_head", component: MelaHeadComponent },
//   { path: "finance", component: FinanceComponent },
//   { path: "notice/registration_form", component: RegistrationFormComponent },
//   { path: "registration", component: RegistrationComponent },
  
// ];

// @NgModule({
//   imports: [RouterModule.forRoot(routes)],
//   exports: [RouterModule]
// })
// export class AppRoutingModule {}
// export const routingComponents = [
//   LoginComponent,
//   LandRegistrationComponent,
//   NoticeBoardComponent,
//   ProfileComponent,
//   RegistrationComponent,
//   MelaHeadComponent,
//   RegistrationFormComponent
// ];

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "auth"
  },
  {
    path: "layouts",
    loadChildren: () =>
    import("./layouts/layouts.module").then(mod => mod.LayoutsModule)
  },
  {
    path: "**",
    redirectTo:"auth/404"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
