import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { MapLoaderService } from "./../../../mapDetails/map.loader";
import { ISecMap } from "../../shared/models";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "./../../../components/dialog/dialog.component";
import { LocalizationService } from "src/app/shares/store/localization.service";
import { ILocalization } from "src/app/shares/models";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { ApiHandlerService } from 'src/app/shares/services/api-handler.service';
import { MatTableDataSource } from "@angular/material/table";
import { ThemePalette } from '@angular/material/core';
import { NgxSpinnerService } from 'ngx-spinner'
import Swal from "sweetalert2";
import html2pdf from 'html2pdf.js'
import { AllotService } from "../../allot/allot.service";
import { RegistrationService } from "../../registration/registration.service";
import { RegistrationFormService } from "../../registration-form/registration-form.service";
import { MobileNumberService } from "src/app/shares/store/mobile-number.service";
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


declare var google: any;
@Component({
  selector: 'app-commerce-letter',
  templateUrl: './commerce-letter.component.html',
  styleUrls: ['./commerce-letter.component.css']
})
export class CommerceLetterComponent implements OnInit {
  public formGroup = this.fb.group({
    file: [null, Validators.required],
  });
  localizationData: ILocalization;
  //@ViewChild("pdfTable", { static: false }) pdfTable: ElementRef;
  namea = "Allotment Letter";
  id;
  data;
  sector;
  area;
  name;
  mobile;
  status;
  finalList;
  alloted_area;
  fee_id;
  feeData;
  fees=0;
  pay_type;
  alloted_sec;
  alloted_are;
  sr_alt_id;
  size;
  unit;
  selectedRole;
  org;
  date;
  pdfData;
  pdfName: string;
  file: any;
  show_spinner: boolean;
  alt_data;
  finaldataSource;
  allot_many;
  displayedColumnsa: string[] = [
    "alt_sec",
    "alt_plot",
    "alt_area",
  ];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private router: Router,
    private _localization: LocalizationService,
    private _registration: RegistrationFormService,
    private _mobileService: MobileNumberService,
    private _apiHandler: ApiHandlerService,
    private _toastrService: ToastrService,
  ) { }
  today='';
  dummy = []
  obj = {}
  async ngOnInit() {
    this._localization.geti18nData().subscribe((res: ILocalization) => {
      this.localizationData = res;
    });
    this.id = this.route.snapshot.params["id"];
    console.log(this.id);
    // this.getData();
    this.selectedRole = this._mobileService.getRole();
    await this.getDataa();
    if(this.obj['isAppliedAfterPayment']=='YES'){
      await this.get_Allot_Data();
      await this.setuniqueID_after_payment()
      await this.get_Cancle_Land_Data()
      await this.setData()
    }else{
      await this.get_Allot_Data();
      await this.setUniqueID()
    }   
    await this.getDataa();
    let date = new Date()
    this.today =  date.getFullYear()+'-' + (date.getMonth() + 1)  +'-'+date.getDate() 
    await this.allMails()
  }
  pay_amt;
 async setData(){
    console.log(this.obj,this.fees)
    if(this.obj['cancle_amt']>=this.fees){
this.pay_amt=this.obj['cancle_amt']-this.fees
    }else{
      this.pay_amt=this.fees-this.obj['cancle_amt']
    }
  }
  async getDataa() {
    this.spinner.show()
    var obj = new Object();
    obj["ip_mobile"] = this.id;
    console.log(obj);
    this.data = await this.http
      .post(this._apiHandler.baseURL1 + "applicant_status_desc", obj)
      .toPromise();
    console.log(this.data);
    this.data = this.data.data[0];
    this.obj = this.data
    if (!this.obj['rate']) {
      this.obj['rate'] = '.....'
    }
    console.log(this.data);
    this.name = this.data.ip_name;
    this.mobile = this.data.ip_mobile;
    this.sector = this.data.req_sector;
    this.area = this.data.req_area;
    this.alloted_sec = this.data.alloted_sector;
    this.alloted_are = this.data.alloted_area;
    this.size = this.data.lc_geo_area;
    this.unit = this.data.lc_geo_area_unit;
    this.status = this.data.status;
    this.org = this.data.ref_val_desc;
    this.date = this.data.allotment_date;
    if(!this.obj['amount']){
      this.obj['amount']=0
    }else{
      this.obj['amount']=Number(this.obj['amount']).toFixed(2)
    }
    if(this.data['ip_eff_ts_row_id']){
      let date=this.data['ip_eff_ts_row_id'].split(' ')
      this.obj['application_date']=date[0]
    }else{
      this.obj['application_date']=' '
    }
    this.dummy.push(this.obj['ip_email'])
    console.log(this.dummy,'dummy email')
    this.spinner.hide()
  }
  // public generatePdf(path) {
  //   this.show_spinner = true;
  //   this.pdfName = this.id + ".pdf";
  //   var data = document.getElementById("printReciept");
  //   html2canvas(data).then((canvas) => {
  //     var imgWidth = 208;
  //     // var imgWidth = 225;

  //     var pageHeight = 295;
  //     var imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     // var imgHeight = 250;
  //     var heightLeft = imgHeight;
  //     const contentDataURL = canvas.toDataURL("image/png");
  //     this.pdfData = new jsPDF("p", "mm", "a4");
  //     var position = 0;
  //     this.pdfData.addImage(
  //       contentDataURL,
  //       "PNG",
  //       0,
  //       position,
  //       imgWidth,
  //       imgHeight
  //     );

  //     this.pdfData.save(this.pdfName);
  //     var file = this.pdfData.output("datauristring");
  //     console.log(file, 'file')
  //     let fileData = file.split(";");
  //     let fileData1 = fileData[0];
  //     let fileData2 = fileData[2];
  //     let finalFile = fileData1 + ";" + fileData2;
  //     console.log(finalFile)
  //     this.show_spinner = false
  //     this.generateLetter(finalFile);
  //   });
  // }
  // update_S_id_for_re_generate
  uniqueIDObj = {}
  async setuniqueID_after_payment() {
    this.uniqueIDObj['ip_mobile'] = this.id;
    this.spinner.show()
    var resp = await this.http
      .post(this._apiHandler.baseURL1 + "update_S_id_for_re_generate", this.uniqueIDObj)
      .toPromise();
    console.log(resp, 'update_S_id_for_re_generate')
    if (resp['Success'] != "Ok"){   
      this.spinner.hide()
      this._toastrService.error('Some Error Occured At Server Side', "",
        {
          positionClass: "toast-top-center",
          progressBar: true,
        });
    }
    else {
      this.spinner.hide()
      this.sr_alt_id=resp['s_no']
      this._toastrService.success('Updated Successfully', "",
        {
          positionClass: "toast-top-center",
          progressBar: true,
        });
    }
  }
  async setUniqueID() {
    console.log(this.data,'data')
    this.uniqueIDObj['org_cd'] = this.data['org_cd']
    this.uniqueIDObj['past_app_id'] = this.data['past_app_id']
    this.uniqueIDObj['ref_val_cd'] = this.data['ref_val_cd']
    this.uniqueIDObj['id'] = this.data['id']
    this.uniqueIDObj['past_app_id'] = this.data['past_app_id']
    this.uniqueIDObj['ip_mobile'] = this.id;
    if ( this.data['is_past_id']!=0) {
      this.uniqueIDObj['isPastApp'] = 'YES'
    } else {
      this.uniqueIDObj['isPastApp'] = 'NO'
    }
    this.spinner.show()
    console.log(this.uniqueIDObj, 'unique obj')
    var resp = await this.http
      .post(this._apiHandler.baseURL1 + "update_NewOrg_cd", this.uniqueIDObj)
      .toPromise();
    console.log(resp, 'update_NewOrg_cd')

    if (resp['Success'] != "Ok") {
    
      this.spinner.hide()
      this._toastrService.error('Some Error Occured At Server Side', "",
        {
          positionClass: "toast-top-center",
          progressBar: true,
        });
    }
    else {
      this.sr_alt_id=resp['s_no']
      console.log(this.sr_alt_id,'sr_alt_id')
      this.spinner.hide()
      this._toastrService.success('Updated Successfully', "",
        {
          positionClass: "toast-top-center",
          progressBar: true,
        });
    }
  }
  finalFile = ''
  async downloadPdf() {
    this.spinner.show()
    var element = document.getElementById('obrz');    
    let f;
    let p = html2pdf()
      .from(element)
      .set({
        margin: [30, 0, 30, 0],
        filename: this.obj['ip_mobile']+'_'+this.obj['ip_name']+'.pdf',
        image: { type: 'jpeg', quality: 0.99 },
        html2canvas: {
          dpi: 192, scale: 4, scrollX: 0, scrollY: 0,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
        pagebreak: { before: '.page-break', avoid: 'img' },
      })
      .toPdf()
      .get('pdf').then((pdf) => {
        f = pdf.output("datauristring")
        let fileData = f.split(";");
        let fileData1 = fileData[0];
        let fileData2 = fileData[2];
        this.finalFile = fileData1 + ";" + fileData2;
        console.log(this.finalFile)
        const pdfData = {
          ip_mobile: this.id,
          sr_alt_id:this.sr_alt_id,
          pic: this.finalFile,
        };
        console.log(pdfData['sr_alt_id'], 'pdf data 295')
        this._registration.uploadLetter(pdfData).subscribe((res) => {
          if (res.Success === "Ok") {
            this.sendMAil()
            this.spinner.hide()
            this._toastrService.success(this.localizationData.generated, "",
              {
                positionClass: "toast-top-center",
                progressBar: true,
              });
            this.router.navigate(['/allottedApp'])
          } else {
            this.spinner.hide()
            console.log("error");
          }
        });
        // var totalPages = pdf.internal.getNumberOfPages();
        // for (let i = 1; i <= totalPages; i++) {
        //   pdf.setPage(i);
        //   pdf.setFontSize(10);
        //   pdf.setTextColor(150);
        //   pdf.text('Page ' + i + ' of ' + totalPages, pdf.internal.pageSize.getWidth() - 100, pdf.internal.pageSize.getHeight() - 10);
        // } 
      }).save();


    // console.log(formData)
  }
  async downloadPdf2() {
    this.spinner.show()
    var element = document.getElementById('obrz_2');    
    let f;
    let p = html2pdf()
      .from(element)
      .set({
        margin: [30, 0, 30, 0],
        filename: this.obj['ip_mobile']+'_'+this.obj['ip_name']+'.pdf',
        image: { type: 'jpeg', quality: 0.99 },
        html2canvas: {
          dpi: 192, scale: 4, scrollX: 0, scrollY: 0,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
        pagebreak: { before: '.page-break', avoid: 'img' },
      })
      .toPdf()
      .get('pdf').then((pdf) => {
        f = pdf.output("datauristring")
        let fileData = f.split(";");
        let fileData1 = fileData[0];
        let fileData2 = fileData[2];
        this.finalFile = fileData1 + ";" + fileData2;
        console.log(this.finalFile)
        const pdfData = {
          ip_mobile: this.id,
          sr_alt_id:this.sr_alt_id,
           pic: this.finalFile,
        };
        console.log(this.finalFile, 'pdf data')
        this._registration.uploadLetter(pdfData).subscribe((res) => {
          if (res.Success === "Ok") {
            this.sendMAil()
            this.spinner.hide()
            this._toastrService.success(this.localizationData.generated, "",
              {
                positionClass: "toast-top-center",
                progressBar: true,
              });
            this.router.navigate(['/allottedApp'])
          } else {
            this.spinner.hide()
            console.log("error");
          }
        });
        // var totalPages = pdf.internal.getNumberOfPages();
        // for (let i = 1; i <= totalPages; i++) {
        //   pdf.setPage(i);
        //   pdf.setFontSize(10);
        //   pdf.setTextColor(150);
        //   pdf.text('Page ' + i + ' of ' + totalPages, pdf.internal.pageSize.getWidth() - 100, pdf.internal.pageSize.getHeight() - 10);
        // } 
      }).save();


    // console.log(formData)
  }
  async sendMAil() {
    console.log('send mail')
    let obj = {}
    obj["ip_mobile"] = this.id;
    obj["path"] = this._apiHandler.baseURL2
    obj['email'] = this.allMailObj['email']
    obj['ip_name'] = this.name
    console.log(obj, 'obj for send mail')
    var resp = await this.http.post(this._apiHandler.baseURL1 + "sendMailToAdmins", obj).toPromise();
    console.log(resp)
  }
  allMailObj = {}
  async allMails() {

    console.log('send mail')
    let obj = {}
    obj["ip_mobile"] = this.id;
    var resp = await this.http.post(this._apiHandler.baseURL1 + "allEmails", obj).toPromise();
    console.log(this)
    for (let i = 0; i < resp['data'].length; i++) {
      this.dummy.push(resp['data'][i]['ref_val'])
    }
    console.log(this.dummy, 'dummy')
    // this.dummy.push('vinit.contact@gmail.com')
    this.allMailObj['email'] = this.dummy.join()
    console.log(this.allMailObj, 'allmailobj')
  }
  
  navi1() {
    this.router.navigate(['/melaAllotAdmn']);
  }
  navi(path: string) {
    this.router.navigate([path]);
  }
  back(){
    this.router.navigate(['/allottedApp']);
  }
  cancled_data=[]
  async get_Cancle_Land_Data() {
    this.obj['cancle_amt']=0
    var obj = new Object();
    obj["ip_mobile"] = this.obj['ip_mobile'];
    var resp = await this.http.post(this._apiHandler.baseURL1 + "allCancled_land_Data", obj).toPromise();
    console.log(resp['data'],'cancled data');
    this.cancled_data=resp['data']
    for(let i=0;i<resp['data'].length;i++){
      this.obj['cancle_amt']=this.obj['cancle_amt']+Number(resp['data'][i]['pmnt_amount'])
    }
    this.show_spinner = false;
    console.log(this.obj)
  }
  checkObj={}
  async get_Allot_Data(){
    this.spinner.show()
    var obj = new Object();
    obj["ip_mobile"] = this.id;
    this.alt_data = await this.http.post(this._apiHandler.baseURL1 + "user_all_AllotedLand_2", obj).toPromise();
    console.log(this.alt_data);
    this.alt_data = this.alt_data.data;
    let sum1 = 0 // for current
    let sum2 = 0 // for not paid
    for (let i = 0; i < this.alt_data.length; i++) {
      if (this.alt_data[i]['payment_type'] == 'Payment') {
        if (this.alt_data[i]['isPaid'] == 'YES') {
          sum1 = sum1 + Number(this.alt_data[i]['pmnt_amount'])
        } else {
          sum2 = sum2 + Number(this.alt_data[i]['pmnt_amount'])
        }
      }
      if (sum1 > sum2) {
        this.checkObj['payment'] = sum1 - sum2
        this.checkObj['action'] = 'Pay'
      } else if (sum2 > sum1){
        this.checkObj['payment'] = sum2
        this.checkObj['action'] = 'Pay'
      } else {
        this.checkObj['payment'] = 0
        this.checkObj['action'] = 'Not to Pay & Refund'
      }
      this.pay_amt=this.checkObj['payment']
      console.log(this.checkObj,'check obj')
    this.finaldataSource = new MatTableDataSource(this.alt_data);
    this.show_spinner = false;
    this.spinner.hide()
  }
}

}
