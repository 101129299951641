import { Injectable } from "@angular/core";
import { Router } from '@angular/router';

@Injectable({
  providedIn: "root",
})
export class MobileNumberService {
   selected_service_mob_number;
  constructor(private router:Router) {}

  setMobileNumber(mobileNumber: string) {
    localStorage.setItem("user", btoa(mobileNumber));
  }
  getMobileNumber(): string {
    const mobileNumber: string = atob(localStorage.getItem("user"));
    if(mobileNumber){
    return mobileNumber;
  }
  this.router.navigate(['/auth'])
  }
  clearMobileNumber() {
    localStorage.removeItem("user");
  }
  // setCount(count:string){
  //   localStorage.setItem("userCount",count)
  // }
  // getCount():string{
  //   const count:string = localStorage.getItem("userCount")
  //   return count
  // }
  setRole(role: string, r_c_c ) {
    localStorage.setItem("userRole", role);
    localStorage.setItem("r_c_c", r_c_c);

  }
  getRole(): string {
    const role: string = localStorage.getItem("userRole");
    return role;
  }
}
