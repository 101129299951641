import { Routes } from "@angular/router";
import { DashboardLayoutComponent } from "./dashboard-layout.component";
import { AuthGuard } from 'src/app/auth.guard';
import { CommerceLetterComponent } from "src/app/pages/allotment-letter/commerce-letter/commerce-letter.component";

export const routes: Routes = [
  {
    path: "notice",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/notice-board/notice-board.module").then(
        mod => mod.NoticeboardModule
      )
  },

  {
    path: "registration_form",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/registration-form/registration-form.module").then(
        mod => mod.RegistrationFormModule
      )
  },
  {
    path: "registration_form/:id",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/registration-form/registration-form.module").then(
        mod => mod.RegistrationFormModule
      )
  },
  {
    path: "profile",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/profile/profile.module").then(
        mod => mod.ProfileModule
      )
  },
  {
    path: "finance",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/finance/finance.module").then(
        mod => mod.FinanceModule
      )
  },
  {
    path: "melaHead",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/mela-head/mela-head.module").then(
        mod => mod.MelaHeadModule
      )
  },
  {
    path: "melaAllotAdmn",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/mela-allot-admin/mela-allot-admin.module").then(
        mod => mod.MelaAllotAdminModule
      )
  },
  {
    path: "melaAppAdmn",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/mela-app-admin/mela-app-admin.module").then(
        mod => mod.MelaAppAdminModule
      )
  },
  {
    path: "melaLandAdmn",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/mela-land-admin/mela-land-admin.module").then(
        mod => mod.MelaLandAdminModule
      )
  },
  {
    path: "melaServAdmn",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/mela-serv-admin/mela-serv-admin/mela-serv-admin.module").then(
        mod => mod.MelaServAdminModule
      )
  },
  {
    path: "melaSiteAdmn",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/mela-site-admin/mela-site-admin.module").then(
        mod => mod.MelaSiteAdminModule
      )
  },
  {
    path: "viewApp",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/view-app/view-app.module").then(
        mod => mod.ViewAppModule
      )
  },
  {
    path: "allot/:id",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/allot/allot.module").then(
        mod => mod.AllotModule
      )
  },
  {
    path: "payment/:id",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/payment/payment.module").then(
        mod => mod.PaymentModule
      )
  },
  {
    path: "notAllot",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/not-allot-app/not-allot-app.module").then(
        mod => mod.NotAllotAppModule
      )
  },
  {
    path: "allApp",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/all-app/all-app.module").then(
        mod => mod.AllAppModule
      )
  },
  {
    path: "allottedApp",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/allotted-app/allotted-app.module").then(
        mod => mod.AllottedAppModule
      )
  },
  {
    path: "reqAllotApp",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/re-allot-app/re-allot-app.module").then(
        mod => mod.ReAllotAppModule
      )
  },
  {
    path: "cancelledApp",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/cancelled-app/cancelled-app.module").then(
        mod => mod.CancelledAppModule
      )
  },
  {
    path: "melaSiteAdmin",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/mela-site-admin/mela-site-admin.module").then(
        mod => mod.MelaSiteAdminModule
      )
  },
  {
    path: "melaLandAdmin",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/mela-land-admin/mela-land-admin.module").then(
        mod => mod.MelaLandAdminModule
      )
  },

  {
    path: "master",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/master/master/master.module").then(
        mod => mod.MasterModule
      )
  },

  {
    path: "totalApp",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/total-app/total-app.module").then(
        mod => mod.TotalAppModule
      )
  },
  {
    path: "landSummary",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/land-summary/land-summary.module").then(
        mod => mod.LandSummaryModule
      )
  },
  {
    path: "allotLetter/:id",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/allotment-letter/allotment-letter.module").then(
        mod => mod.AllotmentLetterModule
      )
  },
  {
    path: "allotLetterCommercial/:id",
    component: CommerceLetterComponent,
    loadChildren: () =>
      import("./../../pages/allotment-letter/allotment-letter.module").then(
        mod => mod.AllotmentLetterModule
      )
  }
  ,
  {
    path: "melaServices",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/mela-services/mela-services.module").then(
        mod => mod.MelaServicesModule
      )
  },
  {
    path: "melaServices/:id",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/mela-services/mela-services.module").then(
        mod => mod.MelaServicesModule
      ),
      // canActivate:[AuthGuard],
      // data:{role:'R'}
  },
  {
    path: "partApp",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/partial-app/partial-app.module").then(
        mod => mod.PartialAppModule
      )
  },
  {
    path: "tentServ",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/tent-serv/tent-serv.module").then(
        mod => mod.TentServModule
      )
  },
  {
    path: "tentServ/:id",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/tent-serv/tent-serv.module").then(
        mod => mod.TentServModule
      )
  },
  {
    path: "allotServList",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/allot-serv-list/allot-serv-list.module").then(
        mod => mod.AllotServListModule
      ),
      // canActivate:[AuthGuard],
      // data:{role:'A,R'}

  },
  {
    path: "selectedServList",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/selected-serv-list/selected-serv-list.module").then(
        mod => mod.SelectedServListModule
      )
  },
  {
    path: "giveServices/:id",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/give-services/give-services.module").then(
        mod => mod.GiveServicesModule
      )
  },
  {
    path: "allottedServ",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/allotted-services/allotted-services.module").then(
        mod => mod.AllottedServicesModule
      )
  },
  {
    path: "notAllottedServ",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/not-allotted-services/not-allotted-services.module").then(
        mod => mod.NotAllottedServicesModule
      )
  },
  {
    path: "exemApp",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/exempt-app/exempt-app.module").then(
        mod => mod.ExemptAppModule
      )
  },
  {
    path: "iciciReturn/:txn_id",
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import("./../../pages/icici-return/icici-return.module").then(
        mod => mod.IciciReturnModule
      )
  },

];
