import { Component, OnInit, ViewChildren, QueryList } from "@angular/core";
import { ILocalization } from "src/app/shares/models";
import { LocalizationService } from "src/app/shares/store/localization.service";
import { MelaServicesService } from "../mela-services/mela-services.service";
import { MobileNumberService } from "src/app/shares/store/mobile-number.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import {HttpErrorResponse} from '@angular/common/http'
import { Router , RoutesRecognized } from "@angular/router";
import {NgxSpinnerService} from 'ngx-spinner'
import { ApiHandlerService } from "src/app/shares/services/api-handler.service";

@Component({
  selector: 'app-mas-rep-service',
  templateUrl: './mas-rep-service.component.html',
  styleUrls: ['./mas-rep-service.component.css']
})
export class MasRepServiceComponent implements OnInit {
  localizationData: ILocalization;
  mobile: string;
  allottedList;
  allottedServList;
  totalServReqList;
  inprocessServList;
  orderedServList;
  notAllotServApp;
  allottedListCount=0;
  allottedServCount;
  notAllotServCount;
  allottedDataSource;
  allottedServiceDatasource;
  totalServDataSource;
  totalServCount=0;
  inProcessDataSource;
  inProcessDataCount=0;
  orderdDataSource;
  orderedDataCount=0
  selectedRole:string
  show_spinner : boolean;
  applicant_approved_count;
  applicant_canceled_count;
  applicant_pending_count;
  applicant_total_count;
  applicant_ordered_count;
  applicant_approved_list;
  applicant_canceled_list;
  applicant_pending_list;
  applicant_total_list;
  applicant_ordered_list;
  applicant_approved_list_dataSource;
  applicant_canceled_list_dataSource;
  applicant_pending_list_dataSource;
  applicant_total_list_dataSource;
  applicant_ordered_list_dataSource;
  app_list;
  app_count=0;
  app_dataSource;
  cancel_list;
  cancel_count=0;
  cancel_dataSource;


  displayedColumns: string[] = [
    
    "ip_name",
    "ip_mobile",
    "alloted_sector",
    "alloted_area",
    "apply_service",
  ];
  displayedColumns1: string[] = [
    "servId",
    "ip_name",
    
    "ip_mobile",
    "date_time",
    "status",
    "view"
  ];

  displayedColumns2: string[] = [
    "servId",
    "date_time",
    "status",
    "view"
  ];

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  constructor(
    private _localization: LocalizationService,
    private _melaService: MelaServicesService,
    private _mobileService: MobileNumberService,
    private router: Router,
    private spinner:NgxSpinnerService,
    private _apiHandler:ApiHandlerService,

  ) {}

  ngOnInit(): void {
    this._localization.geti18nData().subscribe((res: ILocalization) => {
      this.localizationData = res;
    });
    this._localization.getLanguage().subscribe((res) => {
      this.getAllottedData();
      console.log(res);
    });
    this.selectedRole=this._mobileService.getRole()
    this.mobile = this._mobileService.getMobileNumber();
    this.getAllottedData();
    this.getTotalSerReq()
    this.getInProgress()
    this.getOrdered();
    this.getAppSer()
  }
  view_service_letter(element){
    const mobile = element['ip_mobile']
    const data = mobile + ".pdf"
    const link = this._apiHandler.baseURL2+"service_letter/"+data
    window.open(link)
  }
  getAllottedData() {

    this.spinner.show()
    this._melaService.getAllottedList2(this.mobile).subscribe(res => {
      this.spinner.hide()
      this.allottedList = res.data;
      this.allottedListCount = res.data.length;
      console.log(this.allottedListCount, this.allottedList);
      this.allottedDataSource = new MatTableDataSource(this.allottedList);
      this.allottedDataSource.paginator = this.paginator.toArray()[0];
      this.allottedDataSource.sort = this.sort.toArray()[0];
    },
    err=>{
      this.spinner.hide()
      if(err instanceof HttpErrorResponse){
        if(err.status===401 || err.status===500){
          this.router.navigate(['/auth'])
        }
      }
    });
  }
  getTotalSerReq(){
    this.spinner.show()
    const mobile = this._mobileService.getMobileNumber()
    this._melaService.getTotalServReq2(mobile).subscribe(res=>{
      console.log(res,'asdfghjkl')
      this.spinner.hide()
      this.totalServReqList = res.total_ser_req_data
      this.totalServCount= res.total_ser_req_count[0].total_req_count
      this.totalServDataSource = new MatTableDataSource(this.totalServReqList);
      this.totalServDataSource.paginator = this.paginator.toArray()[1];
      this.totalServDataSource.sort = this.sort.toArray()[1];

      this.app_count = res.Approved_ser_req_count[0].total_req_count;
      this.app_list = res.Approved_ser_req_data;
      this.app_dataSource = new MatTableDataSource(this.app_list);
      this.app_dataSource.paginator = this.paginator.toArray()[4];
      this.app_dataSource.sort = this.sort.toArray()[4];

      this.cancel_count = res.Cancled_ser_req_count[0].total_req_count;
      this.cancel_list = res.Cancled_ser_req_data;
      this.cancel_dataSource = new MatTableDataSource(this.cancel_list);
      this.cancel_dataSource.paginator = this.paginator.toArray()[5];
      this.cancel_dataSource.sort = this.sort.toArray()[5];
      console.log("total Service",res)
    },
    err=>{
      this.spinner.hide()
      if(err instanceof HttpErrorResponse){
        if(err.status===401 || err.status===500){
          this.router.navigate(['/auth'])
        }
      }
    })
  }
  getInProgress(){
    this.spinner.show()
    const mobile = this._mobileService.getMobileNumber()
    this._melaService.getInProgress2(mobile).subscribe(res=>{
      this.spinner.hide()
      console.log("In progress",res)
      this.inprocessServList=res.inProcess_ser_req_data
      this.inProcessDataCount=res.inProcess_ser_req_count[0].total_req_count
      this.inProcessDataSource = new MatTableDataSource(this.inprocessServList);
      this.inProcessDataSource.paginator = this.paginator.toArray()[2];
      this.inProcessDataSource.sort = this.sort.toArray()[2];
    },
    err=>{
      this.spinner.hide()
      if(err instanceof HttpErrorResponse){
        if(err.status===401 || err.status===500){
          this.router.navigate(['/auth'])
        }
      }
    })
  }
  getOrdered(){
    this.spinner.show()
    const mobile = this._mobileService.getMobileNumber()
    this._melaService.getOrdered2(mobile).subscribe(res=>{
      this.spinner.hide()
      console.log("Ordered",res)
      this.orderedServList=res.ordered_ser_req_data
      this.orderedDataCount=res.ordered_ser_req_count[0].total_req_count
      this.orderdDataSource = new MatTableDataSource(this.orderedServList);
      this.orderdDataSource.paginator = this.paginator.toArray()[3];
      this.orderdDataSource.sort = this.sort.toArray()[3];
    },
    err=>{
      this.spinner.hide()
      if(err instanceof HttpErrorResponse){
        if(err.status===401 || err.status===500){
          this.router.navigate(['/auth'])
        }
      }
    })
  }
  getAppSer(){
    this.spinner.show()
    const mobile = this._mobileService.getMobileNumber()
    this._melaService.getApplicantService(mobile).subscribe(res=>{
      this.spinner.hide()
      console.log("app_service_by_suraj",res);
      this.applicant_approved_list = res.Approved;
      this.applicant_approved_list_dataSource = new MatTableDataSource(this.applicant_approved_list);
      this.applicant_approved_count = res.Approved_count[0].a;
      this.applicant_canceled_list = res.Cancled;
      this.applicant_canceled_list_dataSource = new MatTableDataSource(this.applicant_canceled_list);
      this.applicant_canceled_count = res.Cancled_count[0].a;
      this.applicant_pending_list = res.in_process;
      this.applicant_pending_list_dataSource = new MatTableDataSource(this.applicant_pending_list);
      this.applicant_pending_count = res.in_process_count[0].a;
      this.applicant_ordered_list = res.ordered;
      this.applicant_ordered_list_dataSource = new MatTableDataSource(this.applicant_ordered_list);
      this.applicant_ordered_count = res.ordered_count[0].a;
      this.applicant_total_list = res.total_req;
      this.applicant_total_list_dataSource = new MatTableDataSource(this.applicant_total_list);
      this.applicant_total_count = res.total_req_count[0].a;

      

    },
    err=>{
      this.spinner.hide()
      if(err instanceof HttpErrorResponse){
        if(err.status===401 || err.status===500){
          this.router.navigate(['/auth'])
        }
      }
    })
  }
  allotServFilter(value: string) {
    this.allottedDataSource.filter = value.trim().toLocaleLowerCase();
  }
  
  totalServReqFilter(value: string) {
    this.totalServDataSource.filter = value.trim().toLocaleLowerCase();
  }
  inProcressReqFilter(value: string) {
    this.inProcessDataSource.filter = value.trim().toLocaleLowerCase();
  }
  orderedReqFilter(value: string) {
    this.orderdDataSource.filter = value.trim().toLocaleLowerCase();
  }

  app(value: string) {
    this.app_dataSource.filter = value.trim().toLocaleLowerCase();
  }

  cancel_filter(value: string) {
    this.cancel_dataSource.filter = value.trim().toLocaleLowerCase();
  }

  app_total_filter(value: string){
    this.applicant_total_list_dataSource.filter = value.trim().toLocaleLowerCase();

  }
  app_in_process_filter(value: string){
    this.applicant_pending_list_dataSource.filter = value.trim().toLocaleLowerCase();
    
  }
  app_ordered_filter(value: string){
    this.applicant_ordered_list_dataSource.filter = value.trim().toLocaleLowerCase();
  }
  app_app_filter(value: string){
    this.applicant_approved_list_dataSource.filter = value.trim().toLocaleLowerCase();
  }
  app_cancel_filter(value: string){
    this.applicant_canceled_list_dataSource.filter = value.trim().toLocaleLowerCase();
  }
  
  clickNavi(path, mobile) {
  this._melaService.setMobile(mobile);
  this._melaService.setSerId("")
    this.navi(path);
  }
  clickAllottedServ(path,mobile){
    this._melaService.setMobile(mobile)
    this.navi(path)

  }
  clickNotAllotServ(path,mobile){
    this._melaService.setMobile(mobile)
    this.navi(path)
  }
  clickTotalServ(path,mobile,servId){
    this._melaService.setMobile(mobile)
    this._melaService.setSerId(servId)
    this.navi(path)
  }
  navi(path: string) {
    this.router.navigate([path]);
  }

}
