import {
  Component,
  OnInit,
  QueryList,
  ViewChildren,
  ViewChild,
  ElementRef,
  Inject,
} from "@angular/core";
import { ApiHandlerService } from 'src/app/shares/services/api-handler.service';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { LocalizationService } from "src/app/shares/store/localization.service";
import { ILocalization } from "src/app/shares/models";
import { HttpClient } from "@angular/common/http";
import { MatTableDataSource } from "@angular/material/table";
import { NgxSpinnerService } from 'ngx-spinner'
import "jspdf-autotable";
import { RegistrationFormService } from "../registration-form/registration-form.service";
import { ToastrService } from "ngx-toastr";
import Swal from 'sweetalert2'
import { AuthService } from "../auth/auth.service";
@Component({
  selector: 'app-add-service',
  templateUrl: './add-service.component.html',
  styleUrls: ['./add-service.component.css']
})
export class AddServiceComponent implements OnInit {
  @ViewChild(MatSort) inventoryDataSort: MatSort;
  @ViewChild(MatPaginator) inventoryDataPaginator: MatPaginator;
  @ViewChild('table1', { read: MatPaginator }) table1: MatPaginator;
  @ViewChild('table2', { read: MatPaginator }) table2: MatPaginator;
  @ViewChild('table3', { read: MatPaginator }) table3: MatPaginator;
  displayedColumns: string[] = ['sno', 'name', 'action'];
  displayedColumns2: string[] = ['sno', 'type_desc', 'action']
  displayedColumns3: string[] = ['sno', 'meas', 'count', 'price', 'thr', 'unit', 'action']
  dataSource;
  localizationData: ILocalization;
  constructor(private spinner: NgxSpinnerService,
    private _registrationLandService: RegistrationFormService,
    private _apiHandler: ApiHandlerService,
    private http: HttpClient,
    private _toastrService: ToastrService,
    private authService: AuthService,
    private _localization: LocalizationService,

  ) { }
  cat = [{ id: 0, value: 'General' }, { id: 1, value: 'Reserved' }]
  isActive = [{ value: 'Active' }, { value: 'InActive' }]
  login_mob;
  async ngOnInit() {
    this._localization.geti18nData().subscribe((res: ILocalization) => {
      this.localizationData = res;
    });
    this.login_mob = this.authService.login_mob
    await this.getLevel1Services()

  }
  change1(){
    console.log(this.localizationData.close)

  }
  level1List = []
  level2List = []
  level3List = []
  verifydataSource;
  filter = (value: string) => {
    //("inside filter method");
    this.verifydataSource.filter = value.trim().toLocaleLowerCase();
  };
  filter2 = (value: string) => {
    //("inside filter method");
    this.level2serviceDataSource.filter = value.trim().toLocaleLowerCase();
  };
  filter3 = (value: string) => {
    //("inside filter method");
    this.level3serviceDataSource.filter = value.trim().toLocaleLowerCase();
  };
  set_data_for_element(data){
    this.obj=data
  }
  async getLevel1Services() {
    this.spinner.show()
    this.http.post(this._apiHandler.baseURL2 + 'level1_services', {
      "hi": 'EN'
    }).subscribe(res => {
      console.log(res, 'resp')
      this.spinner.hide()
      if (res['Success'] == 'Ok') {
        console.log(res)
        this.level1List = res['data']
        for (let i = 0; i < this.level1List.length; i++) {
          this.level1List[i]['sno'] = i + 1
        }
        this.verifydataSource = new MatTableDataSource(this.level1List);
        this.verifydataSource.paginator = this.table1
      }
      else {
        this.spinner.hide()
        this._toastrService.error('Server Side Error', "",
          {
            positionClass: "toast-top-center",
            progressBar: true,
          });
      }
    })
  }
  update_level1_item(){
    console.log('level 1')

    console.log(this.obj)
    this.spinner.show()
    this.http.post(this._apiHandler.baseURL1 + 'update_level1_service', {
      'ser_desc': this.obj['ser_desc'],
      'ser_desc_hi': this.obj['ser_desc_hi'],
      'ser_id': this.obj['ser_id']
    }).subscribe(res => {
      console.log(res, 'resp')
      this.spinner.hide()
      if (res['Success'] == 'Ok') {
        console.log(res)
        this.getLevel1Services()
        this._toastrService.success('Successfully Updated', "",
          {
            positionClass: "toast-top-center",
            progressBar: true,
          });
        this.getLevel1Services()
      }
      else {
        this.spinner.hide()
        this._toastrService.error('Server Side Error', "",
          {
            positionClass: "toast-top-center",
            progressBar: true,
          });
      }
    })
  }
  update_level2_item(){
    console.log('level 2')
    console.log(this.obj)
    this.spinner.show()
    this.http.post(this._apiHandler.baseURL1 + 'update_level2_service', {
      'type_desc': this.obj['ser_desc'],
      'type_desc_hi': this.obj['ser_desc_hi'],
      'type_id': this.obj['type_id'],
      'ser_id': this.obj['ser_id']
    }).subscribe(res => {
      console.log(res, 'resp')
      this.spinner.hide()
      if (res['Success'] == 'Ok') {
        console.log(res)
        this.getLevel2Services(this.obj)
        this._toastrService.success('Successfully Updated', "",
          {
            positionClass: "toast-top-center",
            progressBar: true,
          });
      }
      else {
        this.spinner.hide()
        this._toastrService.error('Server Side Error', "",
          {
            positionClass: "toast-top-center",
            progressBar: true,
          });
      }
    })
  }
  set_data_for_level2(element){
    this.obj=element
    this.obj['ser_desc']=element['type_desc']
    this.obj['ser_desc_hi']=element['type_desc_hi']
    console.log(element)
  }
  submitAtLevel1() {
    this.spinner.show()
    this.http.post(this._apiHandler.baseURL2 + 'addServiceAtLevel1', {
      'ser_desc': this.obj['ser_desc'],
      'ser_desc_hi': this.obj['ser_desc_hi']
    }).subscribe(res => {
      console.log(res, 'resp')
      this.spinner.hide()
      if (res['Success'] == 'Ok') {
        console.log(res)
        this._toastrService.success('Successfully Added', "",
          {
            positionClass: "toast-top-center",
            progressBar: true,
          });
        this.getLevel1Services()
      }
      else {
        this.spinner.hide()
        this._toastrService.error('Server Side Error', "",
          {
            positionClass: "toast-top-center",
            progressBar: true,
          });
      }
    })
  }
  submitAtLevel2() {
    this.spinner.show()
    this.http.post(this._apiHandler.baseURL2 + 'addServiceAtLevel2', {
      'ser_desc': this.obj['ser_desc'],
      'ser_desc_hi': this.obj['ser_desc_hi'],
      'ser_id': this.obj['ser_id']
    }).subscribe(res => {
      console.log(res, 'resp')
      this.spinner.hide()
      if (res['Success'] == 'Ok') {
        console.log(res)
        this._toastrService.success('Successfully Added', "",
          {
            positionClass: "toast-top-center",
            progressBar: true,
          });
        this.getLevel2Services(this.obj)
      }
      else {
        this.spinner.hide()
        this._toastrService.error('Server Side Error', "",
          {
            positionClass: "toast-top-center",
            progressBar: true,
          });
      }
    })
  }
  obj = {}
  removeRow(data) {
    Swal.fire({
      title: 'Do you really want to delete ??',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',

    }).then((result) => {
      if (result.value) {
        this.spinner.show()
        this.http.post(this._apiHandler.baseURL2 + 'deleteServiceAtLevel1', {
          "hi": 'EN',
          "ser_id": data['ser_id']
        }).subscribe(res => {
          console.log(res, 'resp')
          this.spinner.hide()
          if (res['Success'] == 'Ok') {
            this.getLevel1Services()
            this._toastrService.success('Successfully Deleted', "",
              {
                positionClass: "toast-top-center",
                progressBar: true,
              });
            this.getLevel1Services()
          }
          else {
            this.spinner.hide()
            this._toastrService.error('Server Side Error', "",
              {
                positionClass: "toast-top-center",
                progressBar: true,
              });
          }
        })
      }
    })
  }

  removeRow2(data) {
    Swal.fire({
      title: 'Do you really want to delete ??',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',
    }).then((result) => {
      if (result.value) {
        this.spinner.show()
        this.http.post(this._apiHandler.baseURL2 + 'deleteServiceAtLevel2', {
          "hi": 'EN',
          "type_id": data['type_id'],
          "ser_id": data['ser_id']
        }).subscribe(res => {
          console.log(res, 'resp')
          this.spinner.hide()
          if (res['Success'] == 'Ok') {
            console.log(res)
            this._toastrService.success('Successfully Deleted', "",
              {
                positionClass: "toast-top-center",
                progressBar: true,
              });
            this.getLevel2Services(data)
          }
          else {
            this.spinner.hide()
            this._toastrService.error('Server Side Error', "",
              {
                positionClass: "toast-top-center",
                progressBar: true,
              });
          }
        })
      }
    })
  }
  removeRow3(data) {
    for (let i = 0; i < this.level3List.length; i++) {
      if (this.level3List[i]['sno'] == data['sno']) {
        this.level3List.splice(i, 1)
      }
    }
    this.level3serviceDataSource = new MatTableDataSource(this.level3List);
    this.level3serviceDataSource.paginator = this.table3
  }
  addRow3() {
    if (this.level3List.length != 0) {
      this.level3List.push({ sno: this.level3List[this.level3List.length - 1]['sno'] + 1, measurement: '', unit: '' })
      console.log(this.level3List)
    } else {
      this.level3List.push({ sno: 1, measurement: '', unit: '' })

    }
    this.level3serviceDataSource = new MatTableDataSource(this.level3List);
    this.level3serviceDataSource.paginator = this.table3
  }
  level2serviceDataSource;
  async getLevel2Services(data) {
    this.obj['ser_id'] = data['ser_id']
    console.log(data)
    this.setData['level2'] = data['ser_desc']
    this.spinner.show()
    this.http.post(this._apiHandler.baseURL2 + 'level2_services', {
      "hi": 'EN',
      "type_id": data['ser_id']
    }).subscribe(res => {
      console.log(res, 'resp')
      this.spinner.hide()
      if (res['Success'] == 'Ok') {
        console.log(res)
        this.level2List = res['data']
        for (let i = 0; i < this.level2List.length; i++) {
          this.level2List[i]['sno'] = i + 1
        }
        this.level2serviceDataSource = new MatTableDataSource(this.level2List);
        this.level2serviceDataSource.paginator = this.table2
      }
      else {
        this.spinner.hide()
        this._toastrService.error('Server Side Error', "",
          {
            positionClass: "toast-top-center",
            progressBar: true,
          });
      }
    })
  }
  level3serviceDataSource;
  async getLevel3Services(data) {
    this.setData['level3'] = data['type_desc']

    this.spinner.show()
    this.http.post(this._apiHandler.baseURL2 + 'level3_services', {
      "hi": 'EN',
      "type_id": data['type_id']
      //  'data':JSON.stringify(this.data2)
    }).subscribe(res => {
      console.log(res, 'resp')
      this.spinner.hide()
      if (res['Success'] == 'Ok') {
        console.log(res)
        this.level3List = res['data']
        for (let i = 0; i < this.level3List.length; i++) {
          this.level3List[i]['sno'] = i + 1
        }

        this.level3serviceDataSource = new MatTableDataSource(this.level3List);
        this.level3serviceDataSource.paginator = this.table3
      }
      else {
        this.spinner.hide()
        this._toastrService.error('Server Side Error', "",
          {
            positionClass: "toast-top-center",
            progressBar: true,
          });
      }
    })
  }
  setData = {}
  setLevel1Data(data) {
    this.setData['level1'] = [data]
    console.log(this.setData)
  }
  setLevel2Data(data) {
    this.setData['level2'] = [data]
    console.log(this.setData)
  }
  setLevel3Data() {
    this.setData['level3'] = this.level3List
  }
  submit() {
    this.spinner.show()
    this.setLevel3Data()
    console.log(this.setData)
    let dummy=[]
    for(let i=0;i<this.setData['level3'].length;i++){
      if(this.setData['level3'][i]['count']){
        dummy.push(this.setData['level3'][i])
      }
    }
    console.log(this.setData['level3'],'level 3')

    this.http.post(this._apiHandler.baseURL2 + 'add_service', {
      'level1': this.setData['level1'],
      'level2': this.setData['level2'],
      'level3': dummy
    }).subscribe(res => {
      this._toastrService.success('Submitted Successfully', "",
      {
        positionClass: "toast-top-center",
        progressBar: true,
      });
      this.spinner.hide()
      if (res['Success'] == 'Ok') {
        console.log(res)
        this.getLevel1Services()
        this.spinner.hide()
      }
      else {
        this.spinner.hide()
        this._toastrService.error('Server Side Error', "",
          {
            positionClass: "toast-top-center",
            progressBar: true,
          });
      }
    })
  }
}