<div *ngIf="selectedRole === 'MR'">
    <mat-card>
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <div class="container">
                        <div class="row">
                            <div class="col-sm">
                                {{ localizationData.land }} {{ localizationData.alloted_app }} </div>
                            <div class="col-sm">
                                {{ allottedListCount }} </div>
                        </div>
                    </div>

                </mat-expansion-panel-header>
                <mat-form-field fxFlex="40%">
                    <input matInput type="text" (keyup)="allotServFilter($event.target.value)" placeholder="Filter" />
                </mat-form-field>
                <table mat-table [dataSource]="allottedDataSource" matSort>
                    <ng-container matColumnDef="ip_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.applicant }} {{ localizationData.name }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.ip_name }}</td>
                    </ng-container>

                    <ng-container matColumnDef="ip_mobile">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.mobile_number }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.ip_mobile }}</td>
                    </ng-container>
                   
                    <ng-container matColumnDef="alloted_sector">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.alloted_sec }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.alloted_sector }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="alloted_area">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.alloted_area }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.alloted_area }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="apply_service">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ localizationData.action }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div>
                                <button class="btn btn-primary" (click)="clickNavi('/melaServices/', element.ip_mobile)">
                                    {{ localizationData.apply_service }}
                                </button>
                                <div *ngIf="element.isService_ltr_generated == 1">
                                    <button mat-raised-button (click)="view_service_letter(element)" color="primary">
                                       Download Service Letter
                                    </button>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
                <div>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20, 50]"></mat-paginator>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <div class="container">
                        <div class="row">
                            <div class="col-sm">
                                {{ localizationData.total_ser_req }} </div>
                            <div class="col-sm">
                                {{ totalServCount }} </div>
                        </div>
                    </div>
                </mat-expansion-panel-header>
                <mat-form-field fxFlex="40%">
                    <input matInput type="text" (keyup)="totalServReqFilter($event.target.value)"
                        placeholder="Filter" />
                </mat-form-field>
                <table mat-table [dataSource]="totalServDataSource" matSort>
                    <ng-container matColumnDef="servId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.serv_req_id }}
                        </th>
                        <td mat-cell *matCellDef="let element" style="text-align: center;">
                            {{ element.ser_id }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="ip_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.applicant }} {{ localizationData.name }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.ip_name }}</td>
                    </ng-container>

                    <ng-container matColumnDef="ip_mobile">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.applicant }} {{ localizationData.mobile }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.applicant_mobile }}
                        </td>
                    </ng-container>
                   
                    <ng-container matColumnDef="date_time">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.date_time }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.timestamp }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.status }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.status }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="view">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ localizationData.action }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <button class="btn btn-primary" (click)="
                  clickTotalServ(
                    '/selectedServList',
                    element.applicant_mobile,
                    element.ser_id
                  )
                ">
                                {{ localizationData.view }}
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
                </table>
                <div>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20, 50]"></mat-paginator>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <div class="container">
                        <div class="row">
                            <div class="col-sm">
                                {{ localizationData.inProgress }} {{localizationData.serv_req}} </div>
                            <div class="col-sm">
                                {{ inProcessDataCount }} </div>
                        </div>
                    </div>
                </mat-expansion-panel-header>
                <mat-form-field fxFlex="40%">
                    <input matInput type="text" (keyup)="inProcressReqFilter($event.target.value)"
                        placeholder="Filter" />
                </mat-form-field>
                <table mat-table [dataSource]="inProcessDataSource" matSort>
                    <ng-container matColumnDef="servId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.serv_req_id }}
                        </th>
                        <td mat-cell *matCellDef="let element" style="text-align: center;">
                            {{ element.ser_id }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="ip_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.applicant }} {{ localizationData.name }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.ip_name }}</td>
                    </ng-container>

                    <ng-container matColumnDef="ip_mobile">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.applicant }} {{ localizationData.mobile }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.applicant_mobile }}
                        </td>
                    </ng-container>
                   
                    <ng-container matColumnDef="date_time">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.date_time }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.timestamp }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.status }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.status }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="view">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ localizationData.action }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <button class="btn btn-primary" (click)="
                  clickTotalServ(
                    '/selectedServList',
                    element.applicant_mobile,
                    element.ser_id
                  )
                ">
                                {{ localizationData.view }}
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
                </table>
                <div>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20, 50]"></mat-paginator>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <div class="container">
                        <div class="row">
                            <div class="col-sm">
                                {{ localizationData.ordered_request }} {{localizationData.serv_req}} </div>
                            <div class="col-sm">
                                {{ orderedDataCount }} </div>
                        </div>
                    </div>
                </mat-expansion-panel-header>
                <mat-form-field fxFlex="40%">
                    <input matInput type="text" (keyup)="orderedReqFilter($event.target.value)" placeholder="Filter" />
                </mat-form-field>
                <table mat-table [dataSource]="orderdDataSource" matSort>
                    <ng-container matColumnDef="servId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.serv_req_id }}
                        </th>
                        <td mat-cell *matCellDef="let element" style="text-align: center;">
                            {{ element.ser_id }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="ip_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.applicant }} {{ localizationData.name }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.ip_name }}</td>
                    </ng-container>

                    <ng-container matColumnDef="ip_mobile">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.applicant }} {{ localizationData.mobile }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.applicant_mobile }}
                        </td>
                    </ng-container>
                   
                    <ng-container matColumnDef="date_time">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.date_time }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.timestamp }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.status }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.status }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="view">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ localizationData.action }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <button class="btn btn-primary" (click)="
                clickTotalServ(
                  '/selectedServList',
                  element.applicant_mobile,
                  element.ser_id
                )
              ">
              {{ localizationData.view }} & {{localizationData['update']}}
                            </button>

                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
                </table>
                <div>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20, 50]"></mat-paginator>
                </div>
            </mat-expansion-panel>
            <!-- =============================== -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <div class="container">
                        <div class="row">
                            <div class="col-sm">
                                {{localizationData.app_ser_req}} </div>
                            <div class="col-sm">
                                {{ app_count }} </div>
                        </div>
                    </div>
                </mat-expansion-panel-header>
                <mat-form-field fxFlex="40%">
                    <input matInput type="text" (keyup)="app($event.target.value)" placeholder="Filter" />
                </mat-form-field>
                <table mat-table [dataSource]="app_dataSource" matSort>
                    <ng-container matColumnDef="servId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.serv_req_id }}
                        </th>
                        <td mat-cell *matCellDef="let element" style="text-align: center;">
                            {{ element.ser_id }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="ip_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.applicant }} {{ localizationData.name }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.ip_name }}</td>
                    </ng-container>

                    <ng-container matColumnDef="ip_mobile">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.applicant }} {{ localizationData.mobile }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.applicant_mobile }}
                        </td>
                    </ng-container>
                   
                    <ng-container matColumnDef="date_time">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.date_time }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.timestamp }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.status }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.status }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="view">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ localizationData.action }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <button class="btn btn-primary" (click)="
                clickTotalServ(
                  '/selectedServList',
                  element.applicant_mobile,
                  element.ser_id
                )
              ">
                                {{ localizationData.view }}
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
                </table>
                <div>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20, 50]"></mat-paginator>
                </div>
            </mat-expansion-panel>
            <!-- =========================== -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <div class="container">
                        <div class="row">
                            <div class="col-sm">
                                {{localizationData.cancelled_ser_req}} </div>
                            <div class="col-sm">
                                {{ cancel_count }} </div>
                        </div>
                    </div>
                </mat-expansion-panel-header>
                <mat-form-field fxFlex="40%">
                    <input matInput type="text" (keyup)="cancel_filter($event.target.value)" placeholder="Filter" />
                </mat-form-field>
                <table mat-table [dataSource]="cancel_dataSource" matSort>
                    <ng-container matColumnDef="servId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.serv_req_id }}
                        </th>
                        <td mat-cell *matCellDef="let element" style="text-align: center;">
                            {{ element.ser_id }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="ip_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.applicant }} {{ localizationData.name }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.ip_name }}</td>
                    </ng-container>

                    <ng-container matColumnDef="ip_mobile">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.applicant }} {{ localizationData.mobile }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.applicant_mobile }}
                        </td>
                    </ng-container>
                  
                    <ng-container matColumnDef="date_time">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.date_time }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.timestamp }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ localizationData.status }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.status }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="view">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ localizationData.action }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <button class="btn btn-primary" (click)="
                clickTotalServ(
                  '/selectedServList',
                  element.applicant_mobile,
                  element.ser_id
                )
              ">
                                {{ localizationData.view }}
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
                </table>
                <div>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20, 50]"></mat-paginator>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-card>
</div>
<ngx-spinner>Loading...</ngx-spinner>