import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
  AfterViewInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient,HttpErrorResponse } from "@angular/common/http";
import { AuthService } from "../auth/auth.service";
import { ILogin, Datum } from "../auth/models/login.model";
import { MatTableDataSource } from "@angular/material/table";
import { LocalizationService } from "src/app/shares/store/localization.service";
import { ILocalization } from "src/app/shares/models";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MobileNumberService } from "src/app/shares/store/mobile-number.service";
import { ApiHandlerService } from 'src/app/shares/services/api-handler.service';
import {NgxSpinnerService} from 'ngx-spinner'
import { element } from "protractor";
import { ToastrService } from "ngx-toastr";
import html2pdf from 'html2pdf.js'
import Swal from "sweetalert2";
import { MelaServicesService } from "../mela-services/mela-services.service";

@Component({
  selector: 'app-mas-rep-application',
  templateUrl: './mas-rep-application.component.html',
  styleUrls: ['./mas-rep-application.component.css']
})
export class MasRepApplicationComponent implements OnInit {

  localizationData: ILocalization;
  
  ip_mob;
  allCount=0;
  allList;
  partCount=0;
  partList;
  comCount=0;
  comList;
  dataSource;
  partDataSource;
  comDataSource;
  isViewAppShow: boolean = false;
  show_spinner : boolean;
  //displayedColumns: string[] = ['ip_name', 'ip_address', 'ip_mobile', 'ip_email'];
  displayedColumnsa: string[] = [
    "ip_name",
    "ip_address",
    "ip_mobile",
    "rep_id",
    "ip_email",
    "details",
  ];
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  set_link : string;
  pdf : string = '.pdf';
  link : string = this._apiHandler.baseURL2+"exam_file/";

  constructor(
    private http: HttpClient,
    private router: Router,
    private _authService: AuthService,
    private _localization: LocalizationService,
    private _mobileService: MobileNumberService,
    private _apiHandler:ApiHandlerService,
    private spinner:NgxSpinnerService,
    private _toastr:ToastrService,
    private _melaService:MelaServicesService
  ) {}
  today;
  ngOnInit(): void {
    this._localization.geti18nData().subscribe((res: ILocalization) => {
      this.localizationData = res;
    });
    let date = new Date()
    this.today = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()

    this.ip_mob = this._mobileService.getMobileNumber();
    //console.log(this.ip_mob);
    this.getAll();
    this.getPart();
    this.getCom();
  }
 async setReallotData(element){
    // this.reAllotObj=element
    this.spinner.show()
    var obj = new Object();
    // obj["ip_mobile"] = this.userData[0].ip_mobile;
    obj["ip_mobile"] = element['ip_mobile']
    // console.log(this.userData[0])
    var resp = await this.http
      .post(this._apiHandler.baseURL1 + "applicant_status_desc", obj)
      .toPromise();
      console.log(resp['data'][0])
    this.reAllotObj=resp['data'][0]
        this.reAllotObj['req_sec_desc']=this.reAllotObj['req_sector']
    this.reAllotObj['alloted_Sec']=this.reAllotObj['alloted_sector']
    this.reAllotObj['alloted_area_desc']=this.reAllotObj['alloted_area']
    this.reAllotObj['area_alloted']=this.reAllotObj['total_area_alloted']

    
    this.spinner.hide()
  }
  async submitRequestForReAlot(){
    this.spinner.show()
    var obj = new Object();
    obj["ip_mobile"] = this.reAllotObj['ip_mobile'];
    obj["re_alt_desc"] = this.reAllotObj['re_alt_desc']
    obj['afterPayment']='YES'
    console.log(obj);
    var resp = await this.http
      .post(this._apiHandler.baseURL1 + "re_allotment", obj)
      .toPromise();
      if(resp !== null){
        this.spinner.hide()
        this._toastr.success(
          "Successfully Applied"
        )
      }
  }
  // async getData(data) {
   
  // }
  public deleteUser = (data) => {
    console.log(data);
    Swal.fire({
      title: 'Are You Sure',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',

    }).then((result) => {
      if (result.value) {
        this.delete(data);
      }
    })
  }
 
  async delete(data) {
    this.spinner.show()
    var obj = new Object();
    obj["ip_mobile"] = data['ip_mobile'];
    obj["role"] ='A';
    console.log(obj);
  var resp= await this.http
      .post(this._apiHandler.baseURL1 + "delete_roles", obj)
      .toPromise();
    this._toastr.success(this.localizationData.deleted, "", {
      positionClass: "toast-top-center",
      progressBar: true,
    });
    this.getPart()
    this.getAll()
    this.spinner.hide()
   
    

  }
  print_data={}
  downloadPdf(data) {
    this.spinner.show()
    this.print_data=Object.assign({},data)
    this.print_data['id']=data['id']
    console.log(this.print_data)
    let f;
    setTimeout( () => {
      
      let p = html2pdf()
      .from(document.getElementById('obrz'))
      .set({
        margin: [30, 0, 30, 0],
        filename: data['ip_mobile']+'.pdf',
        image: { type: 'jpeg', quality: 0.99 },
        html2canvas: {
          dpi: 100, scale: 4, scrollX: 0, scrollY: 0,

          letterRendering: true,
          useCORS: true
        },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'p' },
        pagebreak: { before: '.page-break', avoid: 'img' },

      })
      .toPdf()
      .get('pdf').then((pdf) => {
        this.spinner.hide()
        // this.generateLetter(finalFile)
        // var totalPages = pdf.internal.getNumberOfPages();
        // for (let i = 1; i <= totalPages; i++) {
        //   pdf.setPage(i);
        //   pdf.setFontSize(10);
        //   pdf.setTextColor(150);
        //   // pdf.text('Page ' + i + ' of ' + totalPages, pdf.internal.pageSize.getWidth() - 100, pdf.internal.pageSize.getHeight() - 10);
        // } 
      }).save();
    }, 1100 )
    

    // //.log(formData)
  }
  cancleSwal(){
    Swal.fire('Warning..','This Application is cancled..!You Can Contact to Mela Administration','warning')
  }
  async cancle_Your_Application(data) {
    Swal.fire({
      title: 'Are You Sure',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Cancle it!',

    }).then((result) => {
      if (result.value) {
        this.spinner.show()
        this.http.post(this._apiHandler.baseURL1 + 'cancleApplication', {
          'ip_mobile': data['ip_mobile'],
         
        }).subscribe(res => {
          console.log(res, 'resp')
          if (res['Success'] == 'Ok') {
            this.ngOnInit()
            this._toastr.success('This Application cancled', "",
              {
                positionClass: "toast-top-center",
                progressBar: true,
              });

          } else {
            this._toastr.error('Some Error Occured At Server Side', "",
              {
                positionClass: "toast-top-center",
                progressBar: true,
              });
          }
          this.spinner.hide()
        })
      }
    })
  }
  async getAll() {
this.spinner.show()
    var obj = new Object();
    obj["rep_id"] = this.ip_mob;
    console.log(obj);
    // ==========================
    // this.allCount = await this.http
    //   .post(this._apiHandler.baseURL1 + "rep_data_count", obj)
    //   .toPromise();
    // this._authService.setTotalApp(this.allCount);
    // this.allCount = this.allCount.data[0].a;
    // console.log(this.allCount);
    // ========================
    // this.http.post(this._apiHandler.baseURL1 + "rep_data_count",{
    //   rep_id:this.ip_mob
    // }).subscribe(res=>{
    //   this.spinner.hide()
    //   console.log(res),this.allCount=res
    // },
    // err=>{
    //   this.spinner.hide()
    //   if(err instanceof HttpErrorResponse){
    //     if(err.status==401 || err.status==500){
    //       this.router.navigate(['/auth'])
    //     }
    //   }
    // })

    // ==========
    this.allList = await this.http
      .post(this._apiHandler.baseURL1 + "master_rep_AllData", obj)
      .toPromise();
    console.log(this.allList);
    this.allList = this.allList.data;
    // this.allCount=this.allList.lengt
    if(this.allList){
      this.spinner.hide() 
      let len=this.allList.length
      console.log(len,'length')
      this.allCount=len
      this._authService.setTotalApp(len);
  
      this.dataSource = new MatTableDataSource(this.allList);
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.dataSource.sort = this.sort.toArray()[0];
    }else{
      this.spinner.hide()
      this._toastr.error('Error While Getting all applications')
    }
   
  }
  view_service_letter(element){
    const mobile = element['ip_mobile']
    const data = mobile + ".pdf"
    const link = this._apiHandler.baseURL2+"service_letter/"+data
    window.open(link)
  }
  reAllotObj={}
  reAllotObj2={}
 async setReAllotData(element){
   console.log('called')
     this.reAllotObj=element
     console.log(element)
    //  this.spinner.show()
    //  var obj = new Object();
    //  obj["ip_mobile"] = element['ip_mobile']
    //  // console.log(this.userData[0])
    //  var resp = await this.http
    //    .post(this._apiHandler.baseURL1 + "applicant_status_desc", obj)
    //    .toPromise();
    //    console.log(resp,'dgsakjytuew')
    //  this.reAllotObj2=resp['data']
    // //      this.reAllotObj2['req_sec_desc']=this.reAllotObj2['req_sector']
    // //  this.reAllotObj2['alloted_Sec']=this.reAllotObj2['alloted_sector']
    // //  this.reAllotObj2['alloted_area_desc']=this.reAllotObj2['alloted_area']
    // //  this.reAllotObj2['area_alloted']=this.reAllotObj2['total_area_alloted']
 
    //  console.log(this.reAllotObj,'ewyudgshj')
    //  this.spinner.hide()
  }

  async sendForRe_allot() {
    this.spinner.show()
  

  if(this.reAllotObj['re_alt_desc']){
    this.spinner.show()
    var obj = new Object();
    obj["ip_mobile"] = this.reAllotObj['ip_mobile']
    obj["re_alt_desc"] = this.reAllotObj['re_alt_desc']
    console.log(obj);
  var resp = await this.http
      .post(this._apiHandler.baseURL1 + "re_allotment", obj)
      .toPromise();
      if(resp!== null){
        this.getAll();
        this.getPart();
        this.getCom();
        this.spinner.hide()
        this._toastr.success(
          "Successfully Applied For Re-Allotment"
          )
      }
      
      this.spinner.hide()
  }else{
    this.spinner.hide()
    this._toastr.error(
      "Please Enter the reason for re-allotment"
      )
  }
    
  }
  
  async getPart() {
this.spinner.show()
    var obj = new Object();
    
    this.partList = await this.http
      .post(this._apiHandler.baseURL1 + "rep_data_2_for_msr", obj)
      .toPromise();
    console.log(this.partList);
    if(this.partList){
      this.spinner.hide()
      this.partList = this.partList.data;
      this.partCount=this.partList.length
      this.partDataSource = new MatTableDataSource(this.partList);
      this.partDataSource.paginator = this.paginator.toArray()[1];
      this.partDataSource.sort = this.sort.toArray()[1];
    }else
    {
      this._toastr.error('Error While Getting partial applications')
    }

  }
  clickNavi(path, mobile) {
    this._melaService.setMobile(mobile);
    this._melaService.setSerId("")
      this.navi(path);
    }
  async getCom() {
this.spinner.show()
    var obj = new Object();
    obj["rep_id"] = this.ip_mob;
    this.comList = await this.http
      .post(this._apiHandler.baseURL1 + "rep_data_3_for_msr", obj)
      .toPromise();
    console.log(this.comList);
    if(this.comList){
      this.spinner.hide()
      this.comCount=this.comList['data'].length;
    this.comList = this.comList.data;
    this.comDataSource = new MatTableDataSource(this.comList);
    this.comDataSource.paginator = this.paginator.toArray()[2];
    this.comDataSource.sort = this.sort.toArray()[2];
    }else
    {
      this.spinner.hide()
      this._toastr.error('Error While Getting completed application')
    }
  }
  file_status_data={}
  file_status_flag:boolean=false
  set_file_status_flag(element){
    this.file_status_data=Object.assign({},element)
    this.file_status_flag=true
    setTimeout(()=>{ 
      this.file_status_flag=false
      console.log(this.file_status_flag)
    }, 10000);
  }
  getDocument(){
    this.spinner.show()
    this.set_link = this.link +this.file_status_data['ip_mobile'];
    console.log(this.set_link);
    const link = `${this.set_link}${this.pdf}`;
    this.spinner.hide()     
    window.open(link);

  }
  redirectToIciciPage(element){
    this.router.navigate(['/iciciReturn/'+element['txn_id']]);
  }
  comFilter = (value: string) => {
    console.log("inside filter method");
    this.comDataSource.filter = value.trim().toLocaleLowerCase();
  };
  partFilter = (value: string) => {
    console.log("inside filter method");
    this.partDataSource.filter = value.trim().toLocaleLowerCase();
  };
  totalFilter = (value: string) => {
    console.log("inside filter method");
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  };
  allotLetter(mobile){
    const data = mobile + ".pdf"
    
    const link = this._apiHandler.baseURL2+"allot_ltr/"+data
    // console.log(link)
    window.open(link)
  }
  navi(path: string) {
    this.router.navigate([path]);
  }

}
