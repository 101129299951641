<br>
<ngx-spinner>Loading...</ngx-spinner>
<div class="row" style="width: 80%; margin-left: 10%; background-color: white;">
    <div class="col-12">
        <div>
            <mat-form-field fxFlex="40%">
                <input matInput type="text" (keyup)="filter($event.target.value)" placeholder="Filter">
            </mat-form-field>
        </div>
        <div class="row">
            <div class="col-12 text-right">
                <button  data-toggle="modal"
                class="btn btn-success" style="font-size: 20px;"
                data-target="#exampleModalPreview6" class="btn btn-primary text-right"> <i class="fa fa-plus"
                        aria-hidden="true"></i></button>
            </div>
        </div>
        <br>
        <table mat-table [dataSource]="verifydataSource" matSort class="mat-elevation-z8">
            <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef> {{localizationData['s_no']}} </th>
                <td mat-cell *matCellDef="let element"> {{element.ser_id}} </td>
            </ng-container>



            <!-- Symbol Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> {{localizationData['service_name']}} </th>
                <td mat-cell *matCellDef="let element">
                    <input *ngIf="localizationData['language']=='english'" disabled type="text" class="form-control" [(ngModel)]="element['ser_desc']" name="" id="">
                    <input *ngIf="localizationData['language']=='hindi'" disabled  type="text" class="form-control" [(ngModel)]="element['ser_desc_hi']" name="" id="">
                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> {{localizationData['action']}}</th>
                <td mat-cell *matCellDef="let element">
                    <button (click)="getLevel2Services(element) && setLevel1Data(element)" data-toggle="modal"
                        class="btn btn-success" style="font-size: 20px;"
                        data-target="#exampleModalPreview">{{localizationData['select']}}</button>
                   
                        <button data-toggle="modal"
                        class="btn btn-primary" style="font-size: 20px;"
                        data-target="#exampleModalPreview2" (click)="set_data_for_element(element)">{{localizationData['update']}}</button>

                        <button class="btn btn-danger" (click)="removeRow(element)"> {{localizationData['delete']}} </button>
    
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div>
            <mat-paginator #table1 [pageSizeOptions]="[10, 20]"></mat-paginator>
        </div>
    </div>
</div>


<div class="modal fade right" id="exampleModalPreview" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalPreviewLabel" aria-hidden="true">
    <div class="modal-dialog-full-width modal-dialog momodel modal-fluid" role="document">
        <div class="modal-content-full-width modal-content ">
            <div class=" modal-header-full-width   modal-header text-center">
                <h2 class="modal-title w-100" id="exampleModalPreviewLabel">{{localizationData['allService']}} 2</h2>
                <button type="button" class="close " data-dismiss="modal" aria-label="Close">
                    <span style="font-size: 1.3em;" aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="col-12">
                    <div>
                        <mat-form-field fxFlex="40%">
                            <input matInput type="text" (keyup)="filter2($event.target.value)" placeholder="Filter">
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <div class="col-12 text-right">
                            <button  data-toggle="modal"
                            class="btn btn-success" style="font-size: 20px;"
                            data-target="#exampleModalPreview5" class="btn btn-primary text-right"> <i class="fa fa-plus"
                                    aria-hidden="true"></i></button>
                        </div>
                    </div>
                    <br>
                    <table mat-table [dataSource]="level2serviceDataSource" matSort class="mat-elevation-z8">
                        <ng-container matColumnDef="sno">
                            <th mat-header-cell *matHeaderCellDef>{{localizationData['s_no']}} </th>
                            <td mat-cell *matCellDef="let element"> {{element.sno}} </td>
                        </ng-container>
                        <ng-container matColumnDef="type_desc">
                            <th mat-header-cell *matHeaderCellDef>{{localizationData['service_name']}} </th>
                            <td mat-cell *matCellDef="let element">
                                <input type="text" *ngIf="localizationData['language']=='english'" disabled class="form-control" [(ngModel)]="element['type_desc']" name=""
                                    id="">
                                    <input type="text" *ngIf="localizationData['language']=='hindi'" disabled class="form-control" [(ngModel)]="element['type_desc_hi']" name=""
                                    id="">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef>{{localizationData['action']}}</th>
                            <td mat-cell *matCellDef="let element">
                                <button data-toggle="modal" class="btn btn-success" style="font-size: 20px;"
                                    data-target="#exampleModalPreview3"
                                    (click)="getLevel3Services(element) && setLevel2Data(element)">{{localizationData['select']}}</button>
                                    <button data-toggle="modal" class="btn btn-primary" style="font-size: 20px;"
                                    data-target="#exampleModalPreview10"
                                    (click)="set_data_for_level2(element)">{{localizationData['update']}}</button>
                                <button class="btn btn-danger" (click)="removeRow2(element)"> {{localizationData['delete']}}</button>
                            </td>
                        </ng-container>






                        <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
                    </table>
                    <div>
                        <mat-paginator #table2 [pageSize]="10"></mat-paginator>
                    </div>
                </div>

            </div>
            <div class="modal-footer-full-width  modal-footer">
                <button type="button" class="btn btn-danger btn-md btn-rounded" data-dismiss="modal">{{localizationData['close']}}</button>
                <!-- <button type="button" class="btn btn-primary btn-md btn-rounded">Save changes</button> -->
            </div>
        </div>
    </div>
</div>


<div class="modal fade right" id="exampleModalPreview3" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalPreviewLabel" aria-hidden="true">
    <div class="modal-dialog-full-width modal-dialog momodel modal-fluid" role="document">
        <div class="modal-content-full-width modal-content ">
            <div class=" modal-header-full-width   modal-header text-center">
                <h2 class="modal-title w-100" id="exampleModalPreviewLabel">{{localizationData['allService']}} 3 </h2>
                <button type="button" class="close " data-dismiss="modal" aria-label="Close">
                    <span style="font-size: 1.3em;" aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="col-12">
                    <div>
                        <mat-form-field fxFlex="40%">
                            <input matInput type="text" (keyup)="filter3($event.target.value)" placeholder="Filter">
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <div class="col-12 text-right">
                            <button (click)="addRow3()" class="btn btn-primary text-right"> <i class="fa fa-plus"
                                    aria-hidden="true"></i></button>
                        </div>
                    </div>
                    <br>
                    <table mat-table [dataSource]="level3serviceDataSource" matSort class="mat-elevation-z8">
                        <ng-container matColumnDef="sno">
                            <th mat-header-cell *matHeaderCellDef>{{localizationData['s_no']}} </th>
                            <td mat-cell *matCellDef="let element"> {{element.sno}} </td>
                        </ng-container>
                        <ng-container matColumnDef="meas">
                            <th mat-header-cell *matHeaderCellDef>{{localizationData['measurement']}}</th>
                            <td mat-cell *matCellDef="let element">
                                <input type="text" name="" id="" [(ngModel)]="element['measurement']"
                                    class="form-control">

                            </td>
                        </ng-container>
                        <ng-container matColumnDef="count">
                            <th mat-header-cell *matHeaderCellDef>{{localizationData['count']}} </th>
                            <td mat-cell *matCellDef="let element">
                                <input type="number" name="" id="" [(ngModel)]="element['count']" class="form-control">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="price">
                            <th mat-header-cell *matHeaderCellDef>{{localizationData['price']}} </th>
                            <td mat-cell *matCellDef="let element">
                                <input type="number" name="" id="" [(ngModel)]="element['price']" class="form-control">

                            </td>
                        </ng-container>
                        <ng-container matColumnDef="thr">
                            <th mat-header-cell *matHeaderCellDef>{{localizationData['threshold']}} </th>
                            <td mat-cell *matCellDef="let element">
                                <input type="number" name="" id="" [(ngModel)]="element['threshold']"
                                    class="form-control">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="unit">
                            <th mat-header-cell *matHeaderCellDef>{{localizationData['unit']}} </th>
                            <td mat-cell *matCellDef="let element">
                                <input type="text" name="" id="" [(ngModel)]="element['unit']" class="form-control">

                            </td>
                        </ng-container>
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef>{{localizationData['action']}} </th>
                            <td mat-cell *matCellDef="let element">
                                <button class="btn btn-danger" (click)="removeRow3(element)"> <i class="fa fa-trash"
                                        aria-hidden="true"></i></button>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns3;"></tr>
                    </table>
                    <div>
                        <mat-paginator #table3 [pageSize]="10"></mat-paginator>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-12 text-center">
                            <button (click)="submit()" class="btn btn-success">{{localizationData['submit']}}</button>
                        </div>
                    </div>
                </div>
            </div>


            <div class="modal-footer-full-width  modal-footer">
                <button type="button" class="btn btn-danger btn-md btn-rounded" data-dismiss="modal">{{localizationData['close']}}</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalPreview6" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLabel">
                    <h1>{{localizationData['service']}}</h1>
                </h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
               <div class="row">
                   <table class="table table-bordered" style="width: 80%; margin-left: 10%;">
                       <thead>
                           <tr>
                            <th>{{localizationData['s_no']}}</th>
                            <th>{{localizationData['service_desc']}}</th>
                            <th>{{localizationData['service_desc_hi']}}</th>
                        </tr>
                       </thead>
                       <tbody>
                           <tr>
                            <td>1</td>
                            <td>
                                <input type="text" class="form-control" [(ngModel)]="obj['ser_desc']" name="" id="">
                            </td>
                            <td>
                                <input type="text" class="form-control" [(ngModel)]="obj['ser_desc_hi']" name="" id="">
                            </td>
                        </tr>
                       </tbody>
                   </table>
               </div>
               <br>
               <br>
               <div class="row">
                   <div class="col-12 text-center">
                    <button (click)="submitAtLevel1()"  data-dismiss="modal" class="btn btn-primary">{{localizationData['submit']}}</button>
                   </div>
               </div>
               <br>
               <br>

                <div class="modal-footer">
                   
                    <button type="button" class="btn btn-danger" style="font-size: 16px;" data-dismiss="modal">
                        {{localizationData['close']}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalPreview2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLabel">
                    <h1>{{localizationData['service']}}</h1>
                </h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
               <div class="row">
                   <table class="table table-bordered" style="width: 80%; margin-left: 10%;">
                       <thead>
                           <tr>
                            <th>{{localizationData['s_no']}}</th>
                            <th>{{localizationData['service_desc']}}</th>
                            <th>{{localizationData['service_desc_hi']}}</th>
                        </tr>
                       </thead>
                       <tbody>
                           <tr>
                            <td>1</td>
                            <td>
                                <input type="text" class="form-control" [(ngModel)]="obj['ser_desc']" name="" id="">
                            </td>
                            <td>
                                <input type="text" class="form-control" [(ngModel)]="obj['ser_desc_hi']" name="" id="">
                            </td>
                        </tr>
                       </tbody>
                   </table>
               </div>
               <br>
               <br>
               <div class="row">
                   <div class="col-12 text-center">
                    <button (click)="update_level1_item()"  data-dismiss="modal" class="btn btn-primary">{{localizationData['update']}}</button>
                   </div>
               </div>
               <br>
               <br>

                <div class="modal-footer">
                   
                    <button type="button" class="btn btn-danger" style="font-size: 16px;" data-dismiss="modal">
                        {{localizationData['close']}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalPreview10" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLabel">
                    <h1>{{localizationData['service']}}</h1>
                </h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
               <div class="row">
                   <table class="table table-bordered" style="width: 80%; margin-left: 10%;">
                       <thead>
                           <tr>
                            <th>{{localizationData['s_no']}}</th>
                            <th>{{localizationData['service_desc']}}</th>
                            <th>{{localizationData['service_desc_hi']}}</th>
                        </tr>
                       </thead>
                       <tbody>
                           <tr>
                            <td>1</td>
                            <td>
                                <input type="text" class="form-control" [(ngModel)]="obj['ser_desc']" name="" id="">
                            </td>
                            <td>
                                <input type="text" class="form-control" [(ngModel)]="obj['ser_desc_hi']" name="" id="">
                            </td>
                        </tr>
                       </tbody>
                   </table>
               </div>
               <br>
               <br>
               <div class="row">
                   <div class="col-12 text-center">
                    <button (click)="update_level2_item()"  data-dismiss="modal" class="btn btn-primary">{{localizationData['update']}}</button>
                   </div>
               </div>
               <br>
               <br>

                <div class="modal-footer">
                   
                    <button type="button" class="btn btn-danger" style="font-size: 16px;" data-dismiss="modal">
                        {{localizationData['close']}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalPreview5" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLabel">
                    <h1>{{localizationData['service']}}</h1>
                </h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
               <div class="row">
                   <table class="table table-bordered" style="width: 80%; margin-left: 10%;">
                       <thead>
                           <tr>
                            <th>{{localizationData['s_no']}}</th>
                            <th>{{localizationData['service_desc']}}</th>
                            <th>{{localizationData['service_desc_hi']}}</th>
                        </tr>
                       </thead>
                       <tbody>
                           <tr>
                            <td>1</td>
                            <td>
                                <input type="text" class="form-control" [(ngModel)]="obj['ser_desc']" name="" id="">
                            </td>
                            <td>
                                <input type="text" class="form-control" [(ngModel)]="obj['ser_desc_hi']" name="" id="">
                            </td>
                        </tr>
                       </tbody>
                   </table>
               </div>
               <br>
               <br>
               <div class="row">
                   <div class="col-12 text-center">
                    <button (click)="submitAtLevel2()" data-dismiss="modal" class="btn btn-primary">{{localizationData['submit']}}</button>
                   </div>
               </div>
               <br>
               <br>

                <div class="modal-footer">
                   
                    <button type="button" class="btn btn-danger" style="font-size: 16px;" data-dismiss="modal">
                        {{localizationData['close']}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>